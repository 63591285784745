import { SbsPlayerGlobal } from '../../common';
import { importScript } from '../../utils';
import { getUUID } from '../../utils/advertisements';

import SbsPlayerLogger from '../../utils/logger';

/**
  추천 서비스 클라이언트 로그
*/
class SbsVideoLog {
  constructor() {
    this.cmdata = null;
    this.extra = null;
    this.state = {
      quality_changed: false,
      ended: false
    };
    this._event = {
      initialized: () => {
        console.log(`initialized.`);
      },
    };
  }

  get event() {
    return this._event;
  }
  set event(event) {
    this._event = event;
  }

  init(video) {
    try {
      console.log('SbsVideoLog init');
      this.video = video;

      const { info, source, ad, ad_25, ad_30, ad_sbs } = SbsPlayerGlobal.state.data;
      const smrAdVersion = (ad.smr_ad_version || '2.5').replace(/\./g, "");
      const ad_smr = smrAdVersion === '25' ? ad_25 : ad_30;
      const vodType = SbsPlayerGlobal.infoType === 'onair' ? 'O' : SbsPlayerGlobal.infoType === 'vod' ? 'F' : 'C';


      this.uuid = getUUID(ad_smr);

      this.cmdata = ad_smr.cmdata;
      this.extra = {
        logtype: `${this.state.quality_changed ? 'DT' : 'QC'}`,
        duration: parseInt(video.currentTime),
        playertype: 'HTML5',
        productcode: info.productcode,
        vodtype: vodType,
        fullvodtype: info.fullvodtype,
        mode: info.type === 'VOD3MIN' ? 'PSP' : 'FSP',
        sn_log: false,
        sn_article: '',
        title: info.title || '',
        program: info.program || null
      }
      this.quality = {
        '02': 'SD',
        '05': 'HD',
        '07': 'FHD',
        '09': 'UHD'
      }[source.mediasource.mediarscuse];



      if (typeof SBSVideoLog === 'undefined') {
        importScript(SbsPlayerGlobal.url.lib.sbs_video_log, () => {
          this._event.initialized();
        });
      } else {
        this._event.initialized();
      }
    } catch (error) {
      console.log(error);
    }
  }

  /**
  * SBS로그 모듈 - adPlay, 영상 광고 재생시
  * @param {String} adType - 광고 타입 (Require), SBS, SMR, ADX, XC
  * @param {String} adPosition - 광고 위치(Require) prerollplus, preroll, midroll, postroll
  */
  adPlay(adType, adPosition) {
    try {
      console.log('==> adPlay.', adType, adPosition);

      if (adType && adPosition) {
        adType = adType.toUpperCase();
        adPosition = adPosition === 'live_midroll' ? 'midroll' : adPosition;
        //let ifaValue = adType === 'SBS' ? 

        window.__SBSLogModuleDataLayer = window.__SBSLogModuleDataLayer || [];
        window.__SBSLogModuleDataLayer.push({
          type: 'adPlay',
          option: {
            cmdata: this.cmdata, //Require
            extra: this.extra, //Require
            adType: adType, //광고 타입 (Require), SBS, SMR, ADX, XC
            adPosition: adPosition, //광고 위치(Require) prerollplus, preroll, midroll, postroll
            ifa: adType === 'SBS' || adType === 'SMR' ? this.uuid : '' //광고 식별자 (별도 표 참조)
          }
        });
      }
    } catch (error) {
      console.error('__SBSLogModuleDataLayer', error);
    }
  }

  /**
  * SBS로그 모듈 - ContentPlay, 콘텐츠 재생시
  * @param {} none - onair/vod/clip 모두 시작시 전송.
  */
  contentPlay() {
    try {
      console.log('==> contentPlay');
      this.state.quality_changed = true;
      this.state.ended = false;

      window.__SBSLogModuleDataLayer = window.__SBSLogModuleDataLayer || [];
      window.__SBSLogModuleDataLayer.push({
        type: 'contentPlay',
        option: {
          cmdata: this.cmdata,
          extra: this.extra
        }
      });
    } catch (error) {
      console.error('__SBSLogModuleDataLayer', error);
    }
  }

  /**
  * SBS로그 모듈 - ContentPlay, 콘텐츠 재생시
  * @param {String} playMode - 재생 모드, NR, PR, ~~DN~~
  // * @param {String} playQuality - 재생 화질(삭제), this.quality 그대로 사용
  * @param {Boolean} playContinue - 클립, 라이브 false 고정, VOD 이어보기시 true, 아니면 false
  * @param {Number} playDuration - 전체 재생시간(초), 라이브는 0 고정
  * @param {Number} playTime - 재생시간 (초)
  * @param {Number} playTime - 재생시간 (초)
  */
  contentPlaying(playMode, playContinue, playDuration, videoElement, countInterval) {
    try {
      if (!this.state.ended) {
        // * 온에어일 경우 videoElement 는 null 로 대치
        const playTime = videoElement ? parseInt(videoElement.currentTime) : -1;
        console.log('==> contentPlaying', playMode, playContinue, playDuration, playTime, countInterval);
        if (playMode, playContinue, playDuration, playTime) {
          window.__SBSLogModuleDataLayer = window.__SBSLogModuleDataLayer || [];
          window.__SBSLogModuleDataLayer.push({
            type: 'contentPlaying',
            option: {
              cmdata: this.cmdata, //Require
              extra: this.extra, //Require
              playMode: playMode, //재생 모드(별도 표 참조)
              playQuality: this.quality, //재생 화질(별도 표 참조)
              playContinue: playContinue, //클립, 라이브 false 고정, VOD 이어보기시 true, 아니면 false
              playDuration: playDuration, //전체 재생시간(초), 라이브는 0 고정
              playTime: playTime, //재생시간 (초)
              playViewTime: countInterval * 60 //시청시간(초)
            }
          });
        }
      }
    } catch (error) {
      console.error('__SBSLogModuleDataLayer', error);
    }
  }

  /**
  * SBS로그 모듈 - 영상 광고 재생완료시
  * @param {} none - contentPlayEnd
  */
  contentPlayEnd() {
    try {
      if (!this.state.ended) {
        this.state.ended = true;

        console.log('==> contentPlayEnd');

        window.__SBSLogModuleDataLayer = window.__SBSLogModuleDataLayer || [];
        window.__SBSLogModuleDataLayer.push({
          type: 'contentPlayEnd',
          option: {
            cmdata: this.cmdata, //Require
            extra: this.extra, //Require
          }
        });
      }
    } catch (error) {
      console.error('__SBSLogModuleDataLayer', error);
    }
  }
}

export default SbsVideoLog;