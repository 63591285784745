/**
 * 화면 구현
 */

import { SbsPlayerGlobal } from '../../common';

customElements.define('sbs-player-toparea-back', class extends HTMLElement {
  constructor() {
    super();
  }

  async connectedCallback() {
    try {
      this.render();
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    try {
      this.innerHTML = `<button type="button" class="playerBtn_back">
          <span class="iconSvg_back"><i class="hide">이전가기</i></span>
        </button>`;
      this.events();

    } catch (error) {
      console.log(error);
    }
  }

  events() {
    try {
      this.querySelector('button').addEventListener('click', () => {
        history.back();
      });
    } catch (error) {
      console.log(error);
    }
  }
});