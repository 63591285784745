/**
 * 화면 구현
 */
import SbsPlayerLogger from '../../utils/logger';
import { SbsPlayerGlobal } from '../../common/global';
// ! 글로벌 국
customElements.define('sbs-player-dialog', class extends HTMLElement {
  constructor() {
    super();
    this.visible = false;
  }

  set visible(value) {
    this._visible = value;
    this.render();
  }

  get visible() {
    return this._visible;
  }

  static get observedAttributes() { return ['title', 'html']; }

  async attributeChangedCallback(name, oldValue, newValue) {
    try {
      if (name === 'title' && (oldValue !== newValue)) {
        this.title = newValue;
      }
      if (name === 'html' && (oldValue !== newValue)) {
        this.html = newValue;
      }
    } catch (error) {
      console.log(error);
    }
  }

  async connectedCallback() {
    try {
      this.render();
    } catch (error) {
      console.log(error);
    }
  }

  disconnectedCallback() {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  adoptedCallback() {
    try {
    } catch (error) {
      console.log(error);
    }
  }


  render() {
    try {
      // ! 공통 모듈형태로 분리하기 어려운 구조임 일단 보류
      this.innerHTML = `<div class="playerDialogLive_w current" role="dialog">
        <div class="playerDialogContents">
            <div class="playerDialogTop">
                <p class="playerDialogTop-text">초고화질 3분 미리보기가 종료되었습니다.</p>
                <p class="playerDialogTop-text">이용권 구매 후 시청가능합니다.</p>
            </div>
            <div class="playerMembershipBox_w">
                <div class="playerMembershipBox">
                    <strong class="playerMembershipBox-title">SBS APP</strong>
                    <a href="javascript:void(0);" target="_blank" class="playerMembershipBtn">바로가기</a>
                </div>
            </div>
        </div>
        <sbs-player-fullscreen></sbs-player-fullscreen>
      </div>`;
      this.querySelector('.ADBlock_text').innerHTML = this.html;

      this.styles();
      this.event();
    } catch (error) {
      console.log(error);
    }
  }

  styles() {
    try {
      if (SbsPlayerGlobal.isMobile) this.style.paddingTop = `56.2%`;
    } catch (error) {
      console.log(error);
    }
  }

  event() {
    try {
      this.querySelector('a').addEventListener('click', () => {
        SbsPlayerGlobal.events.emitEvent(`applink-clicked`, true);
      });
    } catch (error) {
      console.log(error);
    }
  }
});