/**
 * 화면 구현
 */
import SbsPlayerLogger from '../../utils/logger';

customElements.define('sbs-player-share', class extends HTMLElement {
  constructor() {
    super();

  }

  static get observedAttributes() { return []; }

  async attributeChangedCallback(name, oldValue, newValue) {
    try {

    } catch (error) {
      console.log(error);
    }
  }

  async connectedCallback() {
    try {
      this.render();
    } catch (error) {
      console.log(error);
    }
  }

  disconnectedCallback() {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  adoptedCallback() {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    try {
      this.innerHTML = ``;
    } catch (error) {
      console.log(error);
    }
  }
});