import { SbsPlayerGlobal } from '../common';

class SbsPlayerLogger {
  static log = (message, timeCode, colorCode) => {
    try {
      if (href.indexOf('debug') > -1 && (navigator.appName == 'Netscape' && navigator.userAgent.search('Trident') != -1) || (agent.indexOf("msie") != -1)) {
        const date = new Date();
        timeCode ??= `${date.toLocaleTimeString()}.${date.getMilliseconds()}`
        colorCode ??= 7
        if (typeof message === 'string') {
          console.log(`\x1b[3${colorCode}m[${timeCode}] ${message}`);
        } else {
          console.log(`\x1b[3${colorCode}m[${timeCode}] ↓`);
          console.log(`\x1b[3${colorCode}m${JSON.stringify(message)}`);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  static info = (message) => {
    try {
      const date = new Date();
      const timeCode = `${date.toLocaleTimeString()}.${date.getMilliseconds()}`;
      const colorCode = 6; // cyan
      log(message, timeCode, colorCode);
    }
    catch (error) {
      console.log(error);
    }
  }

  static background = (message) => {
    try {
      const date = new Date();
      const timeCode = `${date.toLocaleTimeString()}.${date.getMilliseconds()}`;
      const colorCode = 5; // purple
      log(message, timeCode, colorCode);
    }
    catch (error) {
      console.log(error);
    }
  }

  static primary = (message) => {
    try {
      const date = new Date();
      const timeCode = `${date.toLocaleTimeString()}.${date.getMilliseconds()}`;
      const colorCode = 4; // blue
      log(message, timeCode, colorCode);
    }
    catch (error) {
      console.log(error);
    }
  }

  static warning = (message) => {
    try {
      const date = new Date();
      const timeCode = `${date.toLocaleTimeString()}.${date.getMilliseconds()}`;
      const colorCode = 3; // yellow
      log(message, timeCode, colorCode);
    }
    catch (error) {
      console.log(error);
    }
  }

  static success = (message) => {
    try {
      const date = new Date();
      const timeCode = `${date.toLocaleTimeString()}.${date.getMilliseconds()}`;
      const colorCode = 2; // green
      log(message, timeCode, colorCode);
    }
    catch (error) {
      console.log(error);
    }
  }

  static error = (message) => {
    try {
      const date = new Date();
      const timeCode = `${date.toLocaleTimeString()}.${date.getMilliseconds()}`;
      const colorCode = 1; // red
      log(message, timeCode, colorCode);
    }
    catch (error) {
      console.log(error);
    }
  }
}

export default SbsPlayerLogger;