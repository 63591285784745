/**
 * 플레이어 화면 구현
 */
import { SbsPlayerGlobal } from '../../../../../common';
import { clamp, secondsToHms } from '../../../../../utils';
import SbsPlayerLogger from '../../../../../utils/logger';
import './hover';
import './now';

customElements.define('sbs-player-timeshift', class extends HTMLElement {
  constructor() {
    super();
    this.time = null;
  }

  static get observedAttributes() { return []; }

  async attributeChangedCallback(name, oldValue, newValue) {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  async connectedCallback() {
    try {
      this.render();
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    try {
      this.style.width = "100%";
      this.innerHTML = `<div class="timeMachineMode_w">
                    <i class="timeMachine_bar0">
                      <span class="timeMachine_timeText">4시간 전</span>
                    </i>
                    <i class="timeMachine_bar1"></i>
                    <i class="timeMachine_bar2">
                      <span class="timeMachine_timeText">3시간 전</span>
                    </i>
                    <i class="timeMachine_bar3"></i>
                    <i class="timeMachine_bar4">
                      <span class="timeMachine_timeText">2시간 전</span>
                    </i>
                    <i class="timeMachine_bar5"></i>
                    <i class="timeMachine_bar6">
                      <span class="timeMachine_timeText">1시간 전</span>
                    </i>
                    <i class="timeMachine_bar7"></i>
                    <i class="timeMachine_bar8">
                      <span class="timeMachine_timeText">라이브</span>
                    </i>
                </div>
                <sbs-player-timeshift-hover></sbs-player-timeshift-hover>
                `;
      this.event();
    } catch (error) {
      console.log(error);
    }
  }

  event() {
    try {
      const progressElement = SbsPlayerGlobal.view.querySelector('sbs-player-wrapper sbs-player-progressbar');
      const hover = this.querySelector('sbs-player-timeshift-hover');
      const now = this.querySelector('sbs-player-timeshift-now');

      progressElement.addEventListener('progress-move', (event) => {
        hover.style.display = 'flex';
        // ! changedTouches, TouchEvent 일 경우 예외처리
        let mouseX = (event.detail.clientX || event.detail.changedTouches[0].clientX) - progressElement.getBoundingClientRect().x;
        let position = clamp(mouseX, 0, progressElement.clientWidth);
        let percent = clamp((position / progressElement.clientWidth), 0, 1);
        let mouseMoveTime = parseInt(14_400 - (14_400 * percent));
        this.setHover(percent, mouseMoveTime);
      });
      progressElement.addEventListener('progress-leave', (event) => {
        hover.style.display = 'none';
        this.dispatchEvent(new CustomEvent('timeshift-change-text', {
          detail: ''
        }));
      });
      progressElement.addEventListener('progress-click', (event) => {
        let position = clamp(event.detail, 0, 1);
        let mouseMoveTime = parseInt(14_400 - (14_400 * position));
        console.log('타임머신 시크', event.detail, mouseMoveTime);
        this.time = mouseMoveTime;
        //this.setNow(position, this.time);
      });

      SbsPlayerGlobal.events.addEventListener('motion-key-event', (status) => {
        if (status === 'backward' || status === 'forward') {
          status === 'backward' ? this.time += 10 : this.time -= 10; // 음수임으로 반대
          this.time = clamp(this.time, 0, 14_400);
          //this.setNow(null, this.time);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  setHover(position, mouseMoveTime) {
    try {
      const hover = this.querySelector('sbs-player-timeshift-hover');
      hover.percentage = position * 100;
      hover.text = secondsToHms(mouseMoveTime);

      this.dispatchEvent(new CustomEvent('timeshift-change-text', {
        detail: `- ${hover.text}`
      }));
    } catch (error) {
      console.log(error);
    }
  }

  // 실제 thumb 에 포지션은 hover가 아니라 Progress 의 button 스타일에 따라 변경
  // ! 비활성화로 디자인결정(QA32)
  setNow(position, mouseMoveTime) {
    try {
      const now = SbsPlayerGlobal.view.querySelector('sbs-player-timeshift-now');
      position ? now.percentage = position * 100 : false; // percentage 는 first, last 확인용도
      now.text = secondsToHms(mouseMoveTime);
    } catch (error) {
      console.log(error);
    }

  }
});
