import SbsPlayerLogger from '../utils/logger';

/**
 * 이벤트 관리
 * 
 */
export class EventManager {
  constructor(props) {
    this.events = {};
    this.props = props;
  }

  addEventListener(eventName, callback) {
    if (!this.events[eventName]) {
      this.events[eventName] = [];
    }
    this.events[eventName].push(callback);
  }

  removeEventListener(eventName, callback) {
    if (!this.events[eventName]) {
      return;
    }
    let index = this.events[eventName].indexOf(callback);
    if (index !== -1) {
      this.events[eventName].splice(index, 1);
    }
  }
  /**
   * 이벤트 발생전파
   *
   * @param {string} eventName 명명규칙, 기본엘리먼트이름-이벤트이름-구분자
   * @param {*} data
   * @return {*} 
   * @memberof EventManager
   */
  emitEvent(name, event) {
    try {
      if (!this.events[name]) {
        console.warn(`${name} event listener is not found.`);
        return;
      }

      // 현재 사용자 정의 요소 내에서만 콜백 실행
      if (event && event.target) {
        const currentElement = this.props.shadowRoot.querySelector(`#${this.props.uuid}`);
        this.events[name].forEach(callback => {
          if (event.target && event.target.closest(`#${this.props.uuid}`) === currentElement) {
            callback(event);
          }
        });
      } else {
        this.events[name].forEach(callback => {
          callback(event);
        });
      }
    } catch (error) {
      console.log(error);
    }

  }
}
