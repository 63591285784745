import { SbsPlayerGlobal } from '../../../../common';
import { clamp, secondsToHms, secondsToText } from '../../../../utils';
import SbsPlayerLogger from '../../../../utils/logger';
import './FullScreen';
import './PlayPause';
import './Progress';
import './Sequence';
import './Time';
import './Volume';

customElements.define('sbs-player-ad-controls', class extends HTMLElement {
  constructor() {
    super();

    this.state = {
      data: {}
    }
    this.sequence_text = '';
  }

  static get observedAttributes() { return ['sequence_text']; }

  async attributeChangedCallback(name, oldValue, newValue) {
    try {
      if (name === 'sequence_text' && (oldValue !== newValue)) {
        this.sequence_text = newValue;
        this.render();
      }
    } catch (error) {
      console.log(error);
    }
  }

  async connectedCallback() {
    try {
      // let test = process.env.MEDIA_API_SMR;
      this.render();
      this.event();
    } catch (error) {
      console.log(error);
    }
  }

  disconnectedCallback() {
    try {
      if (!document.body.contains(this)) {
      }
    } catch (error) {
      console.log(error);
    }
  }

  adoptedCallback() {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    try {
      this.innerHTML = `<div class="playerControls_w">
        <div class="playerSeekBar_w">
          <sbs-player-ad-progressbar></sbs-player-ad-progressbar>
        </div>
        <div class="playerFunctionBtns">
          <div class="playerControls_left">
            ${!SbsPlayerGlobal.isMobile ? `<sbs-player-ad-playpause></sbs-player-ad-playpause>` : ``}
            ${!SbsPlayerGlobal.isMobile ? `<sbs-player-ad-volume></sbs-player-ad-volume>` : ``}
            <sbs-player-ad-time label="sbs-player-ad-progressbar"></sbs-player-ad-time>
            <sbs-player-ad-sequence text=${this.sequence_text}></sbs-player-ad-sequence>
          </div>
          <div class="playerControls_right">
            <sbs-player-ad-fullscreen></sbs-player-ad-fullscreen>
          </div>
        </div>
      </div>`;
    } catch (error) {
      console.log(error);
    }
  }

  event() {
    try {
      const self = this;
      const progress = this.querySelector('sbs-player-ad-progressbar');
      const progressbar_wrapper = this.querySelector('.playerSeekBar_w');
      const playpause = this.querySelector('sbs-player-ad-playpause');
      const adVolume = this.querySelector('sbs-player-ad-volume');
      const time = this.querySelector('sbs-player-ad-time');
      const fullscreen = this.querySelector('sbs-player-ad-fullscreen');

      SbsPlayerGlobal.events.addEventListener('video-change-ad', (event) => {
        try {
          const video = event.target;
          if ('timeupdate' === event.type && progress && video.currentTime && video.duration) {
            progress.current = video.currentTime / video.duration;
            time.current = video.currentTime;
            self.labelTime(video, progressbar_wrapper);
          }
          if ('progress' === event.type && progress && video.duration) {
            const value = parseFloat(video.buffered.length > 0 ? video.buffered.end(0) : 0 / video.duration);
            progress.progress = isNaN(value) ? 0 : value;
          }

          if ('volumechange' === event.type && adVolume) {
            let value = clamp(video.volume * 100, 0, 100);
            adVolume.vol = value;
            if (adVolume.vol > 0) SbsPlayerGlobal.options.muted = false;
          }

          if ('canplay' === event.type && video.paused) {
            try {
              console.log('canplay play video volume: ' + video.volume);

              let promise = video.play();
              if (promise !== undefined) {
                promise.then(_ => {
                  console.log('ad video play');

                  video.volume = 1;
                }).catch(error => {
                  console.warn('ad video play error', error);
                  SbsPlayerGlobal.events.emitEvent('autoplay-failed');

                  video.volume = 0;
                  video.play();
                });
              }
            } catch (error) {
              console.log(error);
            }
          }

          if ('canplaythrough' === event.type) {
          }

          if ('durationchange' === event.type && progressbar_wrapper && time) {
            progressbar_wrapper.setAttribute('aria-valuemin', 0);
            progressbar_wrapper.setAttribute('aria-valuemax', Math.round(video.duration / 10));
            time.duration = video.duration;
          }

          if ('play' === event.type) {
            if (playpause) playpause.paused = false;
          }

          if ('pause' === event.type) {
            if (playpause) playpause.paused = true;
          }

          if ('ended' === event.type) {
          }
        } catch (error) {
          console.log(error);
        }
      });


      const elements = [
        { element: playpause, event: 'button-toggle-playpause' },
        { element: adVolume, event: 'button-toggle-volume' },
        { element: adVolume, event: 'input-change-volume' },
        { element: fullscreen, event: 'button-toggle-fullscreen' },
      ];

      for (const { element, event } of elements) {
        if (!element) continue;
        element.addEventListener(event, (e) => {
          self.dispatchEvent(new CustomEvent(event, { detail: e.detail }));
        });
      }

      SbsPlayerGlobal.events.addEventListener('controller-hide-event', (hidden) => {
        const hideControls = this.querySelector('.playerControls_w');
        hidden ? hideControls.classList.add('hide_controls') : hideControls.classList.remove('hide_controls');
      });
    } catch (error) {
      console.log(error);
    }
  }

  ended() {
    try {

    } catch (error) {
      console.log(error);
    }
  }

  labelTime(video, element) {
    try {
      let timeText = `${secondsToText(video.currentTime)} / ${secondsToText(video.duration)}`;
      // 너무자주호출되어서 지장이 있어선 안됨..
      element.setAttribute('aria-valuenow', Math.round(video.currentTime));
      element.setAttribute('aria-valuetext', timeText);
    } catch (error) {
      console.log(error);
    }
  }
});