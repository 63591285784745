/**
 * 최초 인트로 화면 구현
 */
import SbsPlayerLogger from '../../../../utils/logger';

customElements.define('sbs-player-toast', class extends HTMLElement {
  constructor() {
    super();
    this.message = '';
    this.duration = 5_000;
  }

  set message(value) {
    this._message = value;
    this.render();
  }

  get message() {
    return this._message;
  }

  static get observedAttributes() { return ['duration']; }

  async attributeChangedCallback(name, oldValue, newValue) {
    try {
      if (name === 'duration' && (oldValue !== newValue)) {
        this.duration = parseInt(newValue);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async connectedCallback() {
    try {
      // this.render();
    } catch (error) {
      console.log(error);
    }
  }

  disconnectedCallback() {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  adoptedCallback() {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    try {
      console.log('toast: ', this.message, this.duration);
      // ? css animation: disappear 3s 1;
      this.innerHTML = `<div class="playerDialog_w current" role="alert" aria-live="assertive" aria-atomic="true" data-delay="${this.duration}" data-autohide="true" style="pointer-events: none;display: ${this.message.length > 0 ? 'block' : 'none'}">
      <p class="playerDialog_text">${this.message}</p>
    </div>`;
      this.styles();
    } catch (error) {
      console.log(error);
    }
  }

  styles() {
    const alert = this.querySelector('.playerDialog_w');
    alert.style.display = 'block';
    alert.style.animation = `$disappear ${this.duration / 1_000}s 1`;
    setTimeout(() => {
      alert.style.display = 'none';
    }, this.duration + 1_000);
  }
});