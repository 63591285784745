/**
 * 화면 구현
 */
import { SbsPlayerGlobal } from '../../common';

customElements.define('sbs-player-toparea-buy', class extends HTMLElement {
  constructor() {
    super();

  }

  static get observedAttributes() { return []; }

  async attributeChangedCallback(name, oldValue, newValue) {
    try {

    } catch (error) {
      console.log(error);
    }
  }

  async connectedCallback() {
    try {
      this.render();
    } catch (error) {
      console.log(error);
    }
  }

  disconnectedCallback() {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  adoptedCallback() {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    try {
      const { info } = SbsPlayerGlobal.state.data;
      if (SbsPlayerGlobal.isMobile) return false;
      if (SbsPlayerGlobal.vodtype.indexOf('ALLVOD' === -1)) return false;
      if (info && info.program.programid === '22000010818') return false;
      this.innerHTML = `<a role="button" class="btnBorderType-buy" aria-label="한편 구매" title="한편 구매" href="javascript:void(0);" style="cursor: pointer;">한편 구매</a>`;
      this.event();
    } catch (error) {
      console.log(error);
    }
  }

  event() {
    this.addEventListener('click', (event) => {
      event.preventDefault();
      const { source } = SbsPlayerGlobal.state.data;
      // * 낮은 화질 순서로 정렬
      const mediasourcelist = source.mediasourcelist.sort((a, b) => {
        return parseInt(a.mediarscuse) - parseInt(b.mediarscuse);
      });
      const selectedQuality = mediasourcelist.filter((mediasource) => {
        return mediasource.mediarscuse === '02' || mediasource.mediarscuse === '05';
      })[0];
      this.dispatchEvent(new CustomEvent('billing-popup', {
        detail: {
          type: 'vods',
          resource: selectedQuality.mediarscid
        }
      }));
      billingPopup('vods', selectedQuality.mediarscid);
    });
  }
});