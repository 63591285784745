/**
 * 플레이어 화면 구현
 */
import { SbsPlayerGlobal } from '../../../common';
import { clamp } from '../../../utils';
import { loadLocalStorage, saveLocalStorage, removeLocalStorage } from '../../../utils/storage';

import SbsPlayerLogger from '../../../utils/logger';

import './Center';
import './Controls';
import './Motion';
import './SubtitleDisplay';
import './Toast';
import './Video';

customElements.define('sbs-player-wrapper', class extends HTMLElement {
  constructor() {
    super();
    this.state = {
      rscuse: null,
      cc: false,
      dvr: false,
      on_subtitle: false,
      on_audio_desc: false,
    }
    this.hlsDisposed = false;
    this.playbackRate = SbsPlayerGlobal.infoType !== 'onair' && !SbsPlayerGlobal.isMobile ? loadLocalStorage(SbsPlayerGlobal.storage.sbsplayer_playback_rate) || 1 : 1;
    this.shownFoundationMessage = false;
  }

  async connectedCallback() {
    try {
      this.render();
    } catch (error) {
      console.log(error);
    }
  }

  disconnectedCallback() {
    try {
      if (!document.body.contains(this)) {
      }
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    try {
      const { source } = SbsPlayerGlobal.state.data;
      const mediaUrl = SbsPlayerGlobal.src || source.mediasource.mediaurl?.replace('http://', 'https://');
      const subtitleUrl = source && source.subtitle ? source.subtitle.replace('http://', 'https://') : undefined;

      this.innerHTML = `<div class="playerVideo_w">
          <sbs-player-video-motion motion=${this.motion} count=${this.count}></sbs-player-video-motion>
          <div class="playerVideoCont">
            <sbs-player-video src="${mediaUrl}" track="${subtitleUrl}"></sbs-player-video>
          </div>
          <sbs-player-center-buttons></sbs-player-center-buttons>
          ${SbsPlayerGlobal.infoType === 'onair' ? `<sbs-player-timeshift-center></sbs-player-timeshift-center>` : ``}
        </div>
        ${subtitleUrl ? `<sbs-player-subtitle></sbs-player-subtitle>` : ``}
        <sbs-player-controls></sbs-player-controls>
        ${source && source.toast ? `<sbs-player-toast duration="${source.toast.duration ? source.toast.duration : SbsPlayerGlobal.options.toast_seconds}"></sbs-player-toast>` : ``}`;
      this.event();
      this.styles();
    } catch (error) {
      console.log(error);
    }
  }

  adjustVolume(video) {
    try {
      setTimeout(() => {
        video.volume = clamp(loadLocalStorage(SbsPlayerGlobal.storage.sbsplayer_volume), 0, 1);
      }, 500);
    } catch (error) {
      console.error(error);
    }
  }

  /**
      * 
      * @param {string} rscuse 02, 05, 07 화질
      * @param {boolean} cc 라이브 자막 활성화 여부
      * @param {boolean} dvr 라이브 타임머신 활성화 여부

      * @return {*} 
      */
  request = async (rscuse, cc, dvr) => {
    const videoElement = this.querySelector('sbs-player-video');
    let url = SbsPlayerGlobal.url.info;
    url.searchParams.set('rscuse', rscuse ? rscuse : '');

    let response = await fetch(`${url} `);
    if (response.status === 200) {


      response = await response.json();
      SbsPlayerGlobal.state.data = response.vod || response.clip || response.onair;

      let { info, source, login_info } = SbsPlayerGlobal.state.data;

      // * sbsplayer_rscuse
      // * S회원일 경우 변경된 화질정보 저장
      if (login_info.is_s_member) {
        saveLocalStorage(SbsPlayerGlobal.storage.sbsplayer_rscuse, rscuse);
      } else {
        removeLocalStorage(SbsPlayerGlobal.storage.sbsplayer_rscuse);
      }

      // * 온에어 헬스체크 info
      if (info.onair_yn === 'N') {
        // alert(info.onair_text);
        SbsPlayerGlobal.view.querySelector('.playerWrap').innerHTML = `<sbs-player-warning title="영상시청안내" html="${info.onair_text}"></sbs-player-warning>`;
        return false;
      } else if (info.overseas_yn === 'N') {
        // alert(info.overseas_text);
        SbsPlayerGlobal.view.querySelector('.playerWrap').innerHTML = `<sbs-player-warning title="영상시청안내" html="${info.overseas_text}"></sbs-player-warning>`;
        return false;
      }

      let mediaUrl = source.mediasource.mediaurl.replace('http://', 'https://');
      let subtitleUrl = source.subtitle?.replace('http://', 'https://');

      // * SBSVideoLog init
      console.log(`==> SBSVideoLog init`);
      SbsPlayerGlobal.videoLog.init(videoElement)

      console.log(`rscuse: ${this.state.rscuse}`);
      console.log(`cc: ${cc}`);
      console.log(`dvr: ${dvr}`);
      console.log(`mediaUrl: ${mediaUrl}`);
      console.log(`subtitleUrl: ${subtitleUrl}`);

      if (cc) {
        if (info.caption_yn !== 'Y') {
          // LIVE
          alert('자막을 지원하지 않는 채널 입니다.');
        } else {
          mediaUrl = source.captionurl.replace('http://', 'https://');
        }
      } else if (dvr) {
        mediaUrl += '&DVR'
      }
      return { media: mediaUrl, subtitle: subtitleUrl };
    } else {
      throw new Error('화질변경 오류')
    }
  }

  event() {
    try {
      let { source } = SbsPlayerGlobal.state.data;
      const videoElement = this.querySelector('sbs-player-video');
      const centerElement = this.querySelector('sbs-player-center-buttons');
      const controlElement = this.querySelector('sbs-player-controls');
      const motionElement = this.querySelector('sbs-player-video-motion');
      const qaulityMenuElement = controlElement.querySelector('sbs-player-quality-menu');



  // * live default mode (hls) 일때 pause 가 아니라 그냥 끊어야함
      centerElement.addEventListener('button-toggle-playpause', (event) => {
        let paused = event.detail;
        paused ? this.play() : this.pause();
      });
      centerElement.addEventListener('button-click-replay', (event) => {
        videoElement.video.currentTime = 0;
        videoElement.video.play();
      });
      centerElement.addEventListener('button-click-backward', (event) => {
        videoElement.video.currentTime = clamp(videoElement.video.currentTime - 10, 0, videoElement.video.duration - 0.05)
      });
      centerElement.addEventListener('button-click-forward', (event) => {
        videoElement.video.currentTime = clamp(videoElement.video.currentTime + 10, 0, videoElement.video.duration - 0.05)
      });
      controlElement.addEventListener('button-click-popup', (event) => {
        this.pause();

        SbsPlayerGlobal.events.emitEvent('button-click-popup');
      });
      controlElement.addEventListener('progress-click', (event) => {
        videoElement.video.currentTime = videoElement.video.duration * event.detail;
      });
      controlElement.addEventListener('button-toggle-playpause', (event) => {
        let paused = event.detail;
        paused ? videoElement.video.play() : videoElement.video.pause();
      });
      controlElement.addEventListener('button-click-replay', (event) => {
        videoElement.video.currentTime = 0;
        videoElement.video.play();
      });
      controlElement.addEventListener('button-toggle-volume', (event) => {
        let muted = event.detail;
        videoElement.video.volume = muted ? 0.5 : 0;
      });
      controlElement.addEventListener('button-toggle-fullscreen', (event) => {
        this.dispatchEvent(new CustomEvent('button-toggle-fullscreen', { detail: event.detail }));
      });
      controlElement.addEventListener('button-toggle-live-dvr', (event) => {
        this.dispatchEvent(new CustomEvent('button-toggle-live-dvr', { detail: event.detail }));
      });

      controlElement.addEventListener('timeshift-change-text', (event) => {
        // * LIVE DVR 시크바 이동시 중앙에 구간정보 노출
        centerElement.querySelector('.playerVideoBtns').style.display = event.detail ? 'none' : 'flex';
        centerElement.querySelector('sbs-player-center-timeshift-text').setAttribute('text', event.detail);
      });

      // ? 오디오해설, CC에서 화질선택에 관련된 시나리오 필요...


      controlElement.addEventListener('button-toggle-subtitle', (event) => {
        try {
          if (source.subtitle) {
            this.state.on_subtitle = event.detail;
            const videoElement = this.querySelector('sbs-player-video');
            const subtitleElement = this.querySelector('sbs-player-subtitle');
            if (this.state.on_subtitle) {
              saveLocalStorage(SbsPlayerGlobal.storage.sbsplayer_subtitle, true);
              SbsPlayerGlobal.isMobile ? videoElement.showTrack() : subtitleElement.setAttribute('showing', true);
            } else {
              saveLocalStorage(SbsPlayerGlobal.storage.sbsplayer_subtitle, false);
              SbsPlayerGlobal.isMobile ? videoElement.hideTrack() : subtitleElement.setAttribute('showing', false);
            }
            this.toastSubtitleSupport();
          }
        } catch (error) {
          console.log(error);
        }
      });
      controlElement.addEventListener('subtitle-size-change', (event) => {
        try {
          const subtitleElement = this.querySelector('sbs-player-subtitle');
          subtitleElement.setAttribute('size', event.detail);
        } catch (error) {
          console.log(error);
        }
      });
      controlElement.addEventListener('button-toggle-audiodesc', (event) => {
        try {
          if (source.audio_description_url) {
            this.state.on_audio_desc = event.detail;
            saveLocalStorage(SbsPlayerGlobal.storage.sbsplayer_audiodesc, event.detail);
            videoElement.loadSource(this.state.on_audio_desc ? source.audio_description_url.replace('http://', 'https://') : source.mediasource.mediaurl.replace('http://', 'https://'));
            this.toastSubtitleSupport();
          }
        } catch (error) {
          console.log(error);
        }
      });
      controlElement.addEventListener('button-toggle-live-dvr', async (event) => {
        try {
          this.state.dvr = event.detail;
          const { media } = await this.request(this.state.rscuse, false, this.state.dvr);
          videoElement.loadSource(media);
          videoElement.video.playbackRate = this.playbackRate;

          this.adjustVolume(videoElement);
        } catch (error) {
          console.log(error);
        }
      });
      controlElement.addEventListener('button-toggle-live-caption', (event) => {
        this.dispatchEvent(new CustomEvent('button-toggle-live-caption', { detail: event.detail }));
      });
      controlElement.addEventListener('button-toggle-live-caption', async (event) => {
        try {
          this.state.cc = event.detail;

          const { media } = await this.request(this.state.rscuse, this.state.cc, this.state.dvr);
          videoElement.loadSource(media);
          videoElement.video.playbackRate = this.playbackRate;

          this.adjustVolume(videoElement);
        } catch (error) {
          console.log(error);
        }
      });
      controlElement.addEventListener('select-change-quality', async (event) => {
        try {
          this.state.rscuse = event.detail;
          const currentTime = videoElement.video.currentTime;

          const { source } = SbsPlayerGlobal.state.data;
          // ! 기존 구매관련 아이디는 일반화질, 고화질 mediarscid 으로만..사용 중
          // * 낮은 화질 순서로 정렬
          const mediasourcelist = source.mediasourcelist.sort((a, b) => {
            return parseInt(a.mediarscuse) - parseInt(b.mediarscuse);
          });
          const selectedQuality = mediasourcelist.filter((mediasource) => {
            return mediasource.mediarscuse === (this.state.rscuse === '02' ? '02' : '05')
          })[0];

          if (selectedQuality.required_payment === 'Y') {
            // * [front-pc] /app/src/js/model/end/common/player.js
            if (SbsPlayerGlobal.infoType === 'vod') {
              this.dispatchEvent(new CustomEvent('billing-popup', {
                detail: {
                  type: 'vods',
                  resource: selectedQuality.mediarscid
                }
              }));
              billingPopup('vods', selectedQuality.mediarscid);
              return false;
            } else if (SbsPlayerGlobal.infoType === 'onair') {
              this.dispatchEvent(new CustomEvent('billing-popup', {
                detail: {
                  type: 'onair',
                  resource: null
                }
              }));
              billingPopup('onair', null);
              return false;
            }
          }

          // * 모바일 시나리오 변경(모바일웹에선 초고화질 재생안됨)
          if (SbsPlayerGlobal.isMobile && (this.state.rscuse === '07' || this.state.rscuse === '09')) {
            if (confirm('초고화질은 SBS앱에서 이용 가능합니다.')) {
              SbsPlayerGlobal.events.emitEvent(`applink-clicked`, true);
            }
            return false;
          } 

          SbsPlayerGlobal.state.rscuse = this.state.rscuse;
          const { media } = await this.request(this.state.rscuse, false, this.state.dvr);

          // ?? track cuechange 이벤트가 안오길래 그냥 기존처럼 다시 렌더링으로 변경?
          videoElement.loadSource(media);
          videoElement.video.playbackRate = this.playbackRate;
          videoElement.video.currentTime = currentTime;
          videoElement.video.play();

          this.adjustVolume(videoElement);
          qaulityMenuElement.render();
        } catch (error) {

        }
      });
      motionElement.addEventListener('motion-current', (event) => {
        //console.log('motion-current', event.detail);
        centerElement.style.display = event.detail ? 'none' : 'block';
      });
      // * input -> publish 로 변경했지만.. 의미상 그대로 사용
      controlElement.addEventListener('input-change-volume', (event) => {
        let value = event.detail;
        let changeVolume = value && clamp(value, 0, 1);
        videoElement.video.volume = changeVolume;
        saveLocalStorage(SbsPlayerGlobal.storage.sbsplayer_volume, changeVolume);
      });
      // * unmute 컴포넌트에서 발생하는 이벤트
      SbsPlayerGlobal.events.addEventListener('input-change-volume', (volume) => {
        let changeVolume = clamp(volume, 0, 1);
        videoElement.video.volume = changeVolume;
        saveLocalStorage(SbsPlayerGlobal.storage.sbsplayer_volume, changeVolume);
      });
      SbsPlayerGlobal.events.addEventListener('motion-key-event', (status) => {
        // mute, unmute 없네, 라이브 예외처리 필요
        if (SbsPlayerGlobal.infoType !== 'onair')
          motionElement.setAttribute('motion', status);
      });

      SbsPlayerGlobal.events.addEventListener('double-touch-backward', (count) => {
        console.log('double-touch-backward', count);
        motionElement.setAttribute('motion', 'backward');
        motionElement.setAttribute('count', count);
      });
      SbsPlayerGlobal.events.addEventListener('double-touch-forward', (count) => {
        console.log('double-touch-forward', count);
        motionElement.setAttribute('motion', 'forward');
        motionElement.setAttribute('count', count);
      });
      SbsPlayerGlobal.events.addEventListener('select-change-speed', (event) => {
        let changeValue = event.target ? event.target.value : event;
        this.playbackRate = changeValue;

        saveLocalStorage(SbsPlayerGlobal.storage.sbsplayer_playback_rate, changeValue);
        videoElement.video.playbackRate = changeValue;
      });

      SbsPlayerGlobal.events.addEventListener('autoplay-failed', () => {
        try {
          centerElement.style.display = 'block';
        } catch (error) {
          console.log(error);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  styles() {
    try {

    } catch (error) {

    }
  }

  toast(messages) {
    try {
      if (!messages) return false;
      const toast = this.querySelector('sbs-player-toast');
      if (Array.isArray(messages)) {
        for (let i = 0; i < messages.length; i++) {
          setTimeout(function () {
            toast.message = `${messages[i]} `;
          }, i * 3_500);
        }
      } else {
        toast.message = `${messages} `;
      }
    } catch (error) {
      console.error(error);
    }
  }

  toastSubtitleSupport() {
    const { source } = SbsPlayerGlobal.state.data;
    const toast = this.querySelector('sbs-player-toast');
    if (!this.shownFoundationMessage) {
      toast.message = document.documentElement.lang === 'en' ? source.toast.message.en : source.toast.message.ko;
      this.shownFoundationMessage = true;
    }
  }

  playVideo() {
    try {
      if (SbsPlayerGlobal.infoType === 'onair') {
        SbsPlayerGlobal.view.querySelector('.playerWrap').classList.add('playerLiveType');
      }

      const videoElement = this.querySelector('sbs-player-video');

      setTimeout(() => {
        this.style.display = 'block';
        this.style.opacity = 1;

        videoElement.video.playbackRate = SbsPlayerGlobal.infoType !== 'onair' && !SbsPlayerGlobal.isMobile ? parseFloat(loadLocalStorage(SbsPlayerGlobal.storage.sbsplayer_playback_rate) || 1) : 1;

        // * 재생 시도.
        let promise = videoElement.video.play();
        if (promise !== undefined) {
          console.log('video play promise: ' + promise)
          promise.then(_ => {
            console.log('video play');
            videoElement.video.volume = 1;
          }).catch(error => {
            console.warn('video play error', error);
            console.warn('ad video play error', error);
            SbsPlayerGlobal.events.emitEvent('autoplay-failed');
          });
        } else {
          console.log('video not loaded?');
        }
      }, 750);
    } catch (error) {
      console.log(error);
    }
  }

  async play() {
    try {
      const videoElement = this.querySelector('sbs-player-video');
      if (SbsPlayerGlobal.infoType === 'onair' && this.hlsDisposed) {
        this.state.cc = false;
        this.state.dvr = false;
        const { media } = await this.request(this.state.rscuse, this.state.cc, this.state.dvr);
        videoElement.loadSource(media);

        this.adjustVolume(videoElement);
        this.hlsDisposed = false;


      } else {
        videoElement.video.play();
      }
    } catch (error) {
      console.log(error);
    }
  }

  pause() {
    try {
      const videoElement = this.querySelector('sbs-player-video');
      videoElement.video.pause();

      console.log('SbsPlayerGlobal.infoType', SbsPlayerGlobal.infoType);
      if (SbsPlayerGlobal.infoType === 'onair') {
        console.log('video pause hls onair dispose');
        // videoElement.player.dispose();
        // videoElement.player.stopLoad();
        // videoElement.player = {};
        this.hlsDisposed = true;

        videoElement.player.destroy();
        videoElement.player.stopLoad();

        clearTimeout(SbsPlayerGlobal.live_midroll.ct_request_timeout);
        clearTimeout(SbsPlayerGlobal.live_midroll.rd_request_timeout);

        SbsPlayerGlobal.events.emitEvent('hls-disposed');
        SbsPlayerGlobal.events.emitEvent('video-change-player', { type: 'pause' });
      }
    } catch (error) {
      console.log(error);
    }
  }
});