/**
 * 플레이어 화면 구현
 */
import { SbsPlayerGlobal } from '../../../../../common';
import { clamp, secondsToHms } from '../../../../../utils';
import SbsPlayerLogger from '../../../../../utils/logger';
customElements.define('sbs-player-preview', class extends HTMLElement {
  constructor() {
    super();

    this.state = {
      rendered: false,
    }
    this.view = {
      thumbnail: {}
    }
    this.time = null;
  }

  async connectedCallback() {
    try {
      const { source } = SbsPlayerGlobal.state.data;

      if (source.preview?.json === undefined) return;

      const videoElement = SbsPlayerGlobal.view.querySelector('sbs-player-wrapper video');
      const progressElement = SbsPlayerGlobal.view.querySelector('sbs-player-wrapper sbs-player-progressbar');
      const previewUrl = source.preview?.json.replace('http://', 'https://');

      let previewResponse = null;
      previewResponse = await (await fetch(previewUrl)).json();
      if (previewResponse) {
        this.render(previewResponse.spriteUrl).then(() => {
          this.state.rendered = true;
        });

        progressElement.addEventListener('progress-move', (event) => {
          this.querySelector('.playerPreview_w').classList.add('current');
          if (this.state.rendered) {
            // ! changedTouches, TouchEvent 일 경우 예외처리

            let mouseX = (event.detail.clientX || event.detail.changedTouches[0].clientX) - progressElement.getBoundingClientRect().x;
            let position = clamp(mouseX, 0, progressElement.clientWidth);
            let percentage = clamp((position / progressElement.clientWidth), 0, 1);

            let sprite = previewResponse.sprite;
            let mouseMoveTime = videoElement.duration * percentage;
            let perIndex = parseInt(Math.floor(mouseMoveTime / sprite.frameGapSec));
            let xIndex = Math.floor(perIndex % sprite.vertical) || 0;
            let yIndex = Math.floor(perIndex / sprite.vertical);
            let thumbnail = previewResponse.thumbnail;

            this.drawImageActualSize(this.view.thumbnail, xIndex, yIndex, thumbnail.width, thumbnail.height, mouseMoveTime);

            this.time = mouseMoveTime;
            this.positionTimeStamp(this.time);

            const thumbElement = this.querySelector('.playerPreviewThumb');
            const nowElement = this.querySelector('.playerPreview-Now');

            //thumbElement.style.left = `${percentage * 100}%`;
            // * 좌우 clamp 처리
            const box = thumbElement.getBoundingClientRect();
            const limitStart = 0 + (box.width / 2);
            const limitEnd = progressElement.getBoundingClientRect().width - (box.width / 2);
            const limit = clamp(position, limitStart, limitEnd);

            thumbElement.style.left = limit + 'px';
            nowElement.style.left = `${percentage * 100}%`;
          }
        });
        progressElement.addEventListener('progress-leave', (event) => {
          this.querySelector('.playerPreview_w').classList.remove('current');
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  disconnectedCallback() {
    try {
      if (!document.body.contains(this)) {
      }
    } catch (error) {
      console.log(error);
    }
  }

  adoptedCallback() {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  set visible(value) {
    this._visible = value;
    //this.render();
  }

  get visible() {
    return this._visible;
  }

  static get config() {
    return config;
  }

  static get utils() {
    return utils;
  }

  render(url) {
    try {
      if (SbsPlayerGlobal.infoType.indexOf('onair') > -1) {
        return;
      }
      this.innerHTML = `<div class="playerPreview_w">
                  <div class="playerPreviewThumb">
                    <canvas class="playerPreview-Image" width="142" height="81" alt="프리뷰"></canvas>
                    <span class="playerPreview-time"></span>
                  </div>
                  <div class="playerPreview-Now">
                      <i class="playerPreview-dot"></i>
                  </div>
                </div>`;
      this.styles();
      const context = this.querySelector('canvas').getContext('2d');
      this.context = context;

      return new Promise((resolve, reject) => {
        const image = new Image();
        image.src = url;

        this.view.thumbnail = image;
        image.addEventListener('load', () => {
          resolve(image);
        });
        image.addEventListener('error', () => {
          reject(image);
        });
      });
    } catch (error) {
      console.log(error);
    }

  }
  styles() {
    try {
      this.querySelector('.playerPreview-time').style.color = 'white';
    } catch (error) {
      console.log(error);
    }
  }

  drawImageActualSize(thumbnailImg, xIndex, yIndex, startWidth, startHeight) {
    const startX = xIndex * startWidth;
    const startY = yIndex * startHeight;

    //image: CanvasImageSource, sx: number, sy: number, sw: number, sh: number, dx: number, dy: number, dw: number, dh: number
    this.context.drawImage(thumbnailImg, startX, startY, startWidth, startHeight, 0, 0, 142, 81);
  }

  positionTimeStamp(mouseMoveTime) {
    try {
      let time = SbsPlayerGlobal.view.querySelector('.playerPreview-time');
      time.innerText = secondsToHms(mouseMoveTime);
    } catch (error) {
      console.log(error);
    }

  }
});