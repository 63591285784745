/**
 * 플레이어 화면 구현
 */
import * as utils from '../../../../../utils';
import SbsPlayerLogger from '../../../../../utils/logger';

customElements.define('sbs-player-live-label', class extends HTMLElement {
  constructor() {
    super();
  }

  async connectedCallback() {
    try {
      this.render();
    } catch (error) {
      console.log(error);
    }
  }

  disconnectedCallback() {
    try {
      if (!document.body.contains(this)) {
      }
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    try {
      this.innerHTML = `<div class="infoText-live">라이브</div>`;
      this.styles();
    } catch (error) {
      console.log(error);
    }
  }
});