/**
 * 플레이어 화면 구현
 */
import { SbsPlayerGlobal } from '../../../../common';
import SbsPlayerLogger from '../../../../utils/logger';

customElements.define('sbs-player-video-mobile-motion', class extends HTMLElement {
  constructor() {
    super();
    this.timeout;
  }

  static get observedAttributes() { return ['motion', 'count']; }
  async attributeChangedCallback(name, oldValue, newValue) {
    try {
      if (name === 'motion' && (oldValue !== newValue)) {
        this.motion = newValue;
      }
      if (name === 'count' && (oldValue !== newValue)) {
        this.count = newValue && newValue !== 'undefined' ? newValue : undefined;
      }
      this.render();
    } catch (error) {
      console.log(error);
    }
  }

  async connectedCallback() {
    try {
      this.render();
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    try {
      if (this.motion) {
        const motionText = `${this.motion === 'backward' ? '-' : '+'} ${this.count ? this.count * 10 + ' 초' : ''}`;
        // ? https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/status_role
        this.innerHTML = `<div class="MobileMotionMoving">
          <div role="status" aria-atomic="true" aria-live="polite" class="prev10s_motion ${this.motion === 'backward' ? 'current_animation' : ''}" aria-label="${this.count * 10} 이전">
            <div class="motion_cont">
              <span class="motion_prev10s">
                <i class="icon_arrow1"></i>
                <i class="icon_arrow2"></i>
                <i class="icon_arrow3"></i>
              </span>
              <span class="motion_text">${this.motion === 'backward' ? motionText : ''}</span>
            </div>
            <i class="motion_bg"></i>
          </div>
          <div role="status" aria-atomic="true" aria-live="polite" class="next10s_motion ${this.motion === 'forward' ? 'current_animation' : ''}" aria-label="${this.count * 10} 이후">
            <div class="motion_cont">
              <span class="motion_next10s">
                <i class="icon_arrow1"></i>
                <i class="icon_arrow2"></i>
                <i class="icon_arrow3"></i>
              </span>
              <span class="motion_text">${this.motion === 'forward' ? motionText : ''}</span>
            </div>
            <i class="motion_bg"></i>
          </div>
        </div>`;
      }
      this.dispatchEvent(new CustomEvent('motion-current', { detail: true }));

      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.events();
      }, 1_500);
    } catch (error) {
      console.log(error);
    }
  }

  current() {

  }

  events() {
    try {
      const displayElement = this.querySelectorAll('.MobileMotionMoving div');
      for (let i = 0; i < displayElement.length; i++) {
        displayElement[i].classList.remove('current_animation');
      }
      this.dispatchEvent(new CustomEvent('motion-current', { detail: false }));
    } catch (error) {
      console.log(error);
    }
  }
});