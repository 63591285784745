import { SbsPlayerGlobal } from "../../common";
import { secondsToHms, importScript } from '../../utils';

// import Swiper, { Navigation, Pagination, Scrollbar } from 'swiper';
import { register } from 'swiper/element/bundle';
import { loadLocalStorage, saveLocalStorage } from '../../utils/storage';

/**
 * 화면 구현
 * 1. allvod curation button 부분
 * 
 */
customElements.define('sbs-player-episode-list', class extends HTMLElement {
  constructor() {
    super();
    this.visible = false;
    this.type = '';
    this.title = '';
    this.episodes = [];

  }

  set visible(value) {
    this._visible = value;
    this.style.display = value ? 'block' : 'none';

    const wrapper = SbsPlayerGlobal.view.querySelector('.playerWrap');
    if (value) {
      if (!wrapper.classList.contains('playerAllVODType')) wrapper.classList.add('playerAllVODType');
      wrapper.classList.add('episodeList-current');
    } else {
      wrapper.classList.remove('episodeList-current');
    }
  }

  get visible() {
    return this._visible;
  }

  static get observedAttributes() { return ['type']; }

  async attributeChangedCallback(name, oldValue, newValue) {
    try {
      if (name === 'type') {
        this.type = newValue;
      }
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * DOM에 추가, 렌더링
   * - ready: 준비만 영역 또는 로딩 표시
   * 구조상 여기서 하는게 맞는거같은데 ....
  */
  async connectedCallback() {
    try {
      // * VOD/VOD큐레이션/CLIP/CLIP큐레이션에 따라 API 분기 확인필요
      // * 큐레이션에 따라 API가 유효한지 모르겠으나 VOD/CLIP은 info 그대로 사용하면됨
      const { info, urls } = SbsPlayerGlobal.state.data;
      if (info === undefined) return false;

      this.episodes = [];
      const formatterFixBroadDate = (utcTimeString) => {
        const utcDate = new Date(utcTimeString);
        const koreanTime = new Date(utcDate.getTime() - (9 * 60 * 60 * 1000));
        const koreanDateStr = koreanTime.toLocaleDateString("ko-KR", { year: "numeric", month: "2-digit", day: "numeric" });
        return koreanDateStr.replaceAll(' ', '').replace(/\.$/, '');
      }

      const formatterDate = (utcTimeString) => {
        const utcDate = new Date(utcTimeString);
        const dateStr = utcDate.toLocaleDateString("ko-KR", { year: "numeric", month: "2-digit", day: "numeric" });
        return dateStr.replaceAll(' ', '').replace(/\.$/, '');
      }

      const getUrlSearchParams = () => {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams;
      }


      const getNextEpisode = (arr) => {
        const index = arr.findIndex(item => item.current);
        if (index !== -1 && index < arr.length - 1) {
          return arr[index + 1];
        } else {
          return arr[0]; // 순환 연속재생
        }
      }

      const params = getUrlSearchParams();
      const vodListURL = `https://apis.sbs.co.kr/play-api/1.0/sbs_vodall/contents/${info.program.fullprogramid}/${info.mediaid}?sort=new&limit=20&service=allvod&jwt-token=${SbsPlayerGlobal.token}`;
      //const clipListURL = urls.relate.indexOf('.jsp') === -1 ? urls.relate.replace('http://', 'https://') :
      const clipListURL = `https://apis.sbs.co.kr/play-api/1.0/clip/sbs_vodall/${info.program.fullprogramid}?offset=0&limit=20&contentnumber=${info.content.number}&cornerid=0&cliptype=ALL&sort=old&srsdiv=01&service=allvod`
      //const curationURL = `https://apis.sbs.co.kr/allvod-api/batch/main_csutom?cur_no=${params.get('curation')}&cur_from=${params.get('from')}`;
      const curationURL = `https://apis.sbs.co.kr/allvod-api/batch/main_csutom?cur_no=${params.get('curation') ? params.get('curation') : ''}`;
      const movieListURL = `https://apis.sbs.co.kr/play-api/1.0/sbs_vodall/contents/S16_V2000010818/100/0?sort=new&absolute_show=Y&service=allvod`;
      const CURATION_API_MAP = {
        ALLVOD: vodListURL,
        ALLVOD_CLIP: clipListURL,
        ALLVOD_CURATION_VOD: curationURL,
        ALLVOD_CURATION_CLIP: curationURL,
        ALLVOD_MOVIE: movieListURL
      };

      const url = CURATION_API_MAP[this.type]
      console.log('url', url);

      const fetched = await (await fetch(url)).json();
      console.log('fetched', fetched);


      this.episodes = (fetched.contents || fetched.list || (fetched.items && fetched.items.length > 0 ? fetched.items[0].media.items : fetched));

      console.log('this.episodes', this.episodes);

      if ((!this.episodes || this.episodes.length === 0) || (this.episodes.items && this.episodes.items.length === 0)) {
        console.warn('관련회차를 찾을 수 없습니다.');
        this.remove();
        return;
      }

      const isCuration = this.type.toLowerCase().indexOf('curation') > -1;
      const isMovie = this.type === 'ALLVOD_MOVIE';
      const isClip = this.type === 'ALLVOD_CLIP' || this.type === 'ALLVOD_CURATION_CLIP';
      const isVOD = this.type === 'ALLVOD' || this.type === 'ALLVOD_CURATION_VOD';

      // * QA.115 프로그램 템플릿에 등록한 타이틀과 별개로 예외처리
      this.title = this.type === 'ALLVOD_MOVIE' ? '다른 영화' : fetched.items && fetched.items.length > 0 ? fetched.items[0].header.title : info.program.programtitle;

      this.episodes = this.episodes.map(item => {
        let episode = {};
        episode.media = item.mediaid || item.mda_id.items[0].id;
        episode.current = (item.mediaid || item.mda_id.items[0].id) === info.mediaid;
        episode.title = isClip ? item.title : item.content?.contenttitle || item.title;
        episode.pgmTms = !isMovie ? (item.content?.contentnumber || parseInt(item.episode?.number)) : '';
        episode.desc = isCuration ? (item.program?.programtitle || item.detail) : formatterFixBroadDate(item.broaddate);
        episode.free = isClip ? false : item.free || item.badge?.free_yn === 'Y' || false;
        episode.time = isVOD || isMovie ? null : item.playtime || null;
        episode.adult = item.targetage === 19 || false;
        episode.thumbnail = item.thumb?.medium.replace('http://', 'https://')
          || item.image.items[0].img_url;

        episode.progress = parseFloat(item.playduration / item.playtime) * 100 || parseInt(item.progressRate) || 0;
        console.log('episode.progress', episode.progress);
        // ??? 와 이걸 이렇게하는게 맞냐?
        episode.link = window.location.href.replace(info.mediaid, item.mediaid || item.mda_id.items[0].id); 
        return episode;
      });
      // * 회차 오름차순 정렬, sort 파라미터와 상관없이 여러번 API 호출하지않고 배열 순서만 바꿔줌
      // * 큐레이션 / 영화 타입 일 경우 정렬 불필요...
      if (!isCuration && !isMovie) {
        this.episodes = this.episodes.sort((a, b) => {
          return parseInt(a.pgmTms) - parseInt(b.pgmTms);
        });
      }

      // * 연속 재생을 위한 데이터
      if (getNextEpisode(this.episodes)) {
        SbsPlayerGlobal.url.next_episode_link = getNextEpisode(this.episodes).link;
        SbsPlayerGlobal.next_media_id = getNextEpisode(this.episodes).media;

      }
      this.items = this.episodes;

      this.render();

      if (typeof Swiper === 'undefined') {
        console.warn(`Swiper is undefined', ${SbsPlayerGlobal.url.lib.swiper}`);
        importScript(SbsPlayerGlobal.url.lib.swiper, () => {
          this.renderItems();
        });
      } else {
        this.renderItems();
      }
    } catch (error) {
      console.log(error);
    }
  }

  disconnectedCallback() {
    try {
      const wrapper = SbsPlayerGlobal.view.querySelector('.playerWrap');
      wrapper.classList.remove('episodeList-current');
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    try {
      const { info } = SbsPlayerGlobal.state.data;

      const isCuration = this.type.toLowerCase().indexOf('curation') > -1;
      const isMovie = this.type === 'ALLVOD_MOVIE';
      const isClip = this.type === 'ALLVOD_CLIP' || this.type === 'ALLVOD_CURATION_CLIP';
      let isContinue = loadLocalStorage(SbsPlayerGlobal.storage.sbsplayer_use_continuos_play) === 'true';
      const homeURL = SbsPlayerGlobal.vodprogramhome || info.homeurl;

      this.innerHTML = `<div class= "playerEpisodeList_w">
          <div class="episodeLayer_w">
            <div class="episodeLayerTop_w">
              <a href="javascript:void(0);">
                <em class="episodeLayer_title">${this.title}
                  ${isCuration || isMovie ? '' : `<i class="iconSvg_arrow"></i>`}
                </em>
              </a>
              <div class="episodeLayerTop_cont">
                ${isCuration || isMovie || isClip ? '' : `<div class="episodeLayer_sort">
                  <a href="javascript:void(0);" class="episodeLayerTop_sortLink">최신회</a>
                  <a href="javascript:void(0);" class="episodeLayerTop_sortLink">첫회</a>
                </div>`}
                <div class="episodeLayer_autoPlay">
                  <strong class="autoPlay_title">연속재생</strong>
                  <div class="toggleBtnWrap">
                    <input type="checkbox" id="toggle" ${isContinue ? 'checked' : ''}>
                      <label for="toggle" class="toggleLabel">
                        <span class="toggleBtn">
                          <span class="iconSvg_toogleOn"><i class="hide">연속재생 On</i></span>
                          <span class="iconSvg_toogleOff"><i class="hide">연속재생 Off</i></span>
                        </span>
                      </label>
                  </div>
                </div>
                <button type="button" class="btnLayer-closed"><i class="hide">레이어 닫기</i></button>
              </div>
            </div>
            <div class="episodeList_w mySwiper">
              <button type="button" aria-label="다음 콘텐츠 보기" class="sliderBtnType1_next swiper-button-next">
                <span class="sliderBtnType1_arrow"><i class="hide">다음</i></span>
              </button>
              <div class="episodeList swiper-wrapper">
              </div>
              <button type="button" aria-label="이전 컨텐츠 보기" class="sliderBtnType1_prev swiper-button-prev">
                <span class="sliderBtnType1_arrow"><i class="hide">이전</i></span>
              </button>
            </div>
          </div>
    </ >`;


      this.querySelector('.episodeLayerTop_w a').addEventListener('click', (event) => {
        setTimeout(() => {
          window.location.href = decodeURIComponent(homeURL);
        }, 500);
      });
      const checkbox = this.querySelector('.toggleBtnWrap input');
      checkbox.addEventListener('click', (event) => {
        //event.preventDefault();
        isContinue = !isContinue;
        saveLocalStorage(SbsPlayerGlobal.storage.sbsplayer_use_continuos_play, isContinue.toString());
      });
      checkbox.addEventListener('keydown', (event) => {
        //event.preventDefault();
        if (event.key === 'Enter' || event.key === 13) {
          isContinue = !isContinue;
          saveLocalStorage(SbsPlayerGlobal.storage.sbsplayer_use_continuos_play, isContinue.toString());
          checkbox.checked = isContinue;
        }
      });
      this.querySelector('.btnLayer-closed').addEventListener('click', (event) => {
        this.style.display = 'none';
        this.visible = false;
      });
      this.querySelectorAll('.episodeLayerTop_sortLink').forEach((button) => {
        button.addEventListener('click', (event) => {
          const isLatest = button.textContent === '최신회';
          if (isLatest) {
            this.items = this.episodes.slice().reverse();
          } else {
            this.items = this.episodes;
          }



          this.renderItems();
        });
      });

      this.style.display = 'none';
    } catch (error) {
      console.log(error);
    }
  }

  renderItems() {
    try {
      const { info } = SbsPlayerGlobal.state.data;
      const isClip = this.type === 'ALLVOD_CLIP' || this.type === 'ALLVOD_CURATION_CLIP';

      const getCurrentEpisodeIndex = (arr) => {
        const index = arr.findIndex(item => item.current === true); // 대상 값의 인덱스를 찾습니다.
        if (index !== -1 && index < arr.length - 1) {
          return index; // 대상 값 다음의 아이템을 반환합니다.
        } else {
          return 0; // 대상 값이 배열의 끝에 있는 경우나 찾을 수 없는 경우 null을 반환합니다.
        }
      }

      this.querySelector('.swiper-wrapper').innerHTML = `${this.items.map((item, index) => `
              <div class="episodeList_inner swiper-slide">
                <div class="${isClip ? `clipModule_w` : `episodeModule_w`}">
                  <a href="${item.link || window.location.origin}" class="episodeListLink">
                    <div class="episodeImage">
                      <img src="${item.thumbnail}" class="episodeImg" loading="lazy" alt="${item.title}">
                      <div class="boxModuleProgress" style="width:${item.progress}%"></div>
                      ${(item.free || item.adult) ? `<span class="lableCont_w">
                        ${item.free ? `<span class="iconLable_free">FREE</span>` : ''}
                        ${item.adult ? `<span class="iconLable_19">19+</span>` : ''}
                      </span>` : ''}
                      ${item.time ? `<span class="iconLable_clipTime">${secondsToHms(item.time)}</span>` : ''}
                      ${item.current ? `<div class="iconBtnWrap_play">
                        <span class="btnModuleIcon"><i class="hide">재생</i></span>
                      </div>` : ''}
                    </div>
                    ${!isClip && item.pgmTms ? `<strong class="episodeInfo-Number">${item.pgmTms}회</strong>` : ''}
                    <strong class="episodeInfo-Title">${item.title}</strong>
                    <span class="episodeInfo-ProgramTitle">${item.desc}</span>
                  </a>
                </div>
              </div>
              ` ).join('')}`;

      const container = this.querySelector('.episodeList_w.mySwiper');
      const initialSlide = getCurrentEpisodeIndex(this.episodes);
      const slideList = new Swiper(container, {
        speed: 1000,
        initialSlide: initialSlide,
        slidesPerView: 'auto',
        slidesPerGroup: 1,
        navigation: {
          nextEl: '.swiper-button-next', //원래는 자동으로 class tag 찾아서 작동하는 것으로 아는데 ...  안되어서 click event 함수로 구현함 
          prevEl: '.swiper-button-prev'
        },
        breakpoints: {
          320: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          480: {
            slidesPerView: 2,
            spaceBetween: 0,
          },
          618: {
            slidesPerView: 3,
            spaceBetween: 0,
          },
          1180: {
            slidesPerView: 4,
            spaceBetween: 0,
          },
          1920: {
            slidesPerView: 5,
            spaceBetween: 0,
          }
        }
      });
      this.querySelector('.swiper-button-next').addEventListener("click", (e) => {
        slideList.slideNext(1000, true);
      });
      this.querySelector('.swiper-button-prev').addEventListener("click", (e) => {
        slideList.slidePrev(1000, true);
      });
    } catch (error) {
      console.error(error);
    }
  }
});