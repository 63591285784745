import { SbsPlayerGlobal } from '../../common';
import SbsPlayerLogger from '../../utils/logger';
// ! 프로그램 템플릿에서 동작안함
//import AdblockDetector from 'adblock-detector';
import Campaign from './campaign';
import LiveMidroll from './midroll/live';
import VodMidroll from './midroll/vod';
import Vendor from './vendor';

class Advertisements {
  constructor() {
    this.AdblockDetected = false;
    this.handlers = {};
    this.ad = {};
    this.live_midroll_type = null;
    this._position = null;
    this._sequence = [];
    this._campaign = {};
    this._repeat = 0;
    this._i = 0;  // preroll ad sequence number
    this._j = 0;  // preroll platform(vendor) sequence number
    this._admark_index = 0; // midroll ad sequence number(ad-markers)
    this._event = {
      initialized: () => {
        console.log(`initialized.`);
      },
      started: (position) => {
        console.log(`${position} started.`);
      },
      ended: () => {
        console.log(`${this._position} ended.`);
      },
      errored: () => {
        console.log(`${this._position} errored.`);
      },
      adblocked: () => {
        console.log(`ad blocked.`);
      },
      received: (platform, campaign, title) => {
        // console.log(`${platform}, ${JSON.stringify(campaign)}, ${title}`);
      },
      adStarted: (position) => {
        console.log(`${position} adStarted.`);
      },
      adEnded: (position) => {
        console.log(`${position} adEnded.`);
      },
      startVodMidroll: () => {
        console.log(`startVodMidroll.`);
      }
    };
  }

  get campaign() {
    return this._campaign;
  }
  set campaign(campaign) {
    return this._campaign = campaign;
  }

  get sequence() {
    return this._sequence;
  }
  set sequence(sequence) {
    return this._sequence = sequence;
  }

  get position() {
    return this._position;
  }
  set position(position) {
    this._position = position;
  }

  get repeat() {
    return this._repeat;
  }
  set repeat(repeat) {
    this._repeat = repeat;
  }

  get i() {
    return this._i;
  }
  set i(index) {
    this._i = index;
  }

  get j() {
    return this._j;
  }
  set j(index) {
    this._j = index;
  }

  get event() {
    return this._event;
  }
  set event(event) {
    this._event = event;
    this.dispatchEvent(new CustomEvent('ad-event', { detail: event }));
  }

  setAd(ad) {
    this.ad = ad;
  }

  setVodMidroll(videoDuration) {
    try {
      // * info의 videoDration 값이 이상함.
      if (this.ad.midroll_ad_repeat) {
        const vodMidroll = new VodMidroll();
        vodMidroll.init(videoDuration);
        vodMidroll.event.adStarted = () => {
          console.log(`vodMidroll adStarted`);
          this._event.adStarted('midroll');
        }
        vodMidroll.event.adEnded = () => {
          console.log(`vodMidroll adEnded`);
          this._event.adEnded('midroll');
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  getLiveMidrollQueue() {
    try {
      console.log(SbsPlayerGlobal.live_midroll.rd_queue, SbsPlayerGlobal.live_midroll.ct_queue);

    } catch (error) {
      console.log(error);
    }
  }

  setLiveMidroll = async () => {
    try {

      // * 다른 광고처럼 container에서 처리해야하는데 소스정리 필요..
      const videoElement = SbsPlayerGlobal.view.querySelector('sbs-player-wrapper sbs-player-video');
      const $video = videoElement.querySelector('video');

      let savedVideoVolume = undefined;
      let isSectionLiveMidCM = false;

      console.log(`${videoElement} ${videoElement.player} ${videoElement.video} ${$video.volume}`);

      SbsPlayerGlobal.live_midroll = null;
      SbsPlayerGlobal.live_midroll = new LiveMidroll();
      SbsPlayerGlobal.live_midroll.event.adStarted = (type, duration, title) => {
        isSectionLiveMidCM = true;
        console.log(`liveMidroll adStarted ${type} ${duration} ${title}`);
        savedVideoVolume = $video.volume;
        this.processLiveMidrollSequence(type, duration, title);
      }
      SbsPlayerGlobal.live_midroll.event.adEnded = (type) => {
        isSectionLiveMidCM = false;
        console.log(`liveMidroll adEnded ${type}`);
        $video.volume = savedVideoVolume;
        this._event.ended(type);
      }
      // * 라이브롤 
      SbsPlayerGlobal.live_midroll.event.filled = () => {
        console.log(`큐 입력을 완료한 시점이 모든 광고구간(PRE_CM, MID_CM, CM, POST_CM, SB_CM)일 경우에 즉시 광고 소재를 노출해야 함`);
        console.log(`광고구간: ${this.live_midroll_type}`);
        console.log(`광고구간 상태: ${isSectionLiveMidCM}`);

        if (this.live_midroll_type && isSectionLiveMidCM) {
          console.log(`즉시광고`);
          this.processLiveMidrollSequence(this.live_midroll_type);
        }
      }
      SbsPlayerGlobal.live_midroll.event.needed = async (slots, id3) => {
        const { content_targeting, random_targeting } = SbsPlayerGlobal.state.data.ad_30.midroll;

        console.log(`${id3 ? '연동형' : '독립형'} 광고요청 ${slots}`);
        if (id3 && SbsPlayerGlobal.live_midroll.ct_queue.length < content_targeting.minsize || !id3 && SbsPlayerGlobal.live_midroll.rd_queue.length < random_targeting.minsize) {
          const { groupCount, delaySec } = SbsPlayerGlobal.state.data.ad_30.midroll;

          const getMagicNumber = (groupCount, delaySec) => {
            const myCount = Math.floor(Math.random() * (groupCount - 1)) + 1;
            return myCount * delaySec;
          }
          const timer = ms => new Promise(res => setTimeout(res, ms));

          const vendor = new Vendor();
          vendor.platform = 'SMR';
          vendor.id3 = id3;
          vendor.slots = slots;

          // * SMR p14) 광고 요청 API Response 를 받지 못하는 Connection Timeout(1초)이 발생할 경우에는, 분산 광고 요청을 이용하여 최대 3회까지 광고 요청 API Request 를 재 전송해야 함
          for (let i = 0; i < 3; i++) {
            this._campaign = await this.request(vendor, 'midroll');
            if (this._campaign) {
              console.log('campaign', this._campaign);
              SbsPlayerGlobal.live_midroll.enQueue(id3 ? 'ct' : 'rd', this._campaign.ads);
              break;
            }
            const magicNumber = getMagicNumber(groupCount, delaySec);
            console.info(`광고 응답없음, 분산 delay: ${magicNumber}(s) 뒤(${new Date(new Date().getTime() + (magicNumber * 1000)).toLocaleTimeString()})에 ${id3 ? 'ct' : 'rd'} 광고 호출 예정`);

            await timer(magicNumber * 1000);
          }
        } else {
          console.log(`${id3 ? '연동형' : '독립형'} 광고요청 조건안됨`);
        }
      }

      SbsPlayerGlobal.live_midroll.init(videoElement.player, videoElement.video);
    } catch (error) {
      console.log(error);
    }
  }

  init(ad, videoDration) {
    const isAdBlockEnabled = () => {
      var adElement = document.createElement('div');
      adElement.innerHTML = '&nbsp;';
      adElement.className = 'ad';
      document.body.appendChild(adElement);
      var isAdBlockDetected = adElement.offsetHeight === 0;
      adElement.remove();
      return isAdBlockDetected;
    }

    //const userHasAdblock = AdblockDetector.detect();
    if (isAdBlockEnabled()) {
      this._event.adblocked();
    } else {
      this._event.initialized();
    }

    SbsPlayerGlobal.events.addEventListener('ima-ad-event', (type) => {
      try {
        if (type === 'COMPLETED') {
          this._j = 0;
        }
      } catch (e) {
        console.error(e);
      }
    });
  }

  addSequence(newData) {
    this._sequence.push(newData);
  }

  removeSequence(index) {
    this._sequence.splice(index, 1);
  }

  /**
   *광고 시퀀스에서 정해진 캠페인의 갯수만큼 광고노출을 위해 수행
   * position: prerollplus, preroll, midroll, postroll
   * @return {*} 
   * @memberof Advertisements
   */
  async processAdSequence() {
    try {
      this.live_midroll_type = null;
      console.info(this._i, this._j, 'process ad sequence,', this._position, this._sequence);

      // this._position = 'preroll'; // ? test
      // this._sequence = ['SBS'];
      // this._sequence = ['SMR']; // ? test
      // this._sequence = ['SMR', 'XC'];
      // this._sequence = ['ADX'];
      // this._sequence = [['SMR', 'XC'], ['SMR', 'XC'], ['SMR', 'XC'], ['SMR', 'XC']];

      if (this._sequence.length <= this._i) {
        this._event.ended(this._position);
        return;
      }

      const vendor = new Vendor();
      if (!Array.isArray(this._sequence[this._i])) {
        vendor.platform = this._sequence[this._i];
        console.info(this._i, this._j, 'process ad sequence, vendor.platform,', vendor.platform);
        if (vendor.platform) {
          this._campaign = await this.request(vendor, this._position);
          if (this._campaign && this._campaign.ads.length) {
            this._event.started(this._position);
            const adSequenceText = `${parseInt(this._i + 1)}/${this._sequence.length}`;
            this._event.received(vendor.platform, this._campaign, adSequenceText);
            return false;
          } else {
            // * 다음 시퀀스(벤더)
            this._i++;
            this.processAdSequence();
            return;
          }
        } else {
          this._event.ended(this._position);
        }
      } else {
        // * 시퀀스가 존재할 때
        vendor.platform = this._sequence[this._i][this._j];
        console.info(this._i, this._j, 'process ad sequence, vendor.platform,', vendor.platform);
        if (vendor.platform) {
          const adSequenceText = `${parseInt(this._i + 1)}/${this._sequence.length}`;

          // * 지져분해지지만...
          const adWrapperElement = SbsPlayerGlobal.view.querySelector('sbs-player-ad-wrapper');
          adWrapperElement.count(adSequenceText);

          // * 네트워크 광고는 캠페인 가져오지 않고 바로 진행(우리가 렌더링하지않음)
          console.info(this._i, this._j, 'process ad sequence, request,', vendor.platform);
          this._campaign = await this.request(vendor, this._position, adSequenceText);
          if (this._campaign && this._campaign.ads.length) {
            console.info(this._i, this._j, 'process ad sequence, campaign,', vendor.platform);
            this._j = 0;
            this._event.started(this._position);
            this._event.received(vendor.platform, this._campaign, adSequenceText);
            return false;
          } else {
            console.info(this._i, this._j, 'process ad sequence, campaign X,', vendor.platform);
            // * 다음 플랫폼(벤더)
            this._j++;
            this.processAdSequence();
            return;
          }
        } else {
          console.info(this._i, this._j, 'process ad sequence, platform X');

          // * 다음 시퀀스
          this._i++;
          this._j = 0;
          this.processAdSequence();
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  processLiveMidrollSequence(type) {
    try {
      this.live_midroll_type = type;
      const videoElement = SbsPlayerGlobal.view.querySelector('sbs-player-wrapper sbs-player-video');

      console.log(`processLiveMidrollSequence, type: ${this.live_midroll_type}`);
      console.log(`(${SbsPlayerGlobal.live_midroll.rd_queue.length}/${SbsPlayerGlobal.live_midroll.ct_queue.length})`);
      const ads = SbsPlayerGlobal.live_midroll.deQueue(this.live_midroll_type);
      if (ads) {
        const $video = videoElement.querySelector('video');
        $video.volume = 0; // ! test
        console.log(`(${SbsPlayerGlobal.live_midroll.rd_queue.length}/${SbsPlayerGlobal.live_midroll.ct_queue.length})`);
        //serialization
        this._campaign = {
          "response": "video", "ads": [ads], "text_ad": []
        };
        this._campaign = JSON.parse(JSON.stringify(this._campaign));
        this._position = 'live_midroll';
        this._event.started(this._position);
        this._event.received('SMR', this._campaign, '');
        return false;
      }
    } catch (error) {
      console.log(error);
    }
  }

  next() {
    try {
      if (this.live_midroll_type) {
        // * 종료 - duration timer에 의해 종료될때까지 계속 반복
        console.log(`광고종료 다음시퀀스, type: ${this.live_midroll_type}`);
        this.processLiveMidrollSequence(this.live_midroll_type);
      } else {
        this._i++;
        this.processAdSequence();
      }
    } catch (error) {
      console.error(error);
    }
  }

  skip() {
    try {
      // TODO: 여기서 트래킹로그 전송
      this._i++;
      this.processAdSequence();
    } catch (error) {
      console.error(error);
    }
  }

  stop() {
    try {
      this._event.ended();
    } catch (error) {
      console.log(error);
      this._event.errored();
    }
  }

  request(vendor, position) {
    try {
      // vendor: SMR, SBS, ADX, XC
      // position: prerollplus, preroll, midroll, postroll, live_midroll
      return vendor.request(position)
        .then((response) => {
          /**
          * * 광고요청 이후 처리
          * * 1) smr = http request => json => (rendering)
          * * 2) sbs = http request => vast => json => mapping =>> (rendering)
          * * 3) adx, xc = google.ima (AdsRequest/AdsLoader/...)
          */
          let campaign = new Campaign();
          if (vendor.platform === 'SMR') {
            return campaign.getCampaign(response);
          }
          if (vendor.platform === 'SBS') {
            return campaign.convertSbsToSmr(response);
          }
          if (vendor.platform === 'ADX' || vendor.platform === 'XC') {
            return campaign.getCampaign(response);
          }
        }).catch((error) => {
          // failed, errored
          console.error('errored/no campaign', error);
          return null;
        });
    } catch (error) {
      console.log(error);
      return null;
    }
  }
}

export default Advertisements;