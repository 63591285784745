/**
 * 화면 구현
 */
import { importScript } from '../../utils';
import SbsPlayerLogger from '../../utils/logger';

customElements.define('sbs-player-loading', class extends HTMLElement {
  constructor() {
    super();
    this.visible = false;
  }

  set visible(value) {
    this._visible = value;
    this.render();
  }

  get visible() {
    return this._visible;
  }

  static get observedAttributes() { return []; }

  async attributeChangedCallback(name, oldValue, newValue) {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  async connectedCallback() {
    try {
      importScript('https://static.cloud.sbs.co.kr/styles/lottie-player-2.0.2.js', () => {
        this.render();
      });
    } catch (error) {
      console.log(error);
    }
  }

  disconnectedCallback() {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  adoptedCallback() {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    try {
      this.innerHTML = `<div class="loading_wrap ${this.visible ? 'current' : ''}" style="display: ${this.visible ? 'block' : 'none'}">
        <lottie-player src="https://static.cloud.sbs.co.kr/styles/loading.json" background="transparent" speed="1" loop autoplay></lottie-player>
      </div>`;
      this.styles();
      this.event();
    } catch (error) {
      console.log(error);
    }
  }

  styles() {
    try {

    } catch (error) {
      console.log(error);
    }
  }

  event() {
    try {
    } catch (error) {
      console.log(error);
    }
  }
});