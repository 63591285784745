/**
 * 플레이어 화면 구현
 */
import backwardIcon from '../../../../../assets/backward.svg';
import forwardIcon from '../../../../../assets/forward.svg';
import pauseIcon from '../../../../../assets/pause.svg';
import playIcon from '../../../../../assets/play.svg';
import SbsPlayerLogger from '../../../../utils/logger';

customElements.define('sbs-player-video-desktop-motion', class extends HTMLElement {
  constructor() {
    super();
  }

  static get observedAttributes() { return ['motion', 'count']; }

  async attributeChangedCallback(name, oldValue, newValue) {
    try {
      if (name === 'motion') {
        this.motion = newValue;
      }
      if (name === 'count' && (oldValue !== newValue)) {
        this.count = newValue;
      }
      this.render();
    } catch (error) {
      console.log(error);
    }
  }

  async connectedCallback() {
    try {
      this.render();
    } catch (error) {
      console.log(error);
    }
  }
  render() {
    try {
      let iconElement = ``;
      let label = '';
      switch (this.motion) {
        case 'backward':
          label = '10초 이전';
          iconElement = `<span class="iconSvg_prev10s"><i class="hide">10초 이전</i></span>`;
          break;
        case 'forward':
          label = '10초 이후';
          // ! Next 대분자로 바뀜..T.T
          iconElement = `<span class="iconSvg_Next10s"><i class="hide">10초 이후</i></span>`;
          break;
        case 'play':
          label = '재생';
          iconElement = `<span class="iconSvg_play"><i class="hide">재생</i></span>`;
          break;
        case 'pause':
          label = '일시정지';
          iconElement = `<span class="iconSvg_pause"><i class="hide">일시정지</i></span>`;
          break;
      }
      this.innerHTML = `<div class="motionMoving" role="status" aria-live="polite" aria-atomic="true" aria-label="${label}">
        ${iconElement}
        </div>`;

      this.events();
      this.styles();
      this.icons();
    } catch (error) {
      console.log(error);
    }
  }

  events() {
    try {
      const element = this.querySelector('.motionMoving');
      element.classList.add('current');

      clearTimeout(this.motionTimer);
      this.motionTimer = setTimeout(() => {
        element.classList.remove('current');
      }, 1_500);
    } catch (error) {
      console.log(error);
    }
  }
  styles() {
    try {

    } catch (error) {
      console.log(error);
    }
  }
  icons() {
    try {
      // FIXME: 아이콘 깨지는 이슈때문에 backgroud-images 안썼던거 같은데..(김혜미 차장 문의)

      // const backwardIconElement = this.querySelector('.iconSvg_prev10s');
      // backwardIconElement?.insertAdjacentHTML('afterbegin', backwardIcon);

      // const forwardIconElement = this.querySelector('.iconSvg_next10s');
      // forwardIconElement?.insertAdjacentHTML('afterbegin', forwardIcon);

      // const playIconElement = this.querySelector('.iconSvg_play');
      // playIconElement?.insertAdjacentHTML('afterbegin', playIcon);

      // const pauseIconElement = this.querySelector('.iconSvg_pause');
      // pauseIconElement?.insertAdjacentHTML('afterbegin', pauseIcon);
    } catch (error) {
      console.log(error);
    }
  }
});