/**
 * 화면 구현
 */
import { SbsPlayerGlobal } from '../../common';

customElements.define('sbs-player-membership', class extends HTMLElement {
  constructor() {
    super();
    this.visible = false;
  }

  set visible(value) {
    this._visible = value;
    this.render();
  }

  get visible() {
    return this._visible;
  }

  static get observedAttributes() { return []; }

  async attributeChangedCallback(name, oldValue, newValue) {
    try {

    } catch (error) {
      console.log(error);
    }
  }

  async connectedCallback() {
    try {
      this.render();
    } catch (error) {
      console.log(error);
    }
  }

  disconnectedCallback() {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  adoptedCallback() {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    try {
      // 가격, 구매링크 월정액 금액 등등 관련 연동규격 필요, 아래화면이 언제 어떤상황에서 노출되는지, 기존 페이지에서 노출했던 부분임.
      // ! 하드코딩으로 결정
      this.innerHTML = `<div class="playerDialogMembership_w" role="dialog">
        <div class="playerDialogContents">
          <div class="playerDialogTop">
            <p class="playerDialogTop-text">초고화질 3분 미리보기가 종료되었습니다.</p>
            <p class="playerDialogTop-text">이용권 구매 후 시청 가능합니다.</p>
          </div>
          <div class="playerMembershipBox_w">
            <div class="playerMembershipBox">
              <strong class="playerMembershipBox-title">자유이용권S 첫구매</strong>
              <em class="playerMembershipBox-bigText">첫 달 1,000원</em>
              <span class="playerMembershipBox-smallText">월정액 최초 구매시</span>
              <a role="button" href="javascript:void(0);"  class="playerMembershipBtn">구매하기</a>
            </div>
            <div class="playerMembershipBox">
              <strong class="playerMembershipBox-title">자유이용권S 월정액</strong>
              <em class="playerMembershipBox-bigText">월 6,600원</em>
              <span class="playerMembershipBox-smallText">라이브+다시보기</span>
              <a role="button" href="javascript:void(0);"  class="playerMembershipBtn">구매하기</a>
            </div>
          </div>
          <a role="button" href="javascript:void(0);" class="playerMembershipLink_more">전체 이용권 보러 가기</a>
        </div>
      </div>`;
      this.styles();
      this.event();
    } catch (error) {
      console.log(error);
    }
  }

  styles() {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  event() {
    try {
      const linkElement = this.querySelectorAll('a[class^="playerMembership"]');
      for (let i = 0; i < linkElement.length; i++) {
        linkElement[i].addEventListener("click", (e) => {
          if (SbsPlayerGlobal.infoType === 'onair') {
            window.location.href = `https://w3.sbs.co.kr/pass/pass.do?menuType=ticket`;
          } else {
            window.open(`https://w3.sbs.co.kr/pass/pass.do?menuType=ticket`);
          }
        });
      }

      this.querySelector('.playerDialogMembership_w').classList.add('current');

    } catch (error) {
      console.log(error);
    }
  }
});