/**
 * 광고 화면 구현
 */
import SbsPlayerLogger from '../../../../utils/logger';

customElements.define('sbs-player-ad-info-live', class extends HTMLElement {
  constructor() {
    super();
    this.next = null;
  }



  static get observedAttributes() { return ['']; }

  async attributeChangedCallback(name, oldValue, newValue) {
    try {
      this.render();
    } catch (error) {
      console.log(error);
    }
  }
  async connectedCallback() {
    try {
      this.render();
      this.event();
    } catch (error) {
      console.log(error);
    }
  }

  disconnectedCallback() {
    try {
      if (!document.body.contains(this)) {

      }
    } catch (error) {
      console.log(error);
    }
  }

  render(timeLeft) {
    try {
      let skip = parseInt(timeLeft);
      let minutes = parseInt(skip / 60);
      let seconds = parseInt(skip % 60);

      this.innerHTML = `<p class="playerAD-text">
            TV광고 시간입니다.<br>
            ${minutes && minutes < 3 ? `<em class="adPoint-color">${minutes}</em>분 ` : ``}
            ${seconds && minutes < 3 ? `<em class="adPoint-color">${seconds}</em>초 후 방송 시작` : ``}
        </p>`;
      this.styles();
    } catch (error) {
      console.log(error);
    }
  }
  styles() {
    try {
    } catch (error) {
      console.error(error);
    }
  }
  event() {
    try {
    } catch (error) {
      console.error(error);
    }
  }
});