/**
 * 플레이어 화면 구현
 */
import popupIcon from '../../../../../../assets/controller_popup.svg';
import SbsPlayerLogger from '../../../../../utils/logger';

customElements.define('sbs-player-popup', class extends HTMLElement {
  constructor() {
    super();
  }

  static get observedAttributes() { return []; }

  async attributeChangedCallback(name, oldValue, newValue) {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  async connectedCallback() {
    try {
      this.render();
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    try {
      this.innerHTML = `<div class="controlBtn-popup">
                    <button type="button" class="btnIcon-popup" aria-label="팝업 모드 새창열기">
                      <span class="iconSvg_popup">
                        <i class="hide">팝업 모드</i>
                      </span>
                    </button>
                  </div>`;
      this.event();
      this.styles();
      this.icons();
    } catch (error) {
      console.log(error);
    }
  }

  event() {
    try {
      this.querySelector('button').addEventListener('click', (event) => {
        this.dispatchEvent(new CustomEvent('button-click-popup'));
      });
    } catch (error) {
      console.log(error);
    }
  }
  styles() {
    try {

    } catch (error) {
      console.log(error);
    }
  }
  icons() {
    try {
      const popupIconElement = this.querySelector('.iconSvg_popup');
      popupIconElement?.insertAdjacentHTML('afterbegin', popupIcon);
    } catch (error) {
      console.log(error);
    }
  }
});