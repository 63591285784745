import { SbsPlayerGlobal } from '../../../../common';
import SbsPlayerLogger from '../../../../utils/logger';


customElements.define('sbs-player-subtitle', class extends HTMLElement {
  constructor() {
    super();
    this.size = 100;
    this.showing = false;
  }

  static get observedAttributes() { return ['size', 'showing']; }

  async attributeChangedCallback(name, oldValue, newValue) {
    try {
      if (name === 'size' && (oldValue !== newValue)) {
        this.size = parseInt(newValue);
        this.styles();
      }
      if (name === 'showing' && (oldValue !== newValue)) {
        this.showing = newValue === 'true';
        this.render();
      }
    } catch (error) {
      console.log(error);
    }
  }

  async connectedCallback() {
    try {
      this.render();
    } catch (error) {
      console.log(error);
    }
  }

  disconnectedCallback() {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  adoptedCallback() {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    try {
      if (SbsPlayerGlobal.isMobile) return false;
      this.innerHTML = `<div class="playerSubtitle_w ${this.showing ? 'current-subtitle' : ''}">
        <p class="playerSubtitle"></p>
    </div>`;
      this.styles();
      this.event();
    } catch (error) {
      console.log(error);
    }
  }

  event() {
    try {
      SbsPlayerGlobal.events.addEventListener('video-track-cuechange', (event) => {
        if (this.showing && event.detail) {
          this.show(event.detail);
        } else {
          this.hidden();
        }
      });
    }
    catch (error) {
      this.hidden();
    }
  }

  styles() {
    try {
      this.querySelector('.playerSubtitle').style.fontSize = `${this.size}%`;
    } catch (error) {
      console.log(error);
    }
  }

  show(text) {
    try {
      this.querySelector('.playerSubtitle_w').classList.add('current-subtitle');
      this.querySelector('.playerSubtitle').innerText = text;
    } catch (error) {
      console.log(error);
    }
  }
  hidden() {
    try {
      this.querySelector('.playerSubtitle_w').classList.remove('current-subtitle');
      this.querySelector('.playerSubtitle').innerText = '';
    } catch (error) {
      console.log(error);
    }
  }
});