/**
 * 플레이퍼즈 중앙버튼
 */
import pauseIcon from '../../../../../assets/pause.svg';
import playIcon from '../../../../../assets/play.svg';
import SbsPlayerLogger from '../../../../utils/logger';

customElements.define('sbs-player-ad-center-playpause', class extends HTMLElement {
  constructor() {
    super();
    this.paused = true;
    this.visible = true;
  }

  static get observedAttributes() { return ['paused']; }

  async attributeChangedCallback(name, oldValue, newValue) {
    try {
      if (name === 'paused' && (oldValue !== newValue)) {
        this.paused = newValue === 'true';
        this.render();
      }
    } catch (error) {
      console.log(error);
    }
  }

  async connectedCallback() {
    try {
      this.render();
    } catch (error) {
      console.log(error);
    }
  }

  disconnectedCallback() {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  adoptedCallback() {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    try {
      this.innerHTML = `<button type="button" class="videoBtnToggle-play ${this.paused ? '' : 'videoBtnToggle-pause'}" aria-label="${this.paused ? '광고영상 재생' : '광고영상 일시정지'}" aria-pressed="${this.paused}" aria-haspopup="false" title="${this.paused ? '광고영상 재생' : '광고영상 일시정지'}">
      ${this.paused ? `<span class="iconSvg_play"><i class="hide">재생</i></span>` : `<span class="iconSvg_pause"><i class="hide">일시정지</i></span>`}</button>`;

      this.event();
      this.styles();
      this.icons();
    } catch (error) {
      console.log(error);
    }
  }

  event() {
    this.querySelector('button').addEventListener('click', (event) => {
      this.dispatchEvent(new CustomEvent('button-toggle-playpause', {
        detail: this.paused
      }));
    });
  }

  styles() {
    try {

    } catch (error) {
      console.log(error);
    }
  }
  icons() {
    try {
      const playIconElement = this.querySelector('.iconSvg_play');
      playIconElement?.insertAdjacentHTML('afterbegin', playIcon);

      const pauseIconElement = this.querySelector('.iconSvg_pause');
      pauseIconElement?.insertAdjacentHTML('afterbegin', pauseIcon);
    } catch (error) {
      console.log(error);
    }
  }
});