import { SbsPlayerGlobal } from '../common';
import { clamp } from '../utils';

/**
 * 키보드 단축키 입력
 * - 온에어일 경우엔 키보드 좌/우 시크 기능 제거 필요..
 */
export class Hotkey {
  // * 광고, 본영상 Wrapper나 Video에 각각 생성하지않고 콘테이너에서 생성후 아래처럼 분기, 각각 따로처리하는게 더 복잡..
  constructor(props) {
    this.$ad = props.querySelector('sbs-player-ad-wrapper');
    this.$player = props.querySelector('sbs-player-wrapper');

    this.volumeInterval = 0.1;
    this.secondInterval = 10;
    this.props = props;
    this.init();
  }

  init() {
    document.addEventListener('keydown', (event) => {
      try {
        const tag = document.activeElement.tagName.toUpperCase();
        const editable = document.activeElement.getAttribute('contenteditable');

        // * 키 입력시엔 video 렌더링 완료라고 봄
        let adVideo = this.$ad.querySelector('video');
        let playerVideo = this.$player.querySelector('video');
        let isAdsExposed = SbsPlayerGlobal.view.querySelector('.playerWrap').classList.contains('playerADType');

        let isDefaultOnairExposed = SbsPlayerGlobal.infoType === 'onair' && !SbsPlayerGlobal.view.querySelector('.playerWrap').classList.contains('timeMachineMode-on')
        let video = isAdsExposed ? adVideo : playerVideo;
        let videoDuration = video && video.duration ? video.duration - 0.05 : 0;

        if (videoDuration === 0) return false;
        if (
          tag !== 'INPUT' &&
          tag !== 'TEXTAREA' &&
          editable !== '' &&
          editable !== 'true' &&
          !event.defaultPrevented &&
          video &&
          video.id.indexOf(SbsPlayerGlobal.id.uuid) > -1
        ) {
          switch (true) {
            case (event.key === ' ' || event.keyCode === 32)
              && event.target.tagName !== 'VIDEO':
              event.preventDefault();
              if (video.paused) {
                video.play();
                this.emitEvent('play');
              } else {
                video.pause();
                this.emitEvent('pause');
              }
              break;
            case (event.key === 'ArrowRight' || event.keyCode === 39)
              && !isAdsExposed && !isDefaultOnairExposed:
              event.preventDefault();
              video.currentTime = clamp(video.currentTime + this.secondInterval, 0, videoDuration);
              this.emitEvent('forward');
              break;
            case (event.key === 'ArrowLeft' || event.keyCode === 37)
              && !isAdsExposed && !isDefaultOnairExposed:
              event.preventDefault();
              video.currentTime = clamp(video.currentTime - this.secondInterval, 0, videoDuration);
              this.emitEvent('backward');
              break;
            case event.key === 'ArrowUp' || event.keyCode === 38:
              event.preventDefault();
              video.volume = clamp(video.vol + this.volumeInterval, 0, 1);
              this.emitEvent('vol_up');
              break;
            case event.key === 'ArrowDown' || event.keyCode === 40:
              event.preventDefault();
              video.volume = clamp(video.vol - this.volumeInterval, 0, 1);
              this.emitEvent('vol_up');
              break;
            case event.key === 'm' || event.keyCode === 77:
              event.preventDefault();
              video.volume = video.volume === 0 ? 0.5 : 0;
              break;
          }


          SbsPlayerGlobal.events.emitEvent('controller-hide-event', false);
          clearTimeout(this.timoutEventHideController);
          this.timoutEventHideController = setTimeout(() => {
            SbsPlayerGlobal.events.emitEvent('controller-hide-event', true);
          }, SbsPlayerGlobal.options.hide_contorls_seconds);

          // ! 위 이벤트하고 상충되네, 그냥 hide_controls 클래스 안쓰고 display none/flex 처리하겠음
          SbsPlayerGlobal.events.emitEvent('center-hide-event', true);
          clearTimeout(this.timoutEventHideCenterVideoButtons);
          this.timoutEventHideCenterVideoButtons = setTimeout(() => {
            SbsPlayerGlobal.events.emitEvent('center-hide-event', false);
          }, 1_300);
        }
      } catch (error) {
        console.log(error);
      }
    });
  }

  emitEvent(status) {
    SbsPlayerGlobal.events.emitEvent(`motion-key-event`, status);
  }
}
