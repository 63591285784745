// TODO: 아직 기획서에 정리한 내용 적용안함, https://jisobkim.notion.site/i18n-22f509a391b34fc58fe29515396a472f
const i18nMap = {
  'ko': {
    '영상정보': 'video info',
    '닫기': 'close',
    '영상을 불러오지 못했습니다.': 'video load failed',
    '볼륨': 'volume',
    '재생': 'play',
    '정지': 'pause',
    '속도': 'rate',
    '뮤트': 'mute',
    '화면 해설': 'audio description',
    '다시 연결': 'Reconnect',
    '자막 끄기': 'hide subtitle',
    '자막 켜기': 'show subtitle',
    '자막 동기화': 'subtitle offset',
    '스크린샷': 'screenshot',
    '재생 속도': 'play speed',
    '배속': 'playback Rate',
    '화면비율': 'aspect ratio',
    '디폴트': 'default',
    '일반': 'normal',
    '영상 변경': 'wwitch video',
    '자막 변경': 'switch subtitle',
    '전체화면': 'fullscreen',
    '전체화면 종료': 'exit fullscreen',
    '웹페이지 전체화면': 'web fullscreen',
    '웹페이지 전체화면 종료': 'exit web fullscreen',
    '일반': 'common',
    '설정 닫기': 'hide setting',
    '설정 열기': 'show setting',
    '미니 플레이어': 'mini player'
  }
};

export class I18n {
  constructor({ lang }) {
    this.language = i18nMap[lang.toLowerCase()] || {};
  }

  get(key) {
    return this.language[key] || key;
  }
}
