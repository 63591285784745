import { SbsPlayerGlobal } from '../../../../../common';
import { loadCookie } from '../../../../../utils/cookie';
import SbsPlayerLogger from '../../../../../utils/logger';

/**
 * 플레이어 화면 구현
 */
customElements.define('sbs-player-quality-menu', class extends HTMLElement {
  constructor() {
    super();
    this.opened = false;
    this.quality = null;
    this.qualities = [];
    this.panel_id = `sbs-player-quality-panel-${SbsPlayerGlobal.id.uuid}`;
    this.menuitem_id = `sbs-player-quality-menu-${SbsPlayerGlobal.id.uuid}`;
  }

  set opened(value) {
    this._opened = value;
    this.render();
  }
  get opened() {
    return this._opened;
  }

  static get observedAttributes() { return ['']; }

  attributeChangedCallback(name, oldValue, newValue) {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  connectedCallback() {
    try {
      this.render();
    } catch (error) {
      console.log(error);
    }
  }

  disconnectedCallback() {
    try {
      if (!document.body.contains(this)) {
      }
    } catch (error) {
      console.log(error);
    }
  }

  adoptedCallback() {
    try {
    } catch (error) {
      console.log(error);
    }
  }
  /**
   * 어차피 현재 콘트롤러 구조에서 콘트롤러(버튼) / 세팅(패널)로 분리해서 다시 만들면 다시 작성해야함..
   * 
   */
  render() {
    try {
      const { info, source } = SbsPlayerGlobal.state.data;
      if (source === undefined) return false;
      const mediasource = source.mediasource;
      // * 높은 화질 순서로 정렬
      const mediasourcelist = source.mediasourcelist.sort((a, b) => {
        return parseInt(b.mediarscuse) - parseInt(a.mediarscuse);
      });
      if (info.type === 'VOD3MIN') return false;

      this.quality = mediasource.quality || mediasource.medianame;
      this.qualities = [];
      let items = '';
      if (mediasourcelist.length > 0) {
        mediasourcelist.forEach(item => {
          items += `<div role="menuitemradio" tabindex="0" aria-label="${item.quality || item.medianame}" ${item.default === 'Y' ? `aria-checked="true"` : ``}>
            <button type="button" class="btnLayerSelect-quality ${item.default === 'Y' ? 'current' : ''}" value="${item.mediarscuse}"}}>
            ${item.quality || item.medianame}
            </button>
          </div>`;
          let quality = {};
          quality['key'] = item.mediarscuse;
          quality['name'] = item.quality || item.medianame;

          this.qualities.push(quality)
        });
      }

      this.innerHTML = `<div id="${this.panel_id}" class="controlSelect-quality ${this.opened ? 'open-layer' : ''}">
        <button id="${this.menuitem_id}" type="button" class="btnText-qualityNow" aria-haspopup="true" aria-expanded="${this.opened ? 'true' : 'false'}">
          <span class="text-name">${this.quality}</span>
        </button>
        <div class="selectLayerWrap-quality" role="menu" style="display: ${this.opened ? `${SbsPlayerGlobal.isMobile ? 'flex' : 'block'}` : 'none'}">
          ${items}
        </div>
      </div>`;
      this.event();
    } catch (error) {
      console.log(error);
    }
  }

  event() {
    try {
      const { source } = SbsPlayerGlobal.state.data;
      const mediasourcelist = source.mediasourcelist;

      const loginJWT = SbsPlayerGlobal.token || (SbsPlayerGlobal.target.toUpperCase() === 'GOLF' ? loadCookie('LOGIN_JWT_GOLF') : loadCookie('LOGIN_JWT'));
      SbsPlayerGlobal.token = loginJWT;

      let menuitem = this.querySelector(`#${this.menuitem_id}`);
      menuitem.addEventListener('click', (event) => {
        event.preventDefault();
        this.opened = !this.opened;
        this.dispatchEvent(new CustomEvent('control-menu-toggle', { detail: this.opened }));
      });
      menuitem.addEventListener('keydown', (event) => {
        if (event.code === 'Space' || event.code === 'Enter') {
          menuitem.click();
        }
      });

      const qualityButtons = SbsPlayerGlobal.view.querySelectorAll('.selectLayerWrap-quality div');
      qualityButtons.forEach(item => {
        item.addEventListener('click', (event) => {
          event.preventDefault();
          let changeValue = event.target.tagName === 'BUTTON' ? event.target.value : event.target.querySelector('button').value;

          // 토글 닫기
          this.opened = false;
          this.dispatchEvent(new CustomEvent('control-menu-toggle', { detail: this.opened }));

          // 동일화질일 경우 그냥 닫기
          if (event.target.classList.contains('current')) {
            return false;
          }

          this.dispatchEvent(new CustomEvent('select-change-quality', { detail: changeValue }));
        });
        item.addEventListener('keydown', (event) => {
          if (event.code === 'Space' || event.code === 'Enter') {
            item.click();
          }
        });
      });
    } catch (error) {
      console.log(error);
    }
  }
});