/**
 * 플레이어 화면 구현
 */
import SbsPlayerLogger from '../../../../../utils/logger';
import { clamp } from '../../../../../utils';

customElements.define('sbs-player-ad-progressbar', class extends HTMLElement {
  constructor() {
    super();
    this.current = 0;
    this.progress = 0;
    this.rendered = false;
  }

  set current(value) {
    this._current = value;
    this.updateCurrent(value);
  }
  get current() {
    return this._current;
  }
  set progress(value) {
    this._progress = value;
    this.updateProgress(value);
  }
  get progress() {
    return this._progress;
  }


  static get observedAttributes() { return []; }

  async attributeChangedCallback(name, oldValue, newValue) {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  async connectedCallback() {
    try {
      this.render();
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    try {
      this.style.width = "100%";
      this.innerHTML = `<div class="playerSeekBar">
                <button type="button" class="playerSeekBar-boll" tabindex="-1" style="left:${this.value}%; border: none;"></button>
                <div class="playerSeekBar-now" style="width:${this.value}%;"></div>
                <div class="playerSeekBar-Loding" style="width:${this.load}%"></div>
            </div>`;
      this.event();
      this.rendered = true;
    } catch (error) {
      console.log(error);
    }
  }

  event() {
    // * this(sbs-player-progressbar) 영역이 너무 작아서 계속 leave 발생함. parentElement(.playerSeekBar_w) 를 타켓으로 변경
    const progressbar_wrapper = this.parentElement;
    progressbar_wrapper.setAttribute('aria-label', '탐색 슬라이더');
    progressbar_wrapper.setAttribute('tabindex', 0);

    progressbar_wrapper.addEventListener("click", (event) => {
      const rect = this.getBoundingClientRect();
      const value = (event.clientX - rect.left) / rect.width;
      this.dispatchEvent(new CustomEvent('progress-click', { detail: value }));
      // this.update(value);
    });
    progressbar_wrapper.addEventListener("mousemove", (event) => {
      this.dispatchEvent(new CustomEvent('progress-move', { detail: event }));
    });
    progressbar_wrapper.addEventListener("touchmove", (event) => {
      this.dispatchEvent(new CustomEvent('progress-move', { detail: event }));
    });
    progressbar_wrapper.addEventListener("mouseleave", (event) => {
      this.dispatchEvent(new CustomEvent('progress-leave', { detail: event }));
    });
    progressbar_wrapper.addEventListener("touchend", (event) => {
      this.dispatchEvent(new CustomEvent('progress-leave', { detail: event }));
    });
  }

  updateCurrent(value) {
    if (this.rendered) {
      value = clamp(value, 0, 1);
      const handle = this.querySelector('.playerSeekBar-boll');
      const now = this.querySelector('.playerSeekBar-now');
      handle.style.left = value * 100 + '%';
      now.style.width = value * 100 + '%';
    }
  }

  updateProgress(value) {
    if (this.rendered) {
      value = clamp(value, 0, 1);
      const load = this.querySelector('.playerSeekBar-Loding');
      load.style.width = value * 100 + '%';
    }
  }
});