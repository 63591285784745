/**
 * 플레이어 화면 구현
 */
import { FullScreen, Hotkey, SbsPlayerGlobal, Touch, Mouse, DoubleTap } from '../../common';
import { loadLocalStorage } from '../../utils/storage';
import { clamp } from '../../utils';

import Advertisements from '../../logic/Advertisements';
import SbsPlayerLogger from '../../utils/logger';
import '../TopArea';
import './AdWrapper';
import './PlayerWrapper';

customElements.define('sbs-player-container', class extends HTMLElement {
  constructor() {
    super();
  }

  async connectedCallback() {
    try {
      this.render();
      this.event();
    } catch (error) {
      console.log(error);
    }
  }

  async disconnectedCallback() {
    try {

    } catch (error) {
      console.log(error);
    }
  }

  render() {
    try {
      this.style.width = '100%';
      this.innerHTML = `<sbs-player-toparea></sbs-player-toparea>
      <sbs-player-ad-wrapper></sbs-player-ad-wrapper>
      <sbs-player-wrapper></sbs-player-wrapper>`;

    } catch (error) {
      console.log(error);
    }
  }

  event() {
    try {
      const adWrapperElement = this.querySelector('sbs-player-ad-wrapper');
      const playerWrapperElement = this.querySelector('sbs-player-wrapper');
      const topWrapperElement = this.querySelector('sbs-player-toparea');
      const videoElement = playerWrapperElement.querySelector('video');

      const { info, ad, ad_25, ad_30, ad_sbs, ad_adx } = SbsPlayerGlobal.state.data;
      const videoDuration = info && info.duration ? parseInt(info.duration) : null;



      this.hotkey = new Hotkey(this);
      this.mouse = new Mouse(this);
      this.touch = new Touch(this);
      this.doubleTap = new DoubleTap(this);
      this.fullscreen = new FullScreen(this);

      if (ad) {
        const smrAdVersion = (ad.smr_ad_version || '2.5').replace(/\./g, "");
        const ad_smr = this.live || smrAdVersion === '30' ? ad_30 : ad_25;
        this.ads = new Advertisements();

        this.ads.event.adStarted = (position) => {
          try {
            console.log(`${position} adStarted.`);

            if (position === 'midroll') {
              videoElement.pause();
              this.startAdSequence('midroll');
            }
            if (position === 'live_midroll') {
              // * 라이브 미드롤 뮤트인 상태로 재생
              //videoElement.volume = 0;
              this.startAdSequence('live_midroll');
            }
          } catch (error) {
            console.error(error);
          }
        }

        this.ads.event.started = (position) => {
          try {
            console.log(`position, ${position}`);
            adWrapperElement.position = position;
            adWrapperElement.style.display = 'block';
            playerWrapperElement.style.opacity = 0;
            setTimeout(() => {
              playerWrapperElement.style.display = 'none';
            }, 500);
          } catch (error) {
            console.error(error);
          }
        }
        this.ads.event.errored = () => {
          try {
            SbsPlayerGlobal.view.querySelector('.playerWrap').classList.remove('playerADType');
            this.playVideo();
          } catch (error) {
            console.error(error);
          }
        }
        this.ads.event.received = (platform, campaign, adSequenceText) => {
          try {
            console.log(`received, ${platform}, ${campaign}, ${adSequenceText}`);
            let ads = { platform: platform, campaign: campaign, adSequenceText: adSequenceText ? adSequenceText : '' };
            if (campaign) {
              adWrapperElement.platform = platform;
              adWrapperElement.render(ads);
            }

          } catch (error) {
            console.error(error);
          }
        };
        this.ads.event.ended = (position) => {
          try {
            console.log('---------------------------');
            console.log(`${position} end.`); // ? CM, MID_CM

            adWrapperElement.style.display = 'none';
            adWrapperElement.innerHTML = '';

            SbsPlayerGlobal.view.querySelector('.playerWrap').classList.remove('playerADType');
            switch (position) {
              case 'prerollplus':
                if (ad.preroll_ad_repeat || ad.preroll_ad_sequence > 0) {
                  this.ads.i = 0;
                  this.startAdSequence('preroll');
                } else {
                  playerWrapperElement.playVideo();
                }
                break;
              case 'preroll':
              case 'midroll':
                playerWrapperElement.playVideo();
                break;
              case 'postroll':
                break;
              case 'live_midroll':
                //videoElement.volume = clamp(loadLocalStorage(SbsPlayerGlobal.storage.sbsplayer_volume), 0, 1);
                break;
              default:
                playerWrapperElement.playVideo();
                break;
            }
          } catch (error) {
            console.error(error);
          }
        };

        this.ads.event.adblocked = () => {
          try {
            console.warn('---------------------------');
            console.warn('adblock-detected.');
            console.warn('---------------------------');

            if (SbsPlayerGlobal.options.allow_adblock || (info.type === 'VOD' && SbsPlayerGlobal.token)) {
              adWrapperElement.style.display = 'none';
              playerWrapperElement.playVideo();
            } else {
              this.parentElement.innerHTML = `<sbs-player-warning title="영상시청안내" html="설치/이용 중이신 AdBlock 솔루션으로 인해<br>동영상 재생이 차단되었습니다.<br>
                    <span class='ADBlock_textPoint'>SBS 홈페이지의 광고 노출을 허용해 주세요.</span>"></sbs-player-warning>`;
            }
          } catch (error) {
            console.error(error);
          }
        };

        this.ads.event.initialized = () => {
          try {
            this.ads.setAd(ad);

            // FIXME: S회원이 아니면서 프리폴플러스 있을 경우 프리롤플러스부터 시작
            if (ad.prerollplus_ad_repeat && ad_sbs.prerollplus.link) {
              this.startAdSequence('prerollplus');
              return;
            }
            if (ad.preroll_ad_repeat && ad_smr.preroll.link) {
              this.startAdSequence('preroll');
              return;
            }

            // * 광고없을 경우 바로 본영상 시작
            playerWrapperElement.playVideo();
          } catch (error) {
            console.error(error);
          }
        };

        this.ads.init(ad, videoDuration);
      }

      adWrapperElement.addEventListener('button-toggle-fullscreen', (event) => {
        this.fullscreen.toggle();
      });
      adWrapperElement.addEventListener('ad-more-click', (event) => {
        //
      });
      adWrapperElement.addEventListener('ad-skip-click', (event) => {
        console.log('ad-skip-click');
        this.ads.skip();
      });
      topWrapperElement.addEventListener('billing-popup', (event) => {
        this.dispatchEvent(new CustomEvent('billing-popup', {
          detail: event.detail
        }));
      });
      playerWrapperElement.addEventListener('billing-popup', (event) => {
        this.dispatchEvent(new CustomEvent('billing-popup', {
          detail: event.detail
        }));
      });
      playerWrapperElement.addEventListener('button-toggle-fullscreen', (event) => {
        this.fullscreen.toggle();
      });



      SbsPlayerGlobal.events.addEventListener('video-change-player', (event) => {
        if ('loadeddata' === event.type && this.ads) {
          if (SbsPlayerGlobal.infoType !== 'onair') {
            this.ads.setVodMidroll(videoDuration);
          } else {
            // ! 107~112 모바일 온에어의 경우 3분 미리보기만 중간광고 호출
            if (info.onair_preview_yn !== 'Y') {
              this.ads.setLiveMidroll();
            }
          }
        }

        let isContinuePlayBack = loadLocalStorage(SbsPlayerGlobal.storage.sbsplayer_use_continuos_play) === 'true';
        // * (포스트롤 호출 조건) 본영상 ended 이벤트 발생, postroll_ad_repeat(api) 여부, 페이지 연속재생 비활성화시
        if ('ended' === event.type && ad && ad.postroll_ad_repeat && !isContinuePlayBack) {
          // * post-roll
          this.startAdSequence('postroll');
          ad.postroll_ad_repeat = 0;
        }
      });
      SbsPlayerGlobal.events.addEventListener('video-change-ad', (event) => {
        if ('ended' === event.type) {
          this.ads.next();
        }
      });
      SbsPlayerGlobal.events.addEventListener('ima-all-ads-completed', () => {
        this.ads.next();
      });


    } catch (error) {
      console.log(error);
      playerWrapperElement.playVideo();
    }
  }

  startAdSequence = (position) => {
    try {
      console.log('startAdSequence position: ' + position);

      const { ad } = SbsPlayerGlobal.state.data;
      // * 라이브 미드롤 시퀀스는 /login/Advertisement에서 처리
      // * 통일할 필요??
      if (position.indexOf('live') > -1) return false;
      if (position) {
        this.ads.i = 0;
        this.ads.j = 0;
        this.ads.position = position;
        // * 프리롤플러스에 대한 시퀀스는 하드코딩
        this.ads.sequence = position !== 'prerollplus' ? ad[`${position}_ad_sequence`] : ['SBS'];
        this.ads.processAdSequence();
      } else {
        playerWrapperElement.playVideo();
      };
    } catch (error) {
      console.log(error);
    }
  }
});