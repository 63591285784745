/**
 * 화면 구현
 */
import { SbsPlayerGlobal } from '../../common';

customElements.define('sbs-player-toparea-pass', class extends HTMLElement {
  constructor() {
    super();

  }

  static get observedAttributes() { return []; }

  async attributeChangedCallback(name, oldValue, newValue) {
    try {

    } catch (error) {
      console.log(error);
    }
  }

  async connectedCallback() {
    try {
      this.render();
    } catch (error) {
      console.log(error);
    }
  }

  disconnectedCallback() {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  adoptedCallback() {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    try {
      if (SbsPlayerGlobal.isMobile) return false;
      if (SbsPlayerGlobal.vodtype.indexOf('ALLVOD' === -1)) return false;
      this.innerHTML = `<a role="button" class="btnBorderType-pass" aria-label="이용권 구매" title="이용권 구매" href="javascript:void(0);" style="cursor: pointer;">이용권</a>`;
      this.event();
    } catch (error) {
      console.log(error);
    }
  }

  event() {
    this.addEventListener('click', (event) => {
      window.location.href = `https://w3.sbs.co.kr/pass/pass.do?menuType=ticket&returnUrl=${encodeURIComponent(location.href)}`;
    });
  }
});