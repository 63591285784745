// import '../styles/sbs-player-1.0.0.min.css';
import { loadStyles } from '../styles';
import { EventManager, SbsPlayerGlobal } from './common';
import './components';
import './page';
import * as utils from './utils';
import { isMobile, isWebView } from './utils/navigator';
import { loadCookie } from './utils/cookie';
import { loadLocalStorage, saveLocalStorage, removeLocalStorage } from './utils/storage';
import { getAppLink } from './utils/deeplink';
import { isIOS, isAndroid } from './utils/navigator';
import SbsVideoLog from './logic/SbsVideoLog';

customElements.define('sbs-player', class extends HTMLElement {
  constructor() {
    super();


    this.attachShadow({ mode: 'open' });

    const uuid = utils.generateUUID();
    this.uuid = uuid;
    this.styled = false;
    this.options = null;
    this.width = null;
    this.height = null;
    this.view = {};
    this.state = {};
    this.media = null;
    this.rscuse = loadLocalStorage(SbsPlayerGlobal.storage.sbsplayer_rscuse);
    SbsPlayerGlobal.events = new EventManager(this);
    SbsPlayerGlobal.development = false;
    SbsPlayerGlobal.view = this.shadowRoot;
    SbsPlayerGlobal.id.uuid = this.uuid;
    SbsPlayerGlobal.options.crossorigin = 'anonymous';
    SbsPlayerGlobal.options.hide_contorls_seconds = 3_500;
    SbsPlayerGlobal.options.toast_seconds = 3_000;

    SbsPlayerGlobal.videoLog = new SbsVideoLog()
  }

  /* 모니터링 할 속성 이름 */
  static get observedAttributes() {
    return ['app_name', 'development', 'mobile', 'media', 'target', 'service'
      , 'start', 'streaming', 'crossorigin', 'muted', 'autoplay', 'src', 'poster'
      , 'mobile', 'allow_adblock', 'w', 'h', 'token', 'controls', 'hiddens'
      , 'type', 'rscuse', 'curationid', 'programhome'];
  }

  /**
   * 속성 중 하나가 추가/제거/변경
   * - 미디어, 플레이어 콘트롤 옵션 변경
   */
  async attributeChangedCallback(name, oldValue, newValue) {
    try {
      if (name === 'app_name' && (oldValue !== newValue)) {
        SbsPlayerGlobal.config.name = newValue;
      }
      if (name === 'development' && (oldValue !== newValue)) {
        //SbsPlayerGlobal.development = true;
      }
      if (name === 'media' && (oldValue !== newValue)) {
        SbsPlayerGlobal.vodmediaid = newValue;
        this.media = newValue;
      }
      if (name === 'target' && (oldValue !== newValue)) {
        SbsPlayerGlobal.target = newValue;
      }
      if (name === 'src' && (oldValue !== newValue)) {
        SbsPlayerGlobal.src = newValue;
      }
      if (name === 'poster' && (oldValue !== newValue)) {
        SbsPlayerGlobal.poster = newValue;
      }
      // ! ~~allvod 에서 program 으로 사용~~ X 그렇게 볼 수 없음
      if (name === 'service' && (oldValue !== newValue)) {
        this.service = newValue;
      }
      // * 시작시점
      if (name === 'start' && (oldValue !== newValue)) {
        SbsPlayerGlobal.options.start = parseFloat(newValue);
      }
      if (name === 'streaming' && (oldValue !== newValue)) {
        SbsPlayerGlobal.options.streaming = newValue;
      }
      if (name === 'crossorigin' && (oldValue !== newValue)) {
        SbsPlayerGlobal.options.crossorigin = newValue;
      }
      if (name === 'muted' && (oldValue !== newValue)) {
        SbsPlayerGlobal.options.muted = true;
      }
      if (name === 'autoplay' && (oldValue !== newValue)) {
        SbsPlayerGlobal.options.autoplay = true;
        SbsPlayerGlobal.options.muted = true;
      }
      if (name === 'allow_adblock' && (oldValue !== newValue)) {
        SbsPlayerGlobal.options.allow_adblock = true;
      }
      if (name === 'noad' && (oldValue !== newValue)) {
        SbsPlayerGlobal.options.noad = true;
      }
      if (name === 'w' && (oldValue !== newValue)) {
        this.width = newValue;
      }
      if (name === 'h' && (oldValue !== newValue)) {
        this.height = newValue;
      }
      if (name === 'token' && (oldValue !== newValue)) {
        this.token = newValue;
      }
      // ? info 내 config.controls 도 있고.. 설정하기 애매함, 대신 예외처리로 hiddens 시킬 콘트롤러 요구사항은 있음, 아래 hiddens 속성 추가
      if (name === 'controls' && (oldValue !== newValue)) {
        SbsPlayerGlobal.control.shows = newValue.split(",");
      }
      if (name === 'hiddens' && (oldValue !== newValue)) {
        SbsPlayerGlobal.control.hiddens = newValue.split(",");
      }
      if (name === 'rscuse' && (oldValue !== newValue)) {
        this.rscuse = newValue;
      }
      //allvod 에서 사용되는 type 
      if (name === 'type' && (oldValue !== newValue)) {
        this.type = newValue;
        SbsPlayerGlobal.vodtype = newValue;
      }
      //allvod 에서 사용되는 curationid
      if (name === 'curationid' && (oldValue !== newValue)) {
        SbsPlayerGlobal.vodcurationid = newValue;
      }
      //allvod 에서 사용되는 programhome
      if (name === 'programhome' && (oldValue !== newValue)) {
        SbsPlayerGlobal.vodprogramhome = newValue;
      }
      //allvod 에서 curation-type
      if (name === 'curationtype' && (oldValue !== newValue)) {
        SbsPlayerGlobal.vodcurationtype = newValue;
      }
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * DOM에 추가, 렌더링
   * - ready: 준비만 영역 또는 로딩 표시
   */
  async connectedCallback() {
    try {
      // ! mobile 속성 제거 -> 스크립트에서 체크후 활용
      // ! 모바일 에선 3분 미리보기만 제공함
      SbsPlayerGlobal.isMobile = isMobile();

      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);
      const paramsDevelopment = params.get('development');
      if (paramsDevelopment === 'true') SbsPlayerGlobal.development = true;

      if (isWebView()) {
        const pagePlayerWrapper = document.querySelector('.live_player_box') || document.querySelector('.player_box_video') || document.querySelector('.player_video_w');
        pagePlayerWrapper.style.display = 'none';
        return false;
      }

      this.ready();

      if (SbsPlayerGlobal.src) {
        SbsPlayerGlobal.options.autoplay = false;
        //this.renderIntro();
        this.render();

        const backward = SbsPlayerGlobal.view.querySelector('sbs-player-center-backward');
        const forward = SbsPlayerGlobal.view.querySelector('sbs-player-center-forward');
        const controls = SbsPlayerGlobal.view.querySelector('sbs-player-controls');
        const video = SbsPlayerGlobal.view.querySelector('video');

        backward.style.visibility = 'hidden';
        forward.style.visibility = 'hidden';
        controls.style.visibility = 'hidden';
        video.addEventListener('playing', () => {
          video.volume = 1;
          backward.style.visibility = 'visible';
          forward.style.visibility = 'visible';
          controls.style.visibility = 'visible';
        });
        return;
      }

      if (this.media) {
        // FIXME: 골프예외처리, 로그인 토큰
        const loginJWT = this.token || (SbsPlayerGlobal.target.toUpperCase() === 'GOLF' ? loadCookie('LOGIN_JWT_GOLF') : loadCookie('LOGIN_JWT'));
        SbsPlayerGlobal.token = loginJWT;

        this.service = SbsPlayerGlobal.isMobile ? '' : this.service;

        // * 연속재생시 화질 이어보기 위한 파라미터 추가
        const response = await this.getInfo(SbsPlayerGlobal.target || this.target || this.type, this.media, loginJWT, this.service, this.rscuse);
        SbsPlayerGlobal.state.data = response.vod || response.clip || response.onair;

        const dataEvent = new CustomEvent('info-fetched', {
          detail: SbsPlayerGlobal.state.data,
        });
        this.dispatchEvent(dataEvent);

        SbsPlayerGlobal.infoType = response.onair ? 'onair' : SbsPlayerGlobal.state.data.info.type.toLowerCase() === 'clip' ? 'clip' : 'vod';

        const { info, config, login_info } = SbsPlayerGlobal.state.data;
        // * sbsplayer_rscuse
        // * S회원일 경우 변경된 화질정보 저장
        if (login_info.is_s_member) {
          saveLocalStorage(SbsPlayerGlobal.storage.sbsplayer_rscuse, this.rscuse);
        } else {
          removeLocalStorage(SbsPlayerGlobal.storage.sbsplayer_rscuse);
        }
        SbsPlayerGlobal.options.start = info.playduration;

        // ! 조건1, 2 - 19세 다이얼로그(로그인 버튼)  - renderAdultNotice, (info.required_login === 'Y')
        // ! 조건3 - 개인정보 입력 다이얼로그, (this.state.media.login_info && this.state.media.login_info.join_info && this.state.media.login_info.join_info.info_yn === 'N')
        // ! 조건4 - 대체이미지 다이얼로그, (this.state.media.info.service_yn === 'N' && this.state.media.info.service_text === '')

        if (info.service_yn === 'N' || info.onair_yn === 'N' || info.overseas_yn === 'N' || info.required_login === 'Y') {
          //alert(info.service_text || info.overseas_text || info.service_text || "데이터를 가져올 수 없습니다.")
          const message = info.service_text || info.onair_text || info.overseas_text || "데이터를 가져올 수 없습니다.";
          let htmlDialog = ``;
          const htmlMessage = `<em class='age19_title'>${message}</em>`;
          const htmlRequriedLogin = `<em class='age19_title'>${message}</em>
          <p class='age19_text'>이용을 원하면 로그인 하세요.</p>
          <a href='#none' class='btn_login'>로그인</a>`;

          const htmlRequriedRegistInfo = `<p class='age19_text'>연령 확인을 위한 개인 정보를 등록해 주세요.</p>
                <a href='#none' class='btn_registInfo'>개인정보 등록하기</a>`;

          // ! 조건1, 조건2
          if (login_info && login_info.required_login === 'Y') {
            htmlDialog = htmlRequriedLogin;
          } else {
            // ! 조건3
            if (login_info && login_info.join_info.info_yn === 'N') {
              htmlDialog = htmlRequriedRegistInfo;
            } else {
              htmlDialog = htmlMessage;
            }  
          }

          // ! 조건4
          if (info.targetage === 19) {
            const htmlIcon = `<span class='icon_19'><span class='iconSvg_19'><i class='hide'>19</i></span></span>`;
            SbsPlayerGlobal.view.querySelector('.playerWrap').innerHTML = `<sbs-player-dialog-age-19-type icon="${htmlIcon}" html="${htmlDialog}"></sbs-player-dialog-age-19-type>`;
          } else {
            SbsPlayerGlobal.view.querySelector('.playerWrap').innerHTML = `<sbs-player-warning title="재생불가" html="${message}"></sbs-player-warning>`;
          }

        } else {
          try {
            // * allvod 
            if (config && config.control) {
              if (this.service === 'program') {
                config.control.controlbar.button['episode'] = 'Y';
              } else {
                config.control.controlbar.button['episode'] = 'N';
              }
            }
          } catch (error) {
            console.warn('* 에피소드 리스트 콘트롤 관련 예외처리, 온에어의 경우 API에 control 속성 없음', error)
          }

          this.renderIntro();

          // * 자동재생 일 경우
          if (SbsPlayerGlobal.options.autoplay) {
            try {
              let introBtn = this.shadowRoot.querySelector('sbs-player-intro button');
              introBtn.click();
            } catch (error) {
              console.log(error);
            }
          }
        }

        this.event();
      } else {
        SbsPlayerGlobal.view.querySelector('.playerWrap').innerHTML = `<sbs-player-warning title="재생오류" html="영상 정보를 가져오지 못했습니다."></sbs-player-warning>`;
      }
    } catch (error) {
      console.error(error);
      SbsPlayerGlobal.view.querySelector('.playerWrap').innerHTML = `<sbs-player-warning title="재생오류" html="서버에 접속하지 못했습니다."></sbs-player-warning>`;
    }
  }

  /**
   * DOM에서 제거, 엘리먼트를 정리
   * - 로그 전송?
   */
  disconnectedCallback() {
    try {
      console.log(`disconnectedCallback ${this.uuid}`);
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * 다른 Document에서 옮겨짐
   * 
   */
  adoptedCallback() {
    try {
    } catch (error) {
      console.log(error);
    }
  }
  static set data(data) {
    SbsPlayerGlobal.state.data = data;
  }
  static get data() {
    return SbsPlayerGlobal.state.data;
  }
  static get utils() {
    return utils;
  }

  ready() {
    try {
      this.shadowRoot.innerHTML = `<div class="playerWrap ${SbsPlayerGlobal.isMobile ? `playerMobileType` : ``}" 
      ${SbsPlayerGlobal.poster ? `style="background-image: url(${SbsPlayerGlobal.poster});background-size: cover;"` : ''}}>
      </div>`;

      this.styles();
      this.attribute();
    } catch (error) {
      console.log(error);
    }
  }

  renderIntro() {
    try {
      const { info, source } = SbsPlayerGlobal.state.data;

      const posterImg = SbsPlayerGlobal.poster || info.defaultimage || (info.thumbs ? info.thumbs['640'] : null) || info.thumbimg || `https://program-image.cloud.sbs.co.kr/${source.channel}.jpg`;

      const mediaTitle = info ? info.title.replace(/['"]+/g, '') : '플레이어';
      this.shadowRoot.querySelector('.playerWrap').innerHTML = `<sbs-player-intro image='${posterImg}' title='${mediaTitle}'></sbs-player-intro>`;

      let intro = this.shadowRoot.querySelector('sbs-player-intro');
      intro.addEventListener('button-click-intro', (event) => {
        utils.unmute();
        this.render();
      });
    } catch (error) {
      console.log(error);
    }
  }

  renderVideoEndedMembership() {
    try {
      this.shadowRoot.querySelector('.playerWrap').innerHTML = `<sbs-player-membership></sbs-player-membership>`;
    } catch (error) {
      console.log(error);
    }
  }

  renderVideoEndedLivePreview() {
    try {
      this.shadowRoot.querySelector('.playerWrap').innerHTML = `<sbs-player-dialog-live-preview></sbs-player-dialog-live-preview>`;
    } catch (error) {
      console.log(error);
    }
  }

  renderEndClipListLayer() {
    try {
      const endClipList = this.shadowRoot.querySelector('.playerWrap').querySelector('sbs-player-end-clip-list-layer');
      endClipList.style.display = 'block';
      endClipList.addEventListener('button-click-replay', (event) => {
        this.$video = SbsPlayerGlobal.view.querySelector('sbs-player-wrapper video');
        this.$video.currentTime = 0;
        this.$video.play();
      });
    } catch (error) {
      console.log(error);
    }
  }

  renderSimple() {
    try {
      this.shadowRoot.querySelector('.playerWrap').innerHTML = `<sbs-player-wrapper></sbs-player-wrapper>`;
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    try {
      const { info } = SbsPlayerGlobal.state.data;

      // 속성에 따라 광고, 콘텍스트메뉴, 쉐어, 플레이리스트 등 표시 하도록..
      this.shadowRoot.querySelector('.playerWrap').innerHTML = `<sbs-player-container></sbs-player-container>
      <sbs-player-contextmenu></sbs-player-contextmenu>
      <sbs-player-loading></sbs-player-loading>
      ${SbsPlayerGlobal.infoType !== 'onair' ? `<sbs-player-episode-list type="${SbsPlayerGlobal.vodtype}"></sbs-player-episode-list>` : ``}
      ${info && info.type.toUpperCase() === 'CLIP' ? `<sbs-player-end-clip-list-layer style="display: none;"></sbs-player-end-clip-list-layer>` : ``}
      `;
      this.event();
    } catch (error) {
      console.log(error);
    }
  }

  goAppLink() {
    try {
      let channelId = '';
      let payType = 'N'
      let playType = SbsPlayerGlobal.infoType === 'onair' ? 'live' : SbsPlayerGlobal.infoType;

      document.location = getAppLink(channelId, payType, playType);
      if (isIOS()) {
        setTimeout(() => {
          document.location = 'https://itunes.apple.com/kr/app/id1020136055';
        }, 2_000);
      }
    } catch (error) {
      console.error(error);
    }
  }

  event() {
    try {
      const { info, ad, source } = SbsPlayerGlobal.state.data;

      const contextmenu = this.shadowRoot.querySelector('sbs-player-contextmenu');
      const playerWrapperElement = this.shadowRoot.querySelector('sbs-player-wrapper');
      const playerContainerElement = this.shadowRoot.querySelector('sbs-player-container');

      let url = new URL(SbsPlayerGlobal.url.share);
      let params = new URLSearchParams(url.search);
      if (SbsPlayerGlobal.development || this.development) params.append('development', SbsPlayerGlobal.development || this.development);
      if (SbsPlayerGlobal.target || this.target) params.append('target', SbsPlayerGlobal.target || this.target);
      if (this.media) params.append('media', this.media);
      if (SbsPlayerGlobal.token || this.token) params.append('token', SbsPlayerGlobal.token || this.token);
      if (SbsPlayerGlobal.service || this.service) params.append('service', SbsPlayerGlobal.service || this.service);
      url.search = params.toString();

      const shareTitle = SbsPlayerGlobal.state.data.info.title || document.title;
      const shareURL = url.toString();

      const resizeObserver = new ResizeObserver(() => {
        const wrapper = this.shadowRoot.querySelector('.playerWrap');
        if (wrapper) {
          if (wrapper.offsetWidth >= 1920) {
            wrapper.classList.add('min1920Size');
          } else {
            wrapper.classList.remove('min1920Size');
          }
        }
      });
      resizeObserver.observe(this.shadowRoot.querySelector('.playerWrap'));

      this.addEventListener('contextmenu', (event) => {
        event.preventDefault();
        if (contextmenu) contextmenu.pointerEvent = event;
      }, false);
      this.addEventListener('click', (event) => {
        if (contextmenu) contextmenu.pointerEvent = null;
      }, false);
      playerContainerElement?.addEventListener('billing-popup', (event) => {
        event.preventDefault();
        const qcEvent = new CustomEvent('player-purchase', {
          detail: event.detail,
          bubbles: true,
          composed: true // * shadow root 밖으로 이벤트 전파
        });
        this.dispatchEvent(qcEvent);
      });

      //  copied
      contextmenu?.addEventListener('button-click-url', (event) => {
        window.navigator.clipboard.writeText(window.location.href);
      });
      contextmenu?.addEventListener('button-click-code', (event) => {
        window.navigator.clipboard.writeText(`<iframe width="640" height="480" src="${shareURL}" title="${SbsPlayerGlobal.state.data.info.title || document.title}" frameborder="0"></iframe>`);
      });

      SbsPlayerGlobal.events.addEventListener('button-click-popup', (event) => {
        window.open(shareURL, shareTitle, "width=640, height=360, resizable=yes, scrollbars=yes, menubar=no, toolbar=no, location=no");
      });

      // * 미리보기 종료
      let timerOnairPreview;
      SbsPlayerGlobal.events.addEventListener('video-change-player', (event) => {
        if ('ended' === event.type) {
          event.preventDefault();

          // * allvod 연속재생
          let isContinuePlayBack = loadLocalStorage(SbsPlayerGlobal.storage.sbsplayer_use_continuos_play) === 'true';
          const veEvent = new CustomEvent('player-ended', {
            detail: {
              cont_playback: isContinuePlayBack,
              media: this.media,
              next_media: SbsPlayerGlobal.next_media_id,
              rscuse: SbsPlayerGlobal.state.rscuse,
            },
            bubbles: true,
            composed: true // * shadow root 밖으로 이벤트 전파
          });
          this.dispatchEvent(veEvent);

          if (info && info.onair_preview_yn && info.onair_preview_yn.toUpperCase() === 'Y') {
            this.renderVideoEndedLivePreview();
            return false;
          }

          if (info && info.type.toUpperCase() === 'VOD3MIN') {
            this.renderVideoEndedMembership();
            return false;
          }

          if (info && info.type.toUpperCase() === 'CLIP') {
            this.renderEndClipListLayer();
            return false;
          }
        }
        if ('play' === event.type && info.onair_preview_yn === 'Y') {
          clearTimeout(timerOnairPreview);
          timerOnairPreview = setTimeout(() => {
            try {
              // FIXME: 골프예외처리, 온에어 프리뷰 종료
              if (SbsPlayerGlobal.target.toUpperCase() === 'GOLF') {
                sendToJS_Login();
              } else {
                SbsPlayerGlobal.infoType === 'onair' ? this.renderVideoEndedLivePreview() : this.renderVideoEndedMembership();
                SbsPlayerGlobal.videoLog.contentPlayEnd();
              }
            } catch (error) {
              console.log(error);
            }
            //}, 5 * 1000);
          }, source.duration * 1000);
        }
      });

      SbsPlayerGlobal.events.addEventListener('autoplay-failed', () => {
        try {
          console.warn('autoplay-failed');
          SbsPlayerGlobal.options.muted = true;

          this.$video = SbsPlayerGlobal.view.querySelector('sbs-player-ad-wrapper video') || SbsPlayerGlobal.view.querySelector('sbs-player-wrapper video');
          this.$video.volume = 0;
          this.$video.play();
        } catch (error) {
          console.log(error);
        }
      });

      SbsPlayerGlobal.events.addEventListener('applink-clicked', () => {
        try {
          this.goAppLink();
        } catch (error) {
          console.log(error);
        }
      });

    } catch (error) {
      console.log(error);
    }
  }

  attribute() {
    try {
      this.shadowRoot.querySelector('.playerWrap').setAttribute('id', this.uuid);
    } catch (error) {
      console.log(error);
    }
  };

  styles() {
    try {
      loadStyles(this);
      if (this.width && this.height) {
        const attrStyles = document.createElement('style');
        const styles = `.playerWrap {
          width: ${this.width} !important;
          height: ${this.height} !important;
        }

        .playerWrap sbs-player-ad-wrapper {
          visibility: hidden !important;
        }

        .playerADType sbs-player-ad-wrapper {
          visibility: visible !important;
        }

        .playerADType sbs-player-end-clip-list-layer {
          display: none !important;
        }
        `;
        attrStyles.textContent = styles;
        this.shadowRoot.appendChild(attrStyles);
      }
    } catch (error) {
      console.log(error);
    }
  }

  // FIXME: info 호출시 파라미터 정리 필요

  /**
  * 
  * @param {string} target SBS, LIVE, LIVE_24TV, GOLF, NEWS
  * @param {string} id Media ID
  * @param {string} token jwt-token
  * @param {string} service program, 삭제예정
  * @param {string} rscuse 02, 05, 07 화질

  * @return {*} 
  */
  getInfo(target, id, token, service, rscuse) {
    try {
      target ??= 'SBS';
      service ??= '';
      rscuse ??= '';

      console.log(`getInfo ${SbsPlayerGlobal.development}, ${target}, ${id}, ${token}`);

      let apiUrl;
      switch (target.toUpperCase()) {
        case 'LIVE':
          apiUrl = `https://${SbsPlayerGlobal.development ? 'dev.' : ''}apis.sbs.co.kr/play-api/1.0/onair/channel`;
          rscuse = '';
          break;
        case 'LIVE_24TV':
          apiUrl = `https://${SbsPlayerGlobal.development ? 'dev.' : ''}apis.sbs.co.kr/play-api/1.0/onair/virtual/channel`;
          rscuse = '';
          break;
        case 'SMR':
          apiUrl = `https://${SbsPlayerGlobal.development ? 'dev.' : ''}apis.sbs.co.kr/play-api/1.0/media`;
          break;
        case 'GOLF':
          apiUrl = `https://${SbsPlayerGlobal.development ? 'dev.' : ''}apis.sbs.co.kr/play-api/golf/1.0/sbs_vodall`;
          break;
        case 'NEWS':
          apiUrl = `https://${SbsPlayerGlobal.development ? 'dev.' : ''}apis.sbs.co.kr/play-api/1.0/sbs_newsmedia`;
          break;
        default:
          apiUrl = `https://${SbsPlayerGlobal.development ? 'dev.' : ''}apis.sbs.co.kr/play-api/1.0/sbs_vodall`;
          break;
      }
      console.log(`URL: ${apiUrl + "/" + id}`);
      const url = new URL(apiUrl + "/" + id);
      //url.protocol = `${SbsPlayerGlobal.development || process.env.NODE_ENV ? 'http:' : 'https:'}`;
      url.protocol = 'https:';
      url.searchParams.set('jwt-token', token);
      url.searchParams.set('platform', SbsPlayerGlobal.isMobile ? 'mobileweb' : 'pcweb');
      // ! ~~service=program 여부에 따라 VOD3MIN, VODMALL_FULL_AD 나뉨~~ 무료/유료 프로그램에 따라 API 에서 나눠서 노출
      // ! ~~service=program 일 경우 ALLVOD라고 판단하면됨~~ (수정) ALLVOD로 판단할 수 없음
      // ! absolute_show=Y 운영자가 미리 회차를 편성하는 경우에 데이터 노출을 위해 사용 (플레이어 입장에선 고정)
      url.searchParams.set('service', service); // 어차피 API 에서 삭제 예정
      url.searchParams.set('absolute_show', service ? 'Y' : '');
      url.searchParams.set('ssl', 'Y');
      //url.searchParams.set('rscuse', this.type.toLowerCase().indexOf('allvod') > -1 ? '05' : '');
      url.searchParams.set('rscuse', rscuse);
      // * LIVE(v_type=2&platform=pcweb&protocol=hls&ssl=Y&rscuse=&extra=)
      if (target.toUpperCase().indexOf('LIVE') > -1) {
        url.searchParams.set('v_type', '2');
        // * Golf 예외처리 - VOD 중 HLS 스트리밍으로 재생이 안되는 경우 발생 [hls → mp4streaming 으로 변경(2023/04/14)]
        url.searchParams.set('protocol', SbsPlayerGlobal.options.streaming ? SbsPlayerGlobal.options.streaming : 'hls'); // hls, download
        url.searchParams.set('extra', '');
      }
      SbsPlayerGlobal.url.info = url;
      const response = fetch(`${url}`);
      return response.then(res => res.json());
    } catch (error) {
      return null;
    }
  }
});