import { SbsPlayerGlobal } from "../../common";
import { importScript } from '../../utils';

// import Swiper, { Navigation, Pagination, Scrollbar } from 'swiper';

/**
 * 화면 구현
 * 
 */
customElements.define('sbs-player-end-clip-list-layer', class extends HTMLElement {
  constructor() {
    super();
    this.visible = true;
    this.item = [];
  }

  set visible(value) {
    this._visible = value;
    this.render();
  }

  get visible() {
    return this._visible;
  }

  static get observedAttributes() { return ['']; }

  async attributeChangedCallback(name, oldValue, newValue) {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * DOM에 추가, 렌더링
   * - ready: 준비만 영역 또는 로딩 표시
   * 구조상 여기서 하는게 맞는거같은데 ....
  */
  async connectedCallback() {
    try {
      const { info, urls } = SbsPlayerGlobal.state.data;
      if (info === undefined) return false;

      const clipListURL = `https://www.sbs.co.kr/json/2023main/bottom.json`
      const url = clipListURL
      const fetched = await (await fetch(url)).json();

      this.item = (fetched.items.find(item => item.type === 'POP_CLIP'));
      if (!this.item || this.item.contents.length === 0) {
        console.warn('관련회차를 찾을 수 없습니다.');
        return false;
      }

      this.items = this.item.contents.map(item => {
        // * 따로 명시안되어 있어서 아래와같이 추가
        item.current = (item.link_url.indexOf(info.mediaid) > -1);
        return item;
      });

      this.render();

      importScript(SbsPlayerGlobal.url.lib.swiper, () => {
        this.renderItems();
      });

    } catch (error) {
      console.log(error);
    }
  }

  disconnectedCallback() {
    try {
      const wrapper = SbsPlayerGlobal.view.querySelector('.playerWrap');
      wrapper.classList.remove('episodeList-current');
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    try {
      this.innerHTML = `<div class="playerClipLayer_w ${this.visible ? 'current' : ''}">
                      <div class="playerClipContents">
                        <a href="javascript:void(0);" class="btn_clipReplay">
                          <span class="iconSvg_clipReplay"></span>
                          <span class="text_clipReplay">다시보기</span>
                        </a>
                        <div class="playerClipList_w">
                          <em class="playerClipList_title">인기 클립</em>
                          <ul class="playerClipList swiper-wrapper">
                          </ul>
                        </div>
                      </div>
                    </div>`;

      this.querySelector('.btn_clipReplay').addEventListener('click', (event) => {
        this.style.display = 'none';
        this.dispatchEvent(new CustomEvent('button-click-replay'));
      });
      this.querySelector('.btn_clipReplay').addEventListener('touchstart', (event) => {
        this.style.display = 'none';
        this.dispatchEvent(new CustomEvent('button-click-replay'));
      });
    } catch (error) {
      console.log(error);
    }
  }

  renderItems() {
    try {

      const getCurrentIndex = (arr) => {
        const index = arr.findIndex(item => item.current === true); // 대상 값의 인덱스를 찾습니다.
        if (index !== -1 && index < arr.length - 1) {
          return index; // 대상 값 다음의 아이템을 반환합니다.
        } else {
          return 0; // 대상 값이 배열의 끝에 있는 경우나 찾을 수 없는 경우 null을 반환합니다.
        }
      }

      this.querySelector('.swiper-wrapper').innerHTML = `${this.items.map((item, index) => `
              <li class="playerClipList_inner">
                <a href="${item.link_url}" class="playerClipLink">
                  <span class="playerClipImage">
                    <img src="${item.img_url}" class="playerClipImg" alt="${item.img_attr}">
                      <span class="playerClipTime">${item.plytm}</span>
                  </span>
                  <strong class="playerClipTitle">${item.content}</strong>
                </a>
              </li>
              ` ).join('')}`;

      const container = this.querySelector('.playerClipList');
      const initialSlide = getCurrentIndex(this.items);
      const slideList = new Swiper(container, {
        speed: 1000,
        initialSlide: initialSlide,
        slidesPerView: 'auto',
        slidesPerGroup: 1,
        breakpoints: {
          320: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          480: {
            slidesPerView: 2,
            spaceBetween: 0,
          },
          618: {
            slidesPerView: 3,
            spaceBetween: 0,
          },
          1180: {
            slidesPerView: 4,
            spaceBetween: 0,
          },
          1920: {
            slidesPerView: 5,
            spaceBetween: 0,
          }
        }
      });
    } catch (error) {
      console.error(error);
    }
  }
});