import { clamp } from '../utils';
import SbsPlayerLogger from '../utils/logger';
import { SbsPlayerGlobal } from './global';


/**
 * 모바일 더블터치시 seek
 *
 * @export
 * @class Touch
 */
export class DoubleTap {
  constructor(props) {
    this.$container = props.querySelector('sbs-player-wrapper');
    this.time = 0;
    this.timestamp = 0;
    this.doubleTapCount = 0;
    this.doubleTapArea = 'left';
    this.doubleTapResetTimer = null;
    this.secondInterval = 10;
    this.props = props;
    this.init();
  }

  init() {
    if (!SbsPlayerGlobal.isMobile) return false;
    if (SbsPlayerGlobal.infoType === 'onair') return false;

    this.$container.addEventListener('touchend', (event) => {
      try {
        let touchArea;
        let video = this.$container.querySelector('video');
        let touchX = event.changedTouches[0].clientX;
        let middle = this.$container.offsetWidth / 2;
        let videoDuration = video.duration - 0.05;

        const currentTime = new Date().getTime();

        // * 더블탭 500ms 내 발생시 카운트 증가
        if (this.doubleTapResetTimer) clearTimeout(this.doubleTapResetTimer);
        this.doubleTapResetTimer = setTimeout(() => {
          console.log('doubleTapCount reset.');
          this.doubleTapCount = 0;
        }, 500);

        // * 더블탭 위치 변경시 카운트 초기화
        if (touchX < middle) {
          if (this.doubleTapArea === 'right') this.doubleTapCount = 0;
          this.doubleTapArea = 'left';
        } else {
          if (this.doubleTapArea === 'left') this.doubleTapCount = 0;
          this.doubleTapArea = 'right';
        }

        // * double tap
        if (currentTime - this.timestamp < 300) {
          this.doubleTapCount++;

          if (this.doubleTapArea === 'left') {
            video.currentTime = clamp(video.currentTime - this.secondInterval, 0, videoDuration);
            SbsPlayerGlobal.events.emitEvent('double-touch-backward', this.doubleTapCount);
          } else {
            video.currentTime = clamp(video.currentTime + this.secondInterval, 0, videoDuration);
            SbsPlayerGlobal.events.emitEvent('double-touch-forward', this.doubleTapCount);
          }
        } else {
          this.time = video.currentTime;
          this.timestamp = currentTime;
        }
      } catch (error) {
        console.log(error);
      }
    });
  }
}
