import { SbsPlayerGlobal } from '../../../common';
import { toFormattedDate } from '../../../utils';
import SbsPlayerLogger from '../../../utils/logger';

class SmrAdServer {
  constructor() {
    this.position = '';
    this.live = false;
    this.uuid = '';
    this.tid = '';
    this.url = '';
    this.slots = 0;
    this.payload = {};
    this._data = {};
    this._medialog = {};
    this._event = {
      initialized: () => {
        console.log(`initialized.`);
      },
      succeeded: () => {
        console.log(`succeeded.`);
      },
      waiting: () => {
        console.log(`waiting.`);
      },
      failed: () => {
        console.log(`failed.`);
      },
      passed: () => {
        console.log(`passed.`);
      },
      errored: () => {
        console.log(`errored.`);
      }
    };
  }

  get data() {
    return this._data;
  }

  set data(data) {
    return this._data = data;
  }

  get medialog() {
    return this._medialog;
  }

  set medialog(medialog) {
    return this._medialog = medialog;
  }

  get event() {
    return this._event;
  }

  set event(event) {
    return this._event = event;
  }

  initialize(position, id3, slots) {
    try {
      this.position = position;
      let apiConvertJwt = SbsPlayerGlobal.url.api.jwt;
      fetch(`${apiConvertJwt}?value=${encodeURIComponent(JSON.stringify(this.createRequestPayload(position, id3, slots)))}`).then((response) => {
        if (response.status === 200) {
          response.json().then((result) => {
            this.slots = slots;
            this.payload = result.value;
            this._event.initialized();
          });
        } else {
          this._event.errored();
        }
      }).catch(function (err) {
        console.error(err);
        this._event.errored();
      });
    } catch (error) {
      console.log(error);
      this._event.errored();
    }
  }

  createRequestPayload(position, id3, slots) {
    const { ad_30 } = SbsPlayerGlobal.state.data;
    this.live = slots ? true : false;

    let uuid = this.uuid;
    let tid = this.tid;
    let cmdata = slots ? ad_30.cmdata : this._data.cmdata;
    let site = this.position === 'preroll' && this._data.preroll.sites && this._data.preroll.sites.length > 0 ? this._data.preroll.sites.shift() : cmdata.site;
    let payload = {
      version: cmdata.version,
      media: cmdata.media,
      site: site,
      requesttime: toFormattedDate(new Date(), 'yyyyMMddHHmmss'),
      tid: tid,
      uuid: uuid,
      ip: cmdata.ip,
      gender: cmdata.gender,
      age: cmdata.age,
      platform: SbsPlayerGlobal.isMobile ? 'MOBILEWEB' : 'PCWEB',
      playertype: 'HTML5',
      os: (navigator.userAgent.indexOf('Mac') > -1) ? 'Macintosh' : 'Windows',
      devicemodel: '',
      telco: '',
      cpid: cmdata.cpid,
      channelid: cmdata.channelid,
      category: cmdata.category,
      section: cmdata.section,
      programid: cmdata.programid,
      clipid: cmdata.clipid,
      contentnumber: cmdata.contentnumber,
      targetnation: cmdata.targetnation,
      isonair: cmdata.isonair,
      ispay: cmdata.ispay,
      vodtype: cmdata.vodtype,
      broaddate: cmdata.broaddate,
      playtime: cmdata.playtime,
      starttime: cmdata.starttime,
      endtime: cmdata.endtime,
      referrer: cmdata.referrer,
      adtype: this._data[position].adtype,
      customkeyword: this._data[position].customkeyword,
      adlink: cmdata.adlink,
    }

    // * 라이브 미드롤 예외처리
    if (id3 || SbsPlayerGlobal.live_midroll.id3) {
      payload['sectiontype'] = id3 ? id3.sectionType : SbsPlayerGlobal.live_midroll.id3.sectionType;
    }
    if (slots) {
      payload['slots'] = slots;
      payload['site'] = id3 ? ad_30.midroll.content_targeting.site : ad_30.midroll.random_targeting.site;
      // delete payload.adlink;
    }

    return payload;
  }

  useFakeResponse() {
    try {
      //const useFakeResponse = Math.random() < 0.5 ? true : false;
      const useFakeResponse = false;
      if (useFakeResponse) {
        if (this.position === 'midroll' && SbsPlayerGlobal.infoType === 'onair') {
          // FIXME: ONAIR 연동형큐 TEST하드코딩 (slot개)
          let temp = [];
          for (let i = 0; i < this.slots; i++) {
            temp.push({
              "content_url": "http://smrmembers-smr.smartmediarep.com/smc/smrmembers/single/eng/0/smr/contents/video/2020/04/03/e5d5e339c9fd780c0beb1923f20ed802_t34.mp4",
              "duration": 15,
              "house": 0,
              "tracking_url": {
                "impression": "http://admdev.smartmediarep.com/NetInsight/event/imp/1LiVNJ3ow53PNfCpkg-2a_XPvrfPUo6zpFGMR8yd92jaA9V2ZXI9My4wJm1kYT1TTVJfTUVNQkVSUyZzaXRlPVNCU19MSVZFX01JRF9DVCZyZXF0bT0yMDIzMDUyNjE1MDgzNiZ0aWQ9MWZhMDdhZmE2ODM0YWRlYzRkYTc4NDc3NWFmNjdjZTkmdXVpZD1kZWI1YjFmNGM3YTg2MTkyN2MxZmE1YWZkMjExNzBlMiZpcD0yMTguNTUuOTIuOTgmZ25kPTMmYWdlPTk5JnBsYXQ9UENXRUImcHR5cGU9SFRNTDUmb3M9TWFjaW50b3NoJmR2Y21kPSZ0ZWxjbz0mY3A9QzEmY2hubD1TMDEmY2F0ZT0wMSZzZWN0PTkwJmNscGlkPSZwcm9pZD1TMDFfVjIwMDAwMTAyMDEmY3RudW09JnRndG50PSZvbmFpcj1ZJnBheT1OJnZ0eXBlPU8mYmRhdGU9JnB0aW1lPTcyMDAmc3RpbWU9MTQwMCZldGltZT0xNjAwJmZkdXJsPWh0dHAlM0EvL2xvY2FsaG9zdCUzQTgwODAvJmFkbGluaz1jcGlkJTNEQzElMjZjaGFubmVsaWQlM0RTMDElMjZjYXRlZ29yeSUzRDAxJTI2c2VjdGlvbiUzRDkwJTI2cHJvZ3JhbWlkJTNEUzAxX1YyMDAwMDEwMjAxJTI2Y2xpcGlkJTNEJTI2Y29udGVudG51bWJlciUzRCUyNnRhcmdldG5hdGlvbiUzRCUyNmlzb25haXIlM0RZJTI2aXNwYXklM0ROJTI2dm9kdHlwZSUzRE8lMjZicm9hZGRhdGUlM0QlMjZwbGF5dGltZSUzRDcyMDAlMjZzdGFydHRpbWUlM0QxNDAwJTI2ZW5kdGltZSUzRDE2MDAmYWR0eXBlPVBSRSZrd2Q9U0VSVklDRSUzRE9OQUlSJmhvbGQ9JnNsb3RzPTEwJnNlY3Rpb250eXBlPSZvdmVyZmxvdz0mYXQ9dmlkZW8mdHRpbWU9MjAyMzA1MjYxNTA4NTkmdHRpbWUyPTIwMjMwNTI2MTUwODU5NTQ4NzkmcnRya190bT0xNjg1MDgxMzM5JnVucT1GWGdQNUlLclN0YVZDdUl6c29qQUFBJnN0ZT00NCZzZWM9MTQ2JnBnPTQwMyZsb2M9NjIzJmNtcD05MjcwJmFkPTExODY1NSZjb209NDEmaHVzPTEmYWRrd2Q9JmFka3dkYWN1cj0wJnRzeXM9MCZ0cHJnPTAmdG5rd2Q9MCZ0a3dkPTAmdHNpdGU9MTUxNTkmdG1ldGE9MCZjdHY9ODA5OTAmY2xrPTE0OTY4JmR1cj0xNSZyZXNfb3JkPTE",
                "start": "http://admdev.smartmediarep.com/NetInsight/event/start/1LiVNJ3ow53PNfCpkg-2a_XPvrfPUo6zpFGMR8yd92jaA9V2ZXI9My4wJm1kYT1TTVJfTUVNQkVSUyZzaXRlPVNCU19MSVZFX01JRF9DVCZyZXF0bT0yMDIzMDUyNjE1MDgzNiZ0aWQ9MWZhMDdhZmE2ODM0YWRlYzRkYTc4NDc3NWFmNjdjZTkmdXVpZD1kZWI1YjFmNGM3YTg2MTkyN2MxZmE1YWZkMjExNzBlMiZpcD0yMTguNTUuOTIuOTgmZ25kPTMmYWdlPTk5JnBsYXQ9UENXRUImcHR5cGU9SFRNTDUmb3M9TWFjaW50b3NoJmR2Y21kPSZ0ZWxjbz0mY3A9QzEmY2hubD1TMDEmY2F0ZT0wMSZzZWN0PTkwJmNscGlkPSZwcm9pZD1TMDFfVjIwMDAwMTAyMDEmY3RudW09JnRndG50PSZvbmFpcj1ZJnBheT1OJnZ0eXBlPU8mYmRhdGU9JnB0aW1lPTcyMDAmc3RpbWU9MTQwMCZldGltZT0xNjAwJmZkdXJsPWh0dHAlM0EvL2xvY2FsaG9zdCUzQTgwODAvJmFkbGluaz1jcGlkJTNEQzElMjZjaGFubmVsaWQlM0RTMDElMjZjYXRlZ29yeSUzRDAxJTI2c2VjdGlvbiUzRDkwJTI2cHJvZ3JhbWlkJTNEUzAxX1YyMDAwMDEwMjAxJTI2Y2xpcGlkJTNEJTI2Y29udGVudG51bWJlciUzRCUyNnRhcmdldG5hdGlvbiUzRCUyNmlzb25haXIlM0RZJTI2aXNwYXklM0ROJTI2dm9kdHlwZSUzRE8lMjZicm9hZGRhdGUlM0QlMjZwbGF5dGltZSUzRDcyMDAlMjZzdGFydHRpbWUlM0QxNDAwJTI2ZW5kdGltZSUzRDE2MDAmYWR0eXBlPVBSRSZrd2Q9U0VSVklDRSUzRE9OQUlSJmhvbGQ9JnNsb3RzPTEwJnNlY3Rpb250eXBlPSZvdmVyZmxvdz0mYXQ9dmlkZW8mdHRpbWU9MjAyMzA1MjYxNTA4NTkmdHRpbWUyPTIwMjMwNTI2MTUwODU5NTQ4NzkmcnRya190bT0xNjg1MDgxMzM5JnVucT1GWGdQNUlLclN0YVZDdUl6c29qQUFBJnN0ZT00NCZzZWM9MTQ2JnBnPTQwMyZsb2M9NjIzJmNtcD05MjcwJmFkPTExODY1NSZjb209NDEmaHVzPTEmYWRrd2Q9JmFka3dkYWN1cj0wJnRzeXM9MCZ0cHJnPTAmdG5rd2Q9MCZ0a3dkPTAmdHNpdGU9MTUxNTkmdG1ldGE9MCZjdHY9ODA5OTAmY2xrPTE0OTY4JmR1cj0xNSZyZXNfb3JkPTE",
                "firstQuartile": "http://admdev.smartmediarep.com/NetInsight/event/first/1LiVNJ3ow53PNfCpkg-2a_XPvrfPUo6zpFGMR8yd92jaA9V2ZXI9My4wJm1kYT1TTVJfTUVNQkVSUyZzaXRlPVNCU19MSVZFX01JRF9DVCZyZXF0bT0yMDIzMDUyNjE1MDgzNiZ0aWQ9MWZhMDdhZmE2ODM0YWRlYzRkYTc4NDc3NWFmNjdjZTkmdXVpZD1kZWI1YjFmNGM3YTg2MTkyN2MxZmE1YWZkMjExNzBlMiZpcD0yMTguNTUuOTIuOTgmZ25kPTMmYWdlPTk5JnBsYXQ9UENXRUImcHR5cGU9SFRNTDUmb3M9TWFjaW50b3NoJmR2Y21kPSZ0ZWxjbz0mY3A9QzEmY2hubD1TMDEmY2F0ZT0wMSZzZWN0PTkwJmNscGlkPSZwcm9pZD1TMDFfVjIwMDAwMTAyMDEmY3RudW09JnRndG50PSZvbmFpcj1ZJnBheT1OJnZ0eXBlPU8mYmRhdGU9JnB0aW1lPTcyMDAmc3RpbWU9MTQwMCZldGltZT0xNjAwJmZkdXJsPWh0dHAlM0EvL2xvY2FsaG9zdCUzQTgwODAvJmFkbGluaz1jcGlkJTNEQzElMjZjaGFubmVsaWQlM0RTMDElMjZjYXRlZ29yeSUzRDAxJTI2c2VjdGlvbiUzRDkwJTI2cHJvZ3JhbWlkJTNEUzAxX1YyMDAwMDEwMjAxJTI2Y2xpcGlkJTNEJTI2Y29udGVudG51bWJlciUzRCUyNnRhcmdldG5hdGlvbiUzRCUyNmlzb25haXIlM0RZJTI2aXNwYXklM0ROJTI2dm9kdHlwZSUzRE8lMjZicm9hZGRhdGUlM0QlMjZwbGF5dGltZSUzRDcyMDAlMjZzdGFydHRpbWUlM0QxNDAwJTI2ZW5kdGltZSUzRDE2MDAmYWR0eXBlPVBSRSZrd2Q9U0VSVklDRSUzRE9OQUlSJmhvbGQ9JnNsb3RzPTEwJnNlY3Rpb250eXBlPSZvdmVyZmxvdz0mYXQ9dmlkZW8mdHRpbWU9MjAyMzA1MjYxNTA4NTkmdHRpbWUyPTIwMjMwNTI2MTUwODU5NTQ4NzkmcnRya190bT0xNjg1MDgxMzM5JnVucT1GWGdQNUlLclN0YVZDdUl6c29qQUFBJnN0ZT00NCZzZWM9MTQ2JnBnPTQwMyZsb2M9NjIzJmNtcD05MjcwJmFkPTExODY1NSZjb209NDEmaHVzPTEmYWRrd2Q9JmFka3dkYWN1cj0wJnRzeXM9MCZ0cHJnPTAmdG5rd2Q9MCZ0a3dkPTAmdHNpdGU9MTUxNTkmdG1ldGE9MCZjdHY9ODA5OTAmY2xrPTE0OTY4JmR1cj0xNSZyZXNfb3JkPTE",
                "mid_point": "http://admdev.smartmediarep.com/NetInsight/event/mid/1LiVNJ3ow53PNfCpkg-2a_XPvrfPUo6zpFGMR8yd92jaA9V2ZXI9My4wJm1kYT1TTVJfTUVNQkVSUyZzaXRlPVNCU19MSVZFX01JRF9DVCZyZXF0bT0yMDIzMDUyNjE1MDgzNiZ0aWQ9MWZhMDdhZmE2ODM0YWRlYzRkYTc4NDc3NWFmNjdjZTkmdXVpZD1kZWI1YjFmNGM3YTg2MTkyN2MxZmE1YWZkMjExNzBlMiZpcD0yMTguNTUuOTIuOTgmZ25kPTMmYWdlPTk5JnBsYXQ9UENXRUImcHR5cGU9SFRNTDUmb3M9TWFjaW50b3NoJmR2Y21kPSZ0ZWxjbz0mY3A9QzEmY2hubD1TMDEmY2F0ZT0wMSZzZWN0PTkwJmNscGlkPSZwcm9pZD1TMDFfVjIwMDAwMTAyMDEmY3RudW09JnRndG50PSZvbmFpcj1ZJnBheT1OJnZ0eXBlPU8mYmRhdGU9JnB0aW1lPTcyMDAmc3RpbWU9MTQwMCZldGltZT0xNjAwJmZkdXJsPWh0dHAlM0EvL2xvY2FsaG9zdCUzQTgwODAvJmFkbGluaz1jcGlkJTNEQzElMjZjaGFubmVsaWQlM0RTMDElMjZjYXRlZ29yeSUzRDAxJTI2c2VjdGlvbiUzRDkwJTI2cHJvZ3JhbWlkJTNEUzAxX1YyMDAwMDEwMjAxJTI2Y2xpcGlkJTNEJTI2Y29udGVudG51bWJlciUzRCUyNnRhcmdldG5hdGlvbiUzRCUyNmlzb25haXIlM0RZJTI2aXNwYXklM0ROJTI2dm9kdHlwZSUzRE8lMjZicm9hZGRhdGUlM0QlMjZwbGF5dGltZSUzRDcyMDAlMjZzdGFydHRpbWUlM0QxNDAwJTI2ZW5kdGltZSUzRDE2MDAmYWR0eXBlPVBSRSZrd2Q9U0VSVklDRSUzRE9OQUlSJmhvbGQ9JnNsb3RzPTEwJnNlY3Rpb250eXBlPSZvdmVyZmxvdz0mYXQ9dmlkZW8mdHRpbWU9MjAyMzA1MjYxNTA4NTkmdHRpbWUyPTIwMjMwNTI2MTUwODU5NTQ4NzkmcnRya190bT0xNjg1MDgxMzM5JnVucT1GWGdQNUlLclN0YVZDdUl6c29qQUFBJnN0ZT00NCZzZWM9MTQ2JnBnPTQwMyZsb2M9NjIzJmNtcD05MjcwJmFkPTExODY1NSZjb209NDEmaHVzPTEmYWRrd2Q9JmFka3dkYWN1cj0wJnRzeXM9MCZ0cHJnPTAmdG5rd2Q9MCZ0a3dkPTAmdHNpdGU9MTUxNTkmdG1ldGE9MCZjdHY9ODA5OTAmY2xrPTE0OTY4JmR1cj0xNSZyZXNfb3JkPTE",
                "thirdQuartile": "http://admdev.smartmediarep.com/NetInsight/event/third/1LiVNJ3ow53PNfCpkg-2a_XPvrfPUo6zpFGMR8yd92jaA9V2ZXI9My4wJm1kYT1TTVJfTUVNQkVSUyZzaXRlPVNCU19MSVZFX01JRF9DVCZyZXF0bT0yMDIzMDUyNjE1MDgzNiZ0aWQ9MWZhMDdhZmE2ODM0YWRlYzRkYTc4NDc3NWFmNjdjZTkmdXVpZD1kZWI1YjFmNGM3YTg2MTkyN2MxZmE1YWZkMjExNzBlMiZpcD0yMTguNTUuOTIuOTgmZ25kPTMmYWdlPTk5JnBsYXQ9UENXRUImcHR5cGU9SFRNTDUmb3M9TWFjaW50b3NoJmR2Y21kPSZ0ZWxjbz0mY3A9QzEmY2hubD1TMDEmY2F0ZT0wMSZzZWN0PTkwJmNscGlkPSZwcm9pZD1TMDFfVjIwMDAwMTAyMDEmY3RudW09JnRndG50PSZvbmFpcj1ZJnBheT1OJnZ0eXBlPU8mYmRhdGU9JnB0aW1lPTcyMDAmc3RpbWU9MTQwMCZldGltZT0xNjAwJmZkdXJsPWh0dHAlM0EvL2xvY2FsaG9zdCUzQTgwODAvJmFkbGluaz1jcGlkJTNEQzElMjZjaGFubmVsaWQlM0RTMDElMjZjYXRlZ29yeSUzRDAxJTI2c2VjdGlvbiUzRDkwJTI2cHJvZ3JhbWlkJTNEUzAxX1YyMDAwMDEwMjAxJTI2Y2xpcGlkJTNEJTI2Y29udGVudG51bWJlciUzRCUyNnRhcmdldG5hdGlvbiUzRCUyNmlzb25haXIlM0RZJTI2aXNwYXklM0ROJTI2dm9kdHlwZSUzRE8lMjZicm9hZGRhdGUlM0QlMjZwbGF5dGltZSUzRDcyMDAlMjZzdGFydHRpbWUlM0QxNDAwJTI2ZW5kdGltZSUzRDE2MDAmYWR0eXBlPVBSRSZrd2Q9U0VSVklDRSUzRE9OQUlSJmhvbGQ9JnNsb3RzPTEwJnNlY3Rpb250eXBlPSZvdmVyZmxvdz0mYXQ9dmlkZW8mdHRpbWU9MjAyMzA1MjYxNTA4NTkmdHRpbWUyPTIwMjMwNTI2MTUwODU5NTQ4NzkmcnRya190bT0xNjg1MDgxMzM5JnVucT1GWGdQNUlLclN0YVZDdUl6c29qQUFBJnN0ZT00NCZzZWM9MTQ2JnBnPTQwMyZsb2M9NjIzJmNtcD05MjcwJmFkPTExODY1NSZjb209NDEmaHVzPTEmYWRrd2Q9JmFka3dkYWN1cj0wJnRzeXM9MCZ0cHJnPTAmdG5rd2Q9MCZ0a3dkPTAmdHNpdGU9MTUxNTkmdG1ldGE9MCZjdHY9ODA5OTAmY2xrPTE0OTY4JmR1cj0xNSZyZXNfb3JkPTE",
                "complete": "http://admdev.smartmediarep.com/NetInsight/event/complete/1LiVNJ3ow53PNfCpkg-2a_XPvrfPUo6zpFGMR8yd92jaA9V2ZXI9My4wJm1kYT1TTVJfTUVNQkVSUyZzaXRlPVNCU19MSVZFX01JRF9DVCZyZXF0bT0yMDIzMDUyNjE1MDgzNiZ0aWQ9MWZhMDdhZmE2ODM0YWRlYzRkYTc4NDc3NWFmNjdjZTkmdXVpZD1kZWI1YjFmNGM3YTg2MTkyN2MxZmE1YWZkMjExNzBlMiZpcD0yMTguNTUuOTIuOTgmZ25kPTMmYWdlPTk5JnBsYXQ9UENXRUImcHR5cGU9SFRNTDUmb3M9TWFjaW50b3NoJmR2Y21kPSZ0ZWxjbz0mY3A9QzEmY2hubD1TMDEmY2F0ZT0wMSZzZWN0PTkwJmNscGlkPSZwcm9pZD1TMDFfVjIwMDAwMTAyMDEmY3RudW09JnRndG50PSZvbmFpcj1ZJnBheT1OJnZ0eXBlPU8mYmRhdGU9JnB0aW1lPTcyMDAmc3RpbWU9MTQwMCZldGltZT0xNjAwJmZkdXJsPWh0dHAlM0EvL2xvY2FsaG9zdCUzQTgwODAvJmFkbGluaz1jcGlkJTNEQzElMjZjaGFubmVsaWQlM0RTMDElMjZjYXRlZ29yeSUzRDAxJTI2c2VjdGlvbiUzRDkwJTI2cHJvZ3JhbWlkJTNEUzAxX1YyMDAwMDEwMjAxJTI2Y2xpcGlkJTNEJTI2Y29udGVudG51bWJlciUzRCUyNnRhcmdldG5hdGlvbiUzRCUyNmlzb25haXIlM0RZJTI2aXNwYXklM0ROJTI2dm9kdHlwZSUzRE8lMjZicm9hZGRhdGUlM0QlMjZwbGF5dGltZSUzRDcyMDAlMjZzdGFydHRpbWUlM0QxNDAwJTI2ZW5kdGltZSUzRDE2MDAmYWR0eXBlPVBSRSZrd2Q9U0VSVklDRSUzRE9OQUlSJmhvbGQ9JnNsb3RzPTEwJnNlY3Rpb250eXBlPSZvdmVyZmxvdz0mYXQ9dmlkZW8mdHRpbWU9MjAyMzA1MjYxNTA4NTkmdHRpbWUyPTIwMjMwNTI2MTUwODU5NTQ4NzkmcnRya190bT0xNjg1MDgxMzM5JnVucT1GWGdQNUlLclN0YVZDdUl6c29qQUFBJnN0ZT00NCZzZWM9MTQ2JnBnPTQwMyZsb2M9NjIzJmNtcD05MjcwJmFkPTExODY1NSZjb209NDEmaHVzPTEmYWRrd2Q9JmFka3dkYWN1cj0wJnRzeXM9MCZ0cHJnPTAmdG5rd2Q9MCZ0a3dkPTAmdHNpdGU9MTUxNTkmdG1ldGE9MCZjdHY9ODA5OTAmY2xrPTE0OTY4JmR1cj0xNSZyZXNfb3JkPTE",
                "skip": "http://admdev.smartmediarep.com/NetInsight/event/skip/1LiVNJ3ow53PNfCpkg-2a_XPvrfPUo6zpFGMR8yd92jaA9V2ZXI9My4wJm1kYT1TTVJfTUVNQkVSUyZzaXRlPVNCU19MSVZFX01JRF9DVCZyZXF0bT0yMDIzMDUyNjE1MDgzNiZ0aWQ9MWZhMDdhZmE2ODM0YWRlYzRkYTc4NDc3NWFmNjdjZTkmdXVpZD1kZWI1YjFmNGM3YTg2MTkyN2MxZmE1YWZkMjExNzBlMiZpcD0yMTguNTUuOTIuOTgmZ25kPTMmYWdlPTk5JnBsYXQ9UENXRUImcHR5cGU9SFRNTDUmb3M9TWFjaW50b3NoJmR2Y21kPSZ0ZWxjbz0mY3A9QzEmY2hubD1TMDEmY2F0ZT0wMSZzZWN0PTkwJmNscGlkPSZwcm9pZD1TMDFfVjIwMDAwMTAyMDEmY3RudW09JnRndG50PSZvbmFpcj1ZJnBheT1OJnZ0eXBlPU8mYmRhdGU9JnB0aW1lPTcyMDAmc3RpbWU9MTQwMCZldGltZT0xNjAwJmZkdXJsPWh0dHAlM0EvL2xvY2FsaG9zdCUzQTgwODAvJmFkbGluaz1jcGlkJTNEQzElMjZjaGFubmVsaWQlM0RTMDElMjZjYXRlZ29yeSUzRDAxJTI2c2VjdGlvbiUzRDkwJTI2cHJvZ3JhbWlkJTNEUzAxX1YyMDAwMDEwMjAxJTI2Y2xpcGlkJTNEJTI2Y29udGVudG51bWJlciUzRCUyNnRhcmdldG5hdGlvbiUzRCUyNmlzb25haXIlM0RZJTI2aXNwYXklM0ROJTI2dm9kdHlwZSUzRE8lMjZicm9hZGRhdGUlM0QlMjZwbGF5dGltZSUzRDcyMDAlMjZzdGFydHRpbWUlM0QxNDAwJTI2ZW5kdGltZSUzRDE2MDAmYWR0eXBlPVBSRSZrd2Q9U0VSVklDRSUzRE9OQUlSJmhvbGQ9JnNsb3RzPTEwJnNlY3Rpb250eXBlPSZvdmVyZmxvdz0mYXQ9dmlkZW8mdHRpbWU9MjAyMzA1MjYxNTA4NTkmdHRpbWUyPTIwMjMwNTI2MTUwODU5NTQ4NzkmcnRya190bT0xNjg1MDgxMzM5JnVucT1GWGdQNUlLclN0YVZDdUl6c29qQUFBJnN0ZT00NCZzZWM9MTQ2JnBnPTQwMyZsb2M9NjIzJmNtcD05MjcwJmFkPTExODY1NSZjb209NDEmaHVzPTEmYWRrd2Q9JmFka3dkYWN1cj0wJnRzeXM9MCZ0cHJnPTAmdG5rd2Q9MCZ0a3dkPTAmdHNpdGU9MTUxNTkmdG1ldGE9MCZjdHY9ODA5OTAmY2xrPTE0OTY4JmR1cj0xNSZyZXNfb3JkPTE",
                "click": "http://admdev.smartmediarep.com/NetInsight/event/clk/XDrTNMvTohOGPLsFTcV8IUHELC78DGDX6zWKcASrlabaBBd2ZXI9My4wJm1kYT1TTVJfTUVNQkVSUyZzaXRlPVNCU19MSVZFX01JRF9DVCZyZXF0bT0yMDIzMDUyNjE1MDgzNiZ0aWQ9MWZhMDdhZmE2ODM0YWRlYzRkYTc4NDc3NWFmNjdjZTkmdXVpZD1kZWI1YjFmNGM3YTg2MTkyN2MxZmE1YWZkMjExNzBlMiZpcD0yMTguNTUuOTIuOTgmZ25kPTMmYWdlPTk5JnBsYXQ9UENXRUImcHR5cGU9SFRNTDUmb3M9TWFjaW50b3NoJmR2Y21kPSZ0ZWxjbz0mY3A9QzEmY2hubD1TMDEmY2F0ZT0wMSZzZWN0PTkwJmNscGlkPSZwcm9pZD1TMDFfVjIwMDAwMTAyMDEmY3RudW09JnRndG50PSZvbmFpcj1ZJnBheT1OJnZ0eXBlPU8mYmRhdGU9JnB0aW1lPTcyMDAmc3RpbWU9MTQwMCZldGltZT0xNjAwJmZkdXJsPWh0dHAlM0EvL2xvY2FsaG9zdCUzQTgwODAvJmFkbGluaz1jcGlkJTNEQzElMjZjaGFubmVsaWQlM0RTMDElMjZjYXRlZ29yeSUzRDAxJTI2c2VjdGlvbiUzRDkwJTI2cHJvZ3JhbWlkJTNEUzAxX1YyMDAwMDEwMjAxJTI2Y2xpcGlkJTNEJTI2Y29udGVudG51bWJlciUzRCUyNnRhcmdldG5hdGlvbiUzRCUyNmlzb25haXIlM0RZJTI2aXNwYXklM0ROJTI2dm9kdHlwZSUzRE8lMjZicm9hZGRhdGUlM0QlMjZwbGF5dGltZSUzRDcyMDAlMjZzdGFydHRpbWUlM0QxNDAwJTI2ZW5kdGltZSUzRDE2MDAmYWR0eXBlPVBSRSZrd2Q9U0VSVklDRSUzRE9OQUlSJmhvbGQ9JnNsb3RzPTEwJnNlY3Rpb250eXBlPSZvdmVyZmxvdz0mYXQ9dmlkZW8mdHRpbWU9MjAyMzA1MjYxNTA4NTkmdHRpbWUyPTIwMjMwNTI2MTUwODU5NTQ4NzkmcnRya190bT0xNjg1MDgxMzM5JnVucT1GWGdQNUlLclN0YVZDdUl6c29qQUFBJnN0ZT00NCZzZWM9MTQ2JnBnPTQwMyZsb2M9NjIzJmNtcD05MjcwJmFkPTExODY1NSZjb209NDEmaHVzPTEmYWRrd2Q9JmFka3dkYWN1cj0wJnRzeXM9MCZ0cHJnPTAmdG5rd2Q9MCZ0a3dkPTAmdHNpdGU9MTUxNTkmdG1ldGE9MCZjdHY9ODA5OTAmY2xrPTE0OTY4JmR1cj0xNSZyZXNfb3JkPTEmY3VybD1odHRwJTNBLy8lMjB3d3cueW91dHViZS5jb20vY2hhbm5lbC9VQ3hqRVJQUFZ5SGJpbmE3M2c4R3JuLWc",
                "fifteenSeconds": "http://admdev.smartmediarep.com/NetInsight/event/fifteen/1LiVNJ3ow53PNfCpkg-2a_XPvrfPUo6zpFGMR8yd92jaA9V2ZXI9My4wJm1kYT1TTVJfTUVNQkVSUyZzaXRlPVNCU19MSVZFX01JRF9DVCZyZXF0bT0yMDIzMDUyNjE1MDgzNiZ0aWQ9MWZhMDdhZmE2ODM0YWRlYzRkYTc4NDc3NWFmNjdjZTkmdXVpZD1kZWI1YjFmNGM3YTg2MTkyN2MxZmE1YWZkMjExNzBlMiZpcD0yMTguNTUuOTIuOTgmZ25kPTMmYWdlPTk5JnBsYXQ9UENXRUImcHR5cGU9SFRNTDUmb3M9TWFjaW50b3NoJmR2Y21kPSZ0ZWxjbz0mY3A9QzEmY2hubD1TMDEmY2F0ZT0wMSZzZWN0PTkwJmNscGlkPSZwcm9pZD1TMDFfVjIwMDAwMTAyMDEmY3RudW09JnRndG50PSZvbmFpcj1ZJnBheT1OJnZ0eXBlPU8mYmRhdGU9JnB0aW1lPTcyMDAmc3RpbWU9MTQwMCZldGltZT0xNjAwJmZkdXJsPWh0dHAlM0EvL2xvY2FsaG9zdCUzQTgwODAvJmFkbGluaz1jcGlkJTNEQzElMjZjaGFubmVsaWQlM0RTMDElMjZjYXRlZ29yeSUzRDAxJTI2c2VjdGlvbiUzRDkwJTI2cHJvZ3JhbWlkJTNEUzAxX1YyMDAwMDEwMjAxJTI2Y2xpcGlkJTNEJTI2Y29udGVudG51bWJlciUzRCUyNnRhcmdldG5hdGlvbiUzRCUyNmlzb25haXIlM0RZJTI2aXNwYXklM0ROJTI2dm9kdHlwZSUzRE8lMjZicm9hZGRhdGUlM0QlMjZwbGF5dGltZSUzRDcyMDAlMjZzdGFydHRpbWUlM0QxNDAwJTI2ZW5kdGltZSUzRDE2MDAmYWR0eXBlPVBSRSZrd2Q9U0VSVklDRSUzRE9OQUlSJmhvbGQ9JnNsb3RzPTEwJnNlY3Rpb250eXBlPSZvdmVyZmxvdz0mYXQ9dmlkZW8mdHRpbWU9MjAyMzA1MjYxNTA4NTkmdHRpbWUyPTIwMjMwNTI2MTUwODU5NTQ4NzkmcnRya190bT0xNjg1MDgxMzM5JnVucT1GWGdQNUlLclN0YVZDdUl6c29qQUFBJnN0ZT00NCZzZWM9MTQ2JnBnPTQwMyZsb2M9NjIzJmNtcD05MjcwJmFkPTExODY1NSZjb209NDEmaHVzPTEmYWRrd2Q9JmFka3dkYWN1cj0wJnRzeXM9MCZ0cHJnPTAmdG5rd2Q9MCZ0a3dkPTAmdHNpdGU9MTUxNTkmdG1ldGE9MCZjdHY9ODA5OTAmY2xrPTE0OTY4JmR1cj0xNSZyZXNfb3JkPTE",
                "thirtySeconds": "http://admdev.smartmediarep.com/NetInsight/event/thirty/1LiVNJ3ow53PNfCpkg-2a_XPvrfPUo6zpFGMR8yd92jaA9V2ZXI9My4wJm1kYT1TTVJfTUVNQkVSUyZzaXRlPVNCU19MSVZFX01JRF9DVCZyZXF0bT0yMDIzMDUyNjE1MDgzNiZ0aWQ9MWZhMDdhZmE2ODM0YWRlYzRkYTc4NDc3NWFmNjdjZTkmdXVpZD1kZWI1YjFmNGM3YTg2MTkyN2MxZmE1YWZkMjExNzBlMiZpcD0yMTguNTUuOTIuOTgmZ25kPTMmYWdlPTk5JnBsYXQ9UENXRUImcHR5cGU9SFRNTDUmb3M9TWFjaW50b3NoJmR2Y21kPSZ0ZWxjbz0mY3A9QzEmY2hubD1TMDEmY2F0ZT0wMSZzZWN0PTkwJmNscGlkPSZwcm9pZD1TMDFfVjIwMDAwMTAyMDEmY3RudW09JnRndG50PSZvbmFpcj1ZJnBheT1OJnZ0eXBlPU8mYmRhdGU9JnB0aW1lPTcyMDAmc3RpbWU9MTQwMCZldGltZT0xNjAwJmZkdXJsPWh0dHAlM0EvL2xvY2FsaG9zdCUzQTgwODAvJmFkbGluaz1jcGlkJTNEQzElMjZjaGFubmVsaWQlM0RTMDElMjZjYXRlZ29yeSUzRDAxJTI2c2VjdGlvbiUzRDkwJTI2cHJvZ3JhbWlkJTNEUzAxX1YyMDAwMDEwMjAxJTI2Y2xpcGlkJTNEJTI2Y29udGVudG51bWJlciUzRCUyNnRhcmdldG5hdGlvbiUzRCUyNmlzb25haXIlM0RZJTI2aXNwYXklM0ROJTI2dm9kdHlwZSUzRE8lMjZicm9hZGRhdGUlM0QlMjZwbGF5dGltZSUzRDcyMDAlMjZzdGFydHRpbWUlM0QxNDAwJTI2ZW5kdGltZSUzRDE2MDAmYWR0eXBlPVBSRSZrd2Q9U0VSVklDRSUzRE9OQUlSJmhvbGQ9JnNsb3RzPTEwJnNlY3Rpb250eXBlPSZvdmVyZmxvdz0mYXQ9dmlkZW8mdHRpbWU9MjAyMzA1MjYxNTA4NTkmdHRpbWUyPTIwMjMwNTI2MTUwODU5NTQ4NzkmcnRya190bT0xNjg1MDgxMzM5JnVucT1GWGdQNUlLclN0YVZDdUl6c29qQUFBJnN0ZT00NCZzZWM9MTQ2JnBnPTQwMyZsb2M9NjIzJmNtcD05MjcwJmFkPTExODY1NSZjb209NDEmaHVzPTEmYWRrd2Q9JmFka3dkYWN1cj0wJnRzeXM9MCZ0cHJnPTAmdG5rd2Q9MCZ0a3dkPTAmdHNpdGU9MTUxNTkmdG1ldGE9MCZjdHY9ODA5OTAmY2xrPTE0OTY4JmR1cj0xNSZyZXNfb3JkPTE"
              },
              "skip": {
                "offset": ""
              }
            })
          }

          return {
            "response": "video",
            "ads": temp
          }
        } else {
          // FIXME: CLIP/VOD TEST 광고 하드코딩 (30s)
          return {
            "response": "video", "ads": [{
              "content_url": "https://smrmembers-smr.smartmediarep.com/smc/smrmembers/single/eng/0/smr/contents/video/2023/03/07/db1068a31c7e7c2c887e6d10c9c1f010_t34.mp4",
              "duration": 30,
              "house": 0,
              "tracking_url": {
                "start": "https://adtracking.smartmediarep.com/NetInsight/i/start?qs=jNTYk5TbmkXOjISOpQDbwITNmAWU0lTZ5IyR9lnc9ADeyRDMlRGamFTM5NWc5lXJ0wTJGtHLhM2ZvJTJoBzPkZzPk4zcjBmNlMDMjk2ZzITaj4iZ1UHdh1TJ3ZjO1AjMw10bf1DW25jU0lnJyRSMhIGZvZEd2ZmQzxjQhlEM2gXME0mckJSXwEzN24XLlZzbzMHblMTUkUSUmdWTzUTculndkR1d0VTOp0TJkVjOmEDdkAGdoASamQWd0YTcz0DdxVGd90zZxMTNw0DMlMXJmMXYv0SJxcjOtNXPwR9dTFxdnZlMGEvMSQpMDUxbjRzeixycjIuJCEtMGM9M0ISR0UFZUFFZlFmbmJxNGU9QjNyJzBzeDRxbzZ3MT4mR2U0eTBTclZmMGkhRDUQc1FFbiN0a3IuJDtLRiUibGl0dT5ydDdzcDRxNCU3OX0lJlVxemVTbHJwZz5QakZnM2RhOlE9cSAzMD9DMxdjYHRTMDETamlfcjZGMDN0N9Q2QTQTNHIjX2g9bmNmJW9lbvJmMnJzcG19J5A2cXUTbiE1dmV1cWI2JjN1RwUlPD9TZD50YxZ0ajcDOTAyMxQ4MWVmbTIhakZmdGETQUZmJxllbT1TamRkbwFsaWgyUH1zbjNBdTJ2JTZGL9NvM3JjcXAub4JzMmJScmcyJoJGMmNXJmU9d9h3MmJSdGguJhkjMGdGYTAEP3FlMjMDNGAuNmZsMWUWM0ITYrZlZjE2NXllOhcydSd3ODZxZyQ2N2VTJGcvMmIzNDZHQTE2c5RvO3AzPWMkawVEMzQnb1I0Zwk2MzEmOD0lM9IjZGdjOWllZ1BTdDZDMjZwcmZ0MTIGaTUlbrUjb212ZWR0d9FtcmACPT0EY0ZycnM3MmZlMk0hPGFTZixzUm1SbTRHZXZvQpdpMi1GdFElc9MpN31jZSMzcwclYjUzM2N0MmAlMTAGMyUydlVyO2MGUGk0T91zOD1TUjFwZ3ZwNTcTMGchMmIpMW03c0A5PyNlZjRTJGFyOwUpYWRmc0EwV092NWRjMG1tQxZzZDNTP3RuawU9JSRTSWQ0cmR1NmAXPDczdp9yN2dGcVY4Ju0QNDNDZj90d0MyNnYnMDMhdhNyPm0mYTl3YlI4bDIFNDBzd112JmAWNm00MkU1ZyNTd2dkPmdvJzZHM3Apd559PCkGNGNtM9cyZDRFJDZwcNNzOTADPy00YmRtdTR3YjRyZktzYDZVMzA3dFZwRDglMCUhbJZ2ZWI0NylkPlF9dzQ0OW52PT1tZjMEO2IyYSxzJXAFNHQtcmcmM20HYHUoJpM0bTRWc&tqs=yWcdNxEbXU2qzUHxTE0mrzMtE53PgnNetZPkRETcJVd",
                "firstQuartile": "https://adtracking.smartmediarep.com/NetInsight/i/first?qs=jNTYk5TbmkXOjISOpQDbwITNmAWU0lTZ5IyR9lnc9ADeyRDMlRGamFTM5NWc5lXJ0wTJGtHLhM2ZvJTJoBzPkZzPk4zcjBmNlMDMjk2ZzITaj4iZ1UHdh1TJ3ZjO1AjMw10bf1DW25jU0lnJyRSMhIGZvZEd2ZmQzxjQhlEM2gXME0mckJSXwEzN24XLlZzbzMHblMTUkUSUmdWTzUTculndkR1d0VTOp0TJkVjOmEDdkAGdoASamQWd0YTcz0DdxVGd90zZxMTNw0DMlMXJmMXYv0SJxcjOtNXPwR9dTFxdnZlMGEvMSQpMDUxbjRzeixycjIuJCEtMGM9M0ISR0UFZUFFZlFmbmJxNGU9QjNyJzBzeDRxbzZ3MT4mR2U0eTBTclZmMGkhRDUQc1FFbiN0a3IuJDtLRiUibGl0dT5ydDdzcDRxNCU3OX0lJlVxemVTbHJwZz5QakZnM2RhOlE9cSAzMD9DMxdjYHRTMDETamlfcjZGMDN0N9Q2QTQTNHIjX2g9bmNmJW9lbvJmMnJzcG19J5A2cXUTbiE1dmV1cWI2JjN1RwUlPD9TZD50YxZ0ajcDOTAyMxQ4MWVmbTIhakZmdGETQUZmJxllbT1TamRkbwFsaWgyUH1zbjNBdTJ2JTZGL9NvM3JjcXAub4JzMmJScmcyJoJGMmNXJmU9d9h3MmJSdGguJhkjMGdGYTAEP3FlMjMDNGAuNmZsMWUWM0ITYrZlZjE2NXllOhcydSd3ODZxZyQ2N2VTJGcvMmIzNDZHQTE2c5RvO3AzPWMkawVEMzQnb1I0Zwk2MzEmOD0lM9IjZGdjOWllZ1BTdDZDMjZwcmZ0MTIGaTUlbrUjb212ZWR0d9FtcmACPT0EY0ZycnM3MmZlMk0hPGFTZixzUm1SbTRHZXZvQpdpMi1GdFElc9MpN31jZSMzcwclYjUzM2N0MmAlMTAGMyUydlVyO2MGUGk0T91zOD1TUjFwZ3ZwNTcTMGchMmIpMW03c0A5PyNlZjRTJGFyOwUpYWRmc0EwV092NWRjMG1tQxZzZDNTP3RuawU9JSRTSWQ0cmR1NmAXPDczdp9yN2dGcVY4Ju0QNDNDZj90d0MyNnYnMDMhdhNyPm0mYTl3YlI4bDIFNDBzd112JmAWNm00MkU1ZyNTd2dkPmdvJzZHM3Apd559PCkGNGNtM9cyZDRFJDZwcNNzOTADPy00YmRtdTR3YjRyZktzYDZVMzA3dFZwRDglMCUhbJZ2ZWI0NylkPlF9dzQ0OW52PT1tZjMEO2IyYSxzJXAFNHQtcmcmM20HYHUoJpM0bTRWc&tqs=yWcdNxEbXU2qzUHxTE0mrzMtE53PgnNetZPkRETcJVd",
                "mid_point": "https://adtracking.smartmediarep.com/NetInsight/i/mid?qs=jNTYk5TbmkXOjISOpQDbwITNmAWU0lTZ5IyR9lnc9ADeyRDMlRGamFTM5NWc5lXJ0wTJGtHLhM2ZvJTJoBzPkZzPk4zcjBmNlMDMjk2ZzITaj4iZ1UHdh1TJ3ZjO1AjMw10bf1DW25jU0lnJyRSMhIGZvZEd2ZmQzxjQhlEM2gXME0mckJSXwEzN24XLlZzbzMHblMTUkUSUmdWTzUTculndkR1d0VTOp0TJkVjOmEDdkAGdoASamQWd0YTcz0DdxVGd90zZxMTNw0DMlMXJmMXYv0SJxcjOtNXPwR9dTFxdnZlMGEvMSQpMDUxbjRzeixycjIuJCEtMGM9M0ISR0UFZUFFZlFmbmJxNGU9QjNyJzBzeDRxbzZ3MT4mR2U0eTBTclZmMGkhRDUQc1FFbiN0a3IuJDtLRiUibGl0dT5ydDdzcDRxNCU3OX0lJlVxemVTbHJwZz5QakZnM2RhOlE9cSAzMD9DMxdjYHRTMDETamlfcjZGMDN0N9Q2QTQTNHIjX2g9bmNmJW9lbvJmMnJzcG19J5A2cXUTbiE1dmV1cWI2JjN1RwUlPD9TZD50YxZ0ajcDOTAyMxQ4MWVmbTIhakZmdGETQUZmJxllbT1TamRkbwFsaWgyUH1zbjNBdTJ2JTZGL9NvM3JjcXAub4JzMmJScmcyJoJGMmNXJmU9d9h3MmJSdGguJhkjMGdGYTAEP3FlMjMDNGAuNmZsMWUWM0ITYrZlZjE2NXllOhcydSd3ODZxZyQ2N2VTJGcvMmIzNDZHQTE2c5RvO3AzPWMkawVEMzQnb1I0Zwk2MzEmOD0lM9IjZGdjOWllZ1BTdDZDMjZwcmZ0MTIGaTUlbrUjb212ZWR0d9FtcmACPT0EY0ZycnM3MmZlMk0hPGFTZixzUm1SbTRHZXZvQpdpMi1GdFElc9MpN31jZSMzcwclYjUzM2N0MmAlMTAGMyUydlVyO2MGUGk0T91zOD1TUjFwZ3ZwNTcTMGchMmIpMW03c0A5PyNlZjRTJGFyOwUpYWRmc0EwV092NWRjMG1tQxZzZDNTP3RuawU9JSRTSWQ0cmR1NmAXPDczdp9yN2dGcVY4Ju0QNDNDZj90d0MyNnYnMDMhdhNyPm0mYTl3YlI4bDIFNDBzd112JmAWNm00MkU1ZyNTd2dkPmdvJzZHM3Apd559PCkGNGNtM9cyZDRFJDZwcNNzOTADPy00YmRtdTR3YjRyZktzYDZVMzA3dFZwRDglMCUhbJZ2ZWI0NylkPlF9dzQ0OW52PT1tZjMEO2IyYSxzJXAFNHQtcmcmM20HYHUoJpM0bTRWc&tqs=yWcdNxEbXU2qzUHxTE0mrzMtE53PgnNetZPkRETcJVd",
                "thirdQuartile": "https://adtracking.smartmediarep.com/NetInsight/i/third?qs=jNTYk5TbmkXOjISOpQDbwITNmAWU0lTZ5IyR9lnc9ADeyRDMlRGamFTM5NWc5lXJ0wTJGtHLhM2ZvJTJoBzPkZzPk4zcjBmNlMDMjk2ZzITaj4iZ1UHdh1TJ3ZjO1AjMw10bf1DW25jU0lnJyRSMhIGZvZEd2ZmQzxjQhlEM2gXME0mckJSXwEzN24XLlZzbzMHblMTUkUSUmdWTzUTculndkR1d0VTOp0TJkVjOmEDdkAGdoASamQWd0YTcz0DdxVGd90zZxMTNw0DMlMXJmMXYv0SJxcjOtNXPwR9dTFxdnZlMGEvMSQpMDUxbjRzeixycjIuJCEtMGM9M0ISR0UFZUFFZlFmbmJxNGU9QjNyJzBzeDRxbzZ3MT4mR2U0eTBTclZmMGkhRDUQc1FFbiN0a3IuJDtLRiUibGl0dT5ydDdzcDRxNCU3OX0lJlVxemVTbHJwZz5QakZnM2RhOlE9cSAzMD9DMxdjYHRTMDETamlfcjZGMDN0N9Q2QTQTNHIjX2g9bmNmJW9lbvJmMnJzcG19J5A2cXUTbiE1dmV1cWI2JjN1RwUlPD9TZD50YxZ0ajcDOTAyMxQ4MWVmbTIhakZmdGETQUZmJxllbT1TamRkbwFsaWgyUH1zbjNBdTJ2JTZGL9NvM3JjcXAub4JzMmJScmcyJoJGMmNXJmU9d9h3MmJSdGguJhkjMGdGYTAEP3FlMjMDNGAuNmZsMWUWM0ITYrZlZjE2NXllOhcydSd3ODZxZyQ2N2VTJGcvMmIzNDZHQTE2c5RvO3AzPWMkawVEMzQnb1I0Zwk2MzEmOD0lM9IjZGdjOWllZ1BTdDZDMjZwcmZ0MTIGaTUlbrUjb212ZWR0d9FtcmACPT0EY0ZycnM3MmZlMk0hPGFTZixzUm1SbTRHZXZvQpdpMi1GdFElc9MpN31jZSMzcwclYjUzM2N0MmAlMTAGMyUydlVyO2MGUGk0T91zOD1TUjFwZ3ZwNTcTMGchMmIpMW03c0A5PyNlZjRTJGFyOwUpYWRmc0EwV092NWRjMG1tQxZzZDNTP3RuawU9JSRTSWQ0cmR1NmAXPDczdp9yN2dGcVY4Ju0QNDNDZj90d0MyNnYnMDMhdhNyPm0mYTl3YlI4bDIFNDBzd112JmAWNm00MkU1ZyNTd2dkPmdvJzZHM3Apd559PCkGNGNtM9cyZDRFJDZwcNNzOTADPy00YmRtdTR3YjRyZktzYDZVMzA3dFZwRDglMCUhbJZ2ZWI0NylkPlF9dzQ0OW52PT1tZjMEO2IyYSxzJXAFNHQtcmcmM20HYHUoJpM0bTRWc&tqs=yWcdNxEbXU2qzUHxTE0mrzMtE53PgnNetZPkRETcJVd",
                "complete": "https://adtracking.smartmediarep.com/NetInsight/i/complete?qs=jNTYk5TbmkXOjISOpQDbwITNmAWU0lTZ5IyR9lnc9ADeyRDMlRGamFTM5NWc5lXJ0wTJGtHLhM2ZvJTJoBzPkZzPk4zcjBmNlMDMjk2ZzITaj4iZ1UHdh1TJ3ZjO1AjMw10bf1DW25jU0lnJyRSMhIGZvZEd2ZmQzxjQhlEM2gXME0mckJSXwEzN24XLlZzbzMHblMTUkUSUmdWTzUTculndkR1d0VTOp0TJkVjOmEDdkAGdoASamQWd0YTcz0DdxVGd90zZxMTNw0DMlMXJmMXYv0SJxcjOtNXPwR9dTFxdnZlMGEvMSQpMDUxbjRzeixycjIuJCEtMGM9M0ISR0UFZUFFZlFmbmJxNGU9QjNyJzBzeDRxbzZ3MT4mR2U0eTBTclZmMGkhRDUQc1FFbiN0a3IuJDtLRiUibGl0dT5ydDdzcDRxNCU3OX0lJlVxemVTbHJwZz5QakZnM2RhOlE9cSAzMD9DMxdjYHRTMDETamlfcjZGMDN0N9Q2QTQTNHIjX2g9bmNmJW9lbvJmMnJzcG19J5A2cXUTbiE1dmV1cWI2JjN1RwUlPD9TZD50YxZ0ajcDOTAyMxQ4MWVmbTIhakZmdGETQUZmJxllbT1TamRkbwFsaWgyUH1zbjNBdTJ2JTZGL9NvM3JjcXAub4JzMmJScmcyJoJGMmNXJmU9d9h3MmJSdGguJhkjMGdGYTAEP3FlMjMDNGAuNmZsMWUWM0ITYrZlZjE2NXllOhcydSd3ODZxZyQ2N2VTJGcvMmIzNDZHQTE2c5RvO3AzPWMkawVEMzQnb1I0Zwk2MzEmOD0lM9IjZGdjOWllZ1BTdDZDMjZwcmZ0MTIGaTUlbrUjb212ZWR0d9FtcmACPT0EY0ZycnM3MmZlMk0hPGFTZixzUm1SbTRHZXZvQpdpMi1GdFElc9MpN31jZSMzcwclYjUzM2N0MmAlMTAGMyUydlVyO2MGUGk0T91zOD1TUjFwZ3ZwNTcTMGchMmIpMW03c0A5PyNlZjRTJGFyOwUpYWRmc0EwV092NWRjMG1tQxZzZDNTP3RuawU9JSRTSWQ0cmR1NmAXPDczdp9yN2dGcVY4Ju0QNDNDZj90d0MyNnYnMDMhdhNyPm0mYTl3YlI4bDIFNDBzd112JmAWNm00MkU1ZyNTd2dkPmdvJzZHM3Apd559PCkGNGNtM9cyZDRFJDZwcNNzOTADPy00YmRtdTR3YjRyZktzYDZVMzA3dFZwRDglMCUhbJZ2ZWI0NylkPlF9dzQ0OW52PT1tZjMEO2IyYSxzJXAFNHQtcmcmM20HYHUoJpM0bTRWc&tqs=yWcdNxEbXU2qzUHxTE0mrzMtE53PgnNetZPkRETcJVd",
                "skip": "https://adtracking.smartmediarep.com/NetInsight/i/skip?qs=jNTYk5TbmkXOjISOpQDbwITNmAWU0lTZ5IyR9lnc9ADeyRDMlRGamFTM5NWc5lXJ0wTJGtHLhM2ZvJTJoBzPkZzPk4zcjBmNlMDMjk2ZzITaj4iZ1UHdh1TJ3ZjO1AjMw10bf1DW25jU0lnJyRSMhIGZvZEd2ZmQzxjQhlEM2gXME0mckJSXwEzN24XLlZzbzMHblMTUkUSUmdWTzUTculndkR1d0VTOp0TJkVjOmEDdkAGdoASamQWd0YTcz0DdxVGd90zZxMTNw0DMlMXJmMXYv0SJxcjOtNXPwR9dTFxdnZlMGEvMSQpMDUxbjRzeixycjIuJCEtMGM9M0ISR0UFZUFFZlFmbmJxNGU9QjNyJzBzeDRxbzZ3MT4mR2U0eTBTclZmMGkhRDUQc1FFbiN0a3IuJDtLRiUibGl0dT5ydDdzcDRxNCU3OX0lJlVxemVTbHJwZz5QakZnM2RhOlE9cSAzMD9DMxdjYHRTMDETamlfcjZGMDN0N9Q2QTQTNHIjX2g9bmNmJW9lbvJmMnJzcG19J5A2cXUTbiE1dmV1cWI2JjN1RwUlPD9TZD50YxZ0ajcDOTAyMxQ4MWVmbTIhakZmdGETQUZmJxllbT1TamRkbwFsaWgyUH1zbjNBdTJ2JTZGL9NvM3JjcXAub4JzMmJScmcyJoJGMmNXJmU9d9h3MmJSdGguJhkjMGdGYTAEP3FlMjMDNGAuNmZsMWUWM0ITYrZlZjE2NXllOhcydSd3ODZxZyQ2N2VTJGcvMmIzNDZHQTE2c5RvO3AzPWMkawVEMzQnb1I0Zwk2MzEmOD0lM9IjZGdjOWllZ1BTdDZDMjZwcmZ0MTIGaTUlbrUjb212ZWR0d9FtcmACPT0EY0ZycnM3MmZlMk0hPGFTZixzUm1SbTRHZXZvQpdpMi1GdFElc9MpN31jZSMzcwclYjUzM2N0MmAlMTAGMyUydlVyO2MGUGk0T91zOD1TUjFwZ3ZwNTcTMGchMmIpMW03c0A5PyNlZjRTJGFyOwUpYWRmc0EwV092NWRjMG1tQxZzZDNTP3RuawU9JSRTSWQ0cmR1NmAXPDczdp9yN2dGcVY4Ju0QNDNDZj90d0MyNnYnMDMhdhNyPm0mYTl3YlI4bDIFNDBzd112JmAWNm00MkU1ZyNTd2dkPmdvJzZHM3Apd559PCkGNGNtM9cyZDRFJDZwcNNzOTADPy00YmRtdTR3YjRyZktzYDZVMzA3dFZwRDglMCUhbJZ2ZWI0NylkPlF9dzQ0OW52PT1tZjMEO2IyYSxzJXAFNHQtcmcmM20HYHUoJpM0bTRWc&tqs=yWcdNxEbXU2qzUHxTE0mrzMtE53PgnNetZPkRETcJVd",
                "click": "https://ad.smartmediarep.com/NetInsight/i/click?qs=jNTYk5TbmkXOjISOpQDbwITNmAWU0lTZ5IyR9lnc9ADeyRDMlRGamFTM5NWc5lXJ0wTJGtHLhM2ZvJTJoBzPkZzPk4zcjBmNlMDMjk2ZzITaj4iZ1UHdh1TJ3ZjO1AjMw10bf1DW25jU0lnJyRSMhIGZvZEd2ZmQzxjQhlEM2gXME0mckJSXwEzN24XLlZzbzMHblMTUkUSUmdWTzUTculndkR1d0VTOp0TJkVjOmEDdkAGdoASamQWd0YTcz0DdxVGd90zZxMTNw0DMlMXJmMXYv0SJxcjOtNXPwR9dTFxdnZlMGEvMSQpMDUxbjRzeixycjIuJCEtMGM9M0ISR0UFZUFFZlFmbmJxNGU9QjNyJzBzeDRxbzZ3MT4mR2U0eTBTclZmMGkhRDUQc1FFbiN0a3IuJDtLRiUibGl0dT5ydDdzcDRxNCU3OX0lJlVxemVTbHJwZz5QakZnM2RhOlE9cSAzMD9DMxdjYHRTMDETamlfcjZGMDN0N9Q2QTQTNHIjX2g9bmNmJW9lbvJmMnJzcG19J5A2cXUTbiE1dmV1cWI2JjN1RwUlPD9TZD50YxZ0ajcDOTAyMxQ4MWVmbTIhakZmdGETQUZmJxllbT1TamRkbwFsaWgyUH1zbjNBdTJ2JTZGL9NvM3JjcXAub4JzMmJScmcyJoJGMmNXJmU9d9h3MmJSdGguJhkjMGdGYTAEP3FlMjMDNGAuNmZsMWUWM0ITYrZlZjE2NXllOhcydSd3ODZxZyQ2N2VTJGcvMmIzNDZHQTE2c5RvO3AzPWMkawVEMzQnb1I0Zwk2MzEmOD0lM9IjZGdjOWllZ1BTdDZDMjZwcmZ0MTIGaTUlbrUjb212ZWR0d9FtcmACPT0EY0ZycnM3MmZlMk0hPGFTZixzUm1SbTRHZXZvQpdpMi1GdFElc9MpN31jZSMzcwclYjUzM2N0MmAlMTAGMyUydlVyO2MGUGk0T91zOD1TUjFwZ3ZwNTcTMGchMmIpMW03c0A5PyNlZjRTJGFyOwUpYWRmc0EwV092NWRjMG1tQxZzZDNTP3RuawU9JSRTSWQ0cmR1NmAXPDczdp9yN2dGcVY4Ju0QNDNDZj90d0MyNnYnMDMhdhNyPm0mYTl3YlI4bDIFNDBzd112JmAWNm00MkU1ZyNTd2dkPmdvJzZHM3Apd559PCkGNGNtM9cyZDRFJDZwcNNzOTADPy00YmRtdTR3YjRyZktzYDZVMzA3dFZwRDglMCUhbJZ2ZWI0NylkPlF9dzQ0OW52PT1tZjMEO2IyYSxzJXAFNHQtcmcmM20HYHUoJpM0bTRWc&tqs=mcUZkPEcrNTElXQETP0xzYHUWPxErbqRVe3xnet5tdmZ2MTETO4g2N1ACPzRHMkJyd",
                "fifteenSeconds": "https://adtracking.smartmediarep.com/NetInsight/i/fifteen?qs=jNTYk5TbmkXOjISOpQDbwITNmAWU0lTZ5IyR9lnc9ADeyRDMlRGamFTM5NWc5lXJ0wTJGtHLhM2ZvJTJoBzPkZzPk4zcjBmNlMDMjk2ZzITaj4iZ1UHdh1TJ3ZjO1AjMw10bf1DW25jU0lnJyRSMhIGZvZEd2ZmQzxjQhlEM2gXME0mckJSXwEzN24XLlZzbzMHblMTUkUSUmdWTzUTculndkR1d0VTOp0TJkVjOmEDdkAGdoASamQWd0YTcz0DdxVGd90zZxMTNw0DMlMXJmMXYv0SJxcjOtNXPwR9dTFxdnZlMGEvMSQpMDUxbjRzeixycjIuJCEtMGM9M0ISR0UFZUFFZlFmbmJxNGU9QjNyJzBzeDRxbzZ3MT4mR2U0eTBTclZmMGkhRDUQc1FFbiN0a3IuJDtLRiUibGl0dT5ydDdzcDRxNCU3OX0lJlVxemVTbHJwZz5QakZnM2RhOlE9cSAzMD9DMxdjYHRTMDETamlfcjZGMDN0N9Q2QTQTNHIjX2g9bmNmJW9lbvJmMnJzcG19J5A2cXUTbiE1dmV1cWI2JjN1RwUlPD9TZD50YxZ0ajcDOTAyMxQ4MWVmbTIhakZmdGETQUZmJxllbT1TamRkbwFsaWgyUH1zbjNBdTJ2JTZGL9NvM3JjcXAub4JzMmJScmcyJoJGMmNXJmU9d9h3MmJSdGguJhkjMGdGYTAEP3FlMjMDNGAuNmZsMWUWM0ITYrZlZjE2NXllOhcydSd3ODZxZyQ2N2VTJGcvMmIzNDZHQTE2c5RvO3AzPWMkawVEMzQnb1I0Zwk2MzEmOD0lM9IjZGdjOWllZ1BTdDZDMjZwcmZ0MTIGaTUlbrUjb212ZWR0d9FtcmACPT0EY0ZycnM3MmZlMk0hPGFTZixzUm1SbTRHZXZvQpdpMi1GdFElc9MpN31jZSMzcwclYjUzM2N0MmAlMTAGMyUydlVyO2MGUGk0T91zOD1TUjFwZ3ZwNTcTMGchMmIpMW03c0A5PyNlZjRTJGFyOwUpYWRmc0EwV092NWRjMG1tQxZzZDNTP3RuawU9JSRTSWQ0cmR1NmAXPDczdp9yN2dGcVY4Ju0QNDNDZj90d0MyNnYnMDMhdhNyPm0mYTl3YlI4bDIFNDBzd112JmAWNm00MkU1ZyNTd2dkPmdvJzZHM3Apd559PCkGNGNtM9cyZDRFJDZwcNNzOTADPy00YmRtdTR3YjRyZktzYDZVMzA3dFZwRDglMCUhbJZ2ZWI0NylkPlF9dzQ0OW52PT1tZjMEO2IyYSxzJXAFNHQtcmcmM20HYHUoJpM0bTRWc&tqs=yWcdNxEbXU2qzUHxTE0mrzMtE53PgnNetZPkRETcJVd",
                "thirtySeconds": "https://adtracking.smartmediarep.com/NetInsight/i/thirty?qs=jNTYk5TbmkXOjISOpQDbwITNmAWU0lTZ5IyR9lnc9ADeyRDMlRGamFTM5NWc5lXJ0wTJGtHLhM2ZvJTJoBzPkZzPk4zcjBmNlMDMjk2ZzITaj4iZ1UHdh1TJ3ZjO1AjMw10bf1DW25jU0lnJyRSMhIGZvZEd2ZmQzxjQhlEM2gXME0mckJSXwEzN24XLlZzbzMHblMTUkUSUmdWTzUTculndkR1d0VTOp0TJkVjOmEDdkAGdoASamQWd0YTcz0DdxVGd90zZxMTNw0DMlMXJmMXYv0SJxcjOtNXPwR9dTFxdnZlMGEvMSQpMDUxbjRzeixycjIuJCEtMGM9M0ISR0UFZUFFZlFmbmJxNGU9QjNyJzBzeDRxbzZ3MT4mR2U0eTBTclZmMGkhRDUQc1FFbiN0a3IuJDtLRiUibGl0dT5ydDdzcDRxNCU3OX0lJlVxemVTbHJwZz5QakZnM2RhOlE9cSAzMD9DMxdjYHRTMDETamlfcjZGMDN0N9Q2QTQTNHIjX2g9bmNmJW9lbvJmMnJzcG19J5A2cXUTbiE1dmV1cWI2JjN1RwUlPD9TZD50YxZ0ajcDOTAyMxQ4MWVmbTIhakZmdGETQUZmJxllbT1TamRkbwFsaWgyUH1zbjNBdTJ2JTZGL9NvM3JjcXAub4JzMmJScmcyJoJGMmNXJmU9d9h3MmJSdGguJhkjMGdGYTAEP3FlMjMDNGAuNmZsMWUWM0ITYrZlZjE2NXllOhcydSd3ODZxZyQ2N2VTJGcvMmIzNDZHQTE2c5RvO3AzPWMkawVEMzQnb1I0Zwk2MzEmOD0lM9IjZGdjOWllZ1BTdDZDMjZwcmZ0MTIGaTUlbrUjb212ZWR0d9FtcmACPT0EY0ZycnM3MmZlMk0hPGFTZixzUm1SbTRHZXZvQpdpMi1GdFElc9MpN31jZSMzcwclYjUzM2N0MmAlMTAGMyUydlVyO2MGUGk0T91zOD1TUjFwZ3ZwNTcTMGchMmIpMW03c0A5PyNlZjRTJGFyOwUpYWRmc0EwV092NWRjMG1tQxZzZDNTP3RuawU9JSRTSWQ0cmR1NmAXPDczdp9yN2dGcVY4Ju0QNDNDZj90d0MyNnYnMDMhdhNyPm0mYTl3YlI4bDIFNDBzd112JmAWNm00MkU1ZyNTd2dkPmdvJzZHM3Apd559PCkGNGNtM9cyZDRFJDZwcNNzOTADPy00YmRtdTR3YjRyZktzYDZVMzA3dFZwRDglMCUhbJZ2ZWI0NylkPlF9dzQ0OW52PT1tZjMEO2IyYSxzJXAFNHQtcmcmM20HYHUoJpM0bTRWc&tqs=yWcdNxEbXU2qzUHxTE0mrzMtE53PgnNetZPkRETcJVd"
              },
              "skip": {
                "offset": "1"
              },
              "remind_banner": {
                "usable": 1,
                "banner_url": "https://dpklc6li6p47n.cloudfront.net/2023/03/07/141/40051c1c126aac42133d7c34af45cdf2.jpg",
                "imp": "https://adtracking.smartmediarep.com/NetInsight/i/reimp?qs=jNTYk5TbmkXOjISOpQDbwITNmAWU0lTZ5IyR9lnc9ADeyRDMlRGamFTM5NWc5lXJ0wTJGtHLhM2ZvJTJoBzPkZzPk4zcjBmNlMDMjk2ZzITaj4iZ1UHdh1TJ3ZjO1AjMw10bf1DW25jU0lnJyRSMhIGZvZEd2ZmQzxjQhlEM2gXME0mckJSXwEzN24XLlZzbzMHblMTUkUSUmdWTzUTculndkR1d0VTOp0TJkVjOmEDdkAGdoASamQWd0YTcz0DdxVGd90zZxMTNw0DMlMXJmMXYv0SJxcjOtNXPwR9dTFxdnZlMGEvMSQpMDUxbjRzeixycjIuJCEtMGM9M0ISR0UFZUFFZlFmbmJxNGU9QjNyJzBzeDRxbzZ3MT4mR2U0eTBTclZmMGkhRDUQc1FFbiN0a3IuJDtLRiUibGl0dT5ydDdzcDRxNCU3OX0lJlVxemVTbHJwZz5QakZnM2RhOlE9cSAzMD9DMxdjYHRTMDETamlfcjZGMDN0N9Q2QTQTNHIjX2g9bmNmJW9lbvJmMnJzcG19J5A2cXUTbiE1dmV1cWI2JjN1RwUlPD9TZD50YxZ0ajcDOTAyMxQ4MWVmbTIhakZmdGETQUZmJxllbT1TamRkbwFsaWgyUH1zbjNBdTJ2JTZGL9NvM3JjcXAub4JzMmJScmcyJoJGMmNXJmU9d9h3MmJSdGguJhkjMGdGYTAEP3FlMjMDNGAuNmZsMWUWM0ITYrZlZjE2NXllOhcydSd3ODZxZyQ2N2VTJGcvMmIzNDZHQTE2c5RvO3AzPWMkawVEMzQnb1I0Zwk2MzEmOD0lM9IjZGdjOWllZ1BTdDZDMjZwcmZ0MTIGaTUlbrUjb212ZWR0d9FtcmACPT0EY0ZycnM3MmZlMk0hPGFTZixzUm1SbTRHZXZvQpdpMi1GdFElc9MpN31jZSMzcwclYjUzM2N0MmAlMTAGMyUydlVyO2MGUGk0T91zOD1TUjFwZ3ZwNTcTMGchMmIpMW03c0A5PyNlZjRTJGFyOwUpYWRmc0EwV092NWRjMG1tQxZzZDNTP3RuawU9JSRTSWQ0cmR1NmAXPDczdp9yN2dGcVY4Ju0QNDNDZj90d0MyNnYnMDMhdhNyPm0mYTl3YlI4bDIFNDBzd112JmAWNm00MkU1ZyNTd2dkPmdvJzZHM3Apd559PCkGNGNtM9cyZDRFJDZwcNNzOTADPy00YmRtdTR3YjRyZktzYDZVMzA3dFZwRDglMCUhbJZ2ZWI0NylkPlF9dzQ0OW52PT1tZjMEO2IyYSxzJXAFNHQtcmcmM20HYHUoJpM0bTRWc&tqs=yWcdNxEbXU2qzUHxTE0mrzMtE53PgnNetZPkRETcJVd",
                "click": "https://ad.smartmediarep.com/NetInsight/i/reclick?qs=jNTYk5TbmkXOjISOpQDbwITNmAWU0lTZ5IyR9lnc9ADeyRDMlRGamFTM5NWc5lXJ0wTJGtHLhM2ZvJTJoBzPkZzPk4zcjBmNlMDMjk2ZzITaj4iZ1UHdh1TJ3ZjO1AjMw10bf1DW25jU0lnJyRSMhIGZvZEd2ZmQzxjQhlEM2gXME0mckJSXwEzN24XLlZzbzMHblMTUkUSUmdWTzUTculndkR1d0VTOp0TJkVjOmEDdkAGdoASamQWd0YTcz0DdxVGd90zZxMTNw0DMlMXJmMXYv0SJxcjOtNXPwR9dTFxdnZlMGEvMSQpMDUxbjRzeixycjIuJCEtMGM9M0ISR0UFZUFFZlFmbmJxNGU9QjNyJzBzeDRxbzZ3MT4mR2U0eTBTclZmMGkhRDUQc1FFbiN0a3IuJDtLRiUibGl0dT5ydDdzcDRxNCU3OX0lJlVxemVTbHJwZz5QakZnM2RhOlE9cSAzMD9DMxdjYHRTMDETamlfcjZGMDN0N9Q2QTQTNHIjX2g9bmNmJW9lbvJmMnJzcG19J5A2cXUTbiE1dmV1cWI2JjN1RwUlPD9TZD50YxZ0ajcDOTAyMxQ4MWVmbTIhakZmdGETQUZmJxllbT1TamRkbwFsaWgyUH1zbjNBdTJ2JTZGL9NvM3JjcXAub4JzMmJScmcyJoJGMmNXJmU9d9h3MmJSdGguJhkjMGdGYTAEP3FlMjMDNGAuNmZsMWUWM0ITYrZlZjE2NXllOhcydSd3ODZxZyQ2N2VTJGcvMmIzNDZHQTE2c5RvO3AzPWMkawVEMzQnb1I0Zwk2MzEmOD0lM9IjZGdjOWllZ1BTdDZDMjZwcmZ0MTIGaTUlbrUjb212ZWR0d9FtcmACPT0EY0ZycnM3MmZlMk0hPGFTZixzUm1SbTRHZXZvQpdpMi1GdFElc9MpN31jZSMzcwclYjUzM2N0MmAlMTAGMyUydlVyO2MGUGk0T91zOD1TUjFwZ3ZwNTcTMGchMmIpMW03c0A5PyNlZjRTJGFyOwUpYWRmc0EwV092NWRjMG1tQxZzZDNTP3RuawU9JSRTSWQ0cmR1NmAXPDczdp9yN2dGcVY4Ju0QNDNDZj90d0MyNnYnMDMhdhNyPm0mYTl3YlI4bDIFNDBzd112JmAWNm00MkU1ZyNTd2dkPmdvJzZHM3Apd559PCkGNGNtM9cyZDRFJDZwcNNzOTADPy00YmRtdTR3YjRyZktzYDZVMzA3dFZwRDglMCUhbJZ2ZWI0NylkPlF9dzQ0OW52PT1tZjMEO2IyYSxzJXAFNHQtcmcmM20HYHUoJpM0bTRWc&tqs=tmVxedEEYNARZdymTE0HznDkWrxq0UP35ZXbctMcmUNTgxTPE2PZrhXzJ02",
                "duration": 4,
                "display_per": 70
              }
            }], "text_ad": []
          }
        }
      } else {
        return null;
      }

    } catch (error) {

    }
  }

  requestAd() {
    try {
      console.log('smr request ad position', this.position);
      this.url = this._data[this.position].link;
      if (this.url) {
        SbsPlayerGlobal.infoType !== 'onair' && this.position !== 'midroll' && this.url.indexOf('enc=jwt') === -1 ? this.url += '?enc=jwt' : this.url;
        // Todo: 테스트 2023-01-18 테스트 광고서버로 하드코딩하여 호출하도록 변경
        this.url = this.position === 'midroll' && SbsPlayerGlobal.infoType === 'onair' ? 'http://admdev.smartmediarep.com/NetInsight/video/smr_live/smr_live/smr_live@smr_live' : 'http://addev.smartmediarep.com/NetInsight/video/smr1/smr1/smr1@smr1?enc=jwt';
        console.log(this.position, this.url);
        fetch(this.url, {
          method: 'post',
          body: this.payload,
          mode: 'cors'
        })
          .then(response => {
            if (response.status === 200) {
              return response.json();
            } else {
              this._event.failed(response.status);
            }
          })
          .then(responseJson => {
            if (responseJson.response !== 'error') {
              this._event.succeeded(responseJson);
            } else {
              this._event.failed(responseJson);
            }
          })
          .catch(() => {
            // if (this.useFakeResponse()) {
            //   this._event.succeeded(this.useFakeResponse());
            // } else {
            //   this._event.errored();
            // }
            this._event.errored();
          });
      } else {
        this._event.passed();
      }


    } catch (error) {
      console.log(error);
      this._event.errored(error);
    }
  }
}

export default SmrAdServer;