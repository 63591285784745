/**
 * 광고 화면 구현
 */
import SbsPlayerLogger from '../../../../utils/logger';

customElements.define('sbs-player-ad-info-more', class extends HTMLElement {
  constructor() {
    super();
  }

  static get observedAttributes() { return []; }

  async attributeChangedCallback(name, oldValue, newValue) {
    try {

    } catch (error) {
      console.log(error);
    }
  }
  async connectedCallback() {
    try {
      this.render();
    } catch (error) {
      console.log(error);
    }
  }

  disconnectedCallback() {
    try {
      if (!document.body.contains(this)) {

      }
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    try {
      this.innerHTML = `<button type="button" class="videoBtn-adMore">광고정보 더보기</button>`;
      this.event();
    } catch (error) {
      console.log(error);
    }
  }
  event() {
    this.querySelector('button').addEventListener('click', () => {
      this.dispatchEvent(new CustomEvent('ad-more-click'));
    });
  }
});