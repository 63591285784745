import { clamp } from '../utils';
import SbsPlayerLogger from '../utils/logger';
import { SbsPlayerGlobal } from './global';


/**
 * PC 
 *
 * @export
 * @class Mouse
 */
export class Mouse {
  constructor(props) {
    this.$wrapper = SbsPlayerGlobal.view;
    // this.$ad = props.querySelector('sbs-player-ad-wrapper');
    // this.$player = props.querySelector('sbs-player-wrapper');
    this.props = props;
    this.init();
  }

  init() {
    if (SbsPlayerGlobal.isMobile) return false;
    const hideControls = (ms) => {
      try {
        const adVolume = SbsPlayerGlobal.view.querySelector('sbs-player-ad-volume .controlBarWrap-volume');
        const volume = SbsPlayerGlobal.view.querySelector('sbs-player-volume .controlBarWrap-volume');
        const adVideo = SbsPlayerGlobal.view.querySelector('sbs-player-ad-wrapper video');
        const video = SbsPlayerGlobal.view.querySelector('sbs-player-wrapper video');

        SbsPlayerGlobal.events.emitEvent('controller-hide-event', false);
        clearTimeout(this.timoutEventHideController);
        document.body.style.cursor = '';

        this.timoutEventHideController = setTimeout(() => {
          // * 영상리스트가 나올때는 콘트롤러를 숨지기 않음
          if (SbsPlayerGlobal.view.querySelector('.episodeList-current')) return;

          // * 영상이 멈춘상태에서는 콘트롤러를 숨기지 않는다 like YT
          if (adVideo?.paused || video?.paused) return;

          adVolume?.setAttribute('aria-expanded', false);
          adVolume?.classList.remove('volumeSeek-open');
          volume?.setAttribute('aria-expanded', false);
          volume?.classList.remove('volumeSeek-open');
          SbsPlayerGlobal.events.emitEvent('controller-hide-event', true);
          this.timoutEventHideController = null;

          document.body.style.cursor = 'none';

        }, ms ??= SbsPlayerGlobal.options.hide_contorls_seconds);
      } catch (error) {
        console.log(error);
      }
    }

    hideControls();
    this.$wrapper.addEventListener('mousemove', () => {
      hideControls();
    });
    this.$wrapper.addEventListener('mouseout', () => {
      hideControls(1_000);
    });
    this.$wrapper.addEventListener('mouseover', () => {
      SbsPlayerGlobal.events.emitEvent('controller-hide-event', false);
      clearTimeout(this.timoutEventHideController);
      document.body.style.cursor = '';
    });
  }
}
