/**
 * 화면 구현
 */
import { SbsPlayerGlobal } from '../../common';

customElements.define('sbs-player-toparea-app', class extends HTMLElement {
  constructor() {
    super();

  }

  static get observedAttributes() { return []; }

  async attributeChangedCallback(name, oldValue, newValue) {
    try {

    } catch (error) {
      console.log(error);
    }
  }

  async connectedCallback() {
    try {
      this.render();
    } catch (error) {
      console.log(error);
    }
  }

  disconnectedCallback() {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  adoptedCallback() {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    try {
      // Todo: 기존소스에서 앱프로토콜 확인
      this.innerHTML = `<a role="button" href="javascript:void(0);" class="btnBorderType-sbsApp" aria-label="SBS 앱으로 열기" title="SBS 앱으로 열기" style="cursor: pointer;">SBS APP</a>`;

      this.event();
    } catch (error) {
      console.log(error);
    }
  }

  event() {
    this.addEventListener('click', (event) => {
      SbsPlayerGlobal.events.emitEvent(`applink-clicked`, true);
    });
  }
});