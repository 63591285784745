export * from './compatibility';
import { SbsPlayerGlobal } from '../common';

import SbsPlayerLogger from './logger';

export function isMobile() {
  // * http://lab.sbs.co.kr/allvod/web-front/blob/master/app/source/javascript/common/global.js#L16

  if (/iP(ad|hone|od)/i.test(navigator.userAgent) || /Android/.test(navigator.userAgent)) { // i : ignore case, 대소문자 상관없이 검색.
    return true;
  } else {
    return navigator.maxTouchPoints &&
      navigator.maxTouchPoints >= 2 && (/MacIntel/.test(navigator.userAgent)
        || /Mac/.test(navigator.userAgent)
        || /Linux/.test(navigator.userAgent));
  }
}

export function isIOS() {
  if (/iP(ad|hone|od)/i.test(navigator.userAgent)) { // i : ignore case, 대소문자 상관없이 검색.
    return true;
  } else {
    return navigator.maxTouchPoints &&
      navigator.maxTouchPoints >= 2 && (/MacIntel/.test(navigator.userAgent) ||
        /Mac/.test(navigator.userAgent));
  }
}

export function isAndroid() {
  if (/Android/.test(navigator.userAgent)) {
    return true;
  } else {
    return false;
  }
}

export function isWebView() {
  return navigator.userAgent.indexOf('SBSApp') > -1 ? true : false;
}