import { SbsPlayerGlobal } from '../../../common';
import { getTID, getUUID } from '../../../utils/advertisements';
import SbsPlayerLogger from '../../../utils/logger';
import GoogleAdxAdServer from './adx';
import SbsAdServer from './sbs';
import SmrAdServer from './smr';
import NetInsightXcAdServer from './xc';

class Vendor {
  constructor() {
    this._data = null;
    this._platform = '';
    this._id3 = null;
    this._slots = 0;
    this.live = false;
    this._event = {
      initialized: (position) => {
        console.log(`${position} initialized.`);
      },
      ended: (position) => {
        console.log(`${position} ended.`);
      },

    };
  }

  get platform() {
    return this._platform;
  }

  set platform(platform) {
    return this._platform = platform;
  }

  get id3() {
    return this._id3;
  }

  set id3(id3) {
    return this._id3 = id3;
  }

  get slots() {
    return this._slots;
  }

  set slots(slots) {
    return this._slots = slots;
  }

  get data() {
    return this._data;
  }

  set data(data) {
    this._data = data;
  }

  request(position) {
    return new Promise((resolve, reject) => {
      try {
        console.log(`request vendor: ${this._platform}  ${position}`);
        this.live = position.indexOf('live_midroll') > -1;
        // * ad_25, ad_30
        const { ad, ad_25, ad_30, ad_xc, ad_adx, ad_sbs, log_15, log_20 } = SbsPlayerGlobal.state.data;
        const smrAdVersion = (ad.smr_ad_version || '2.5').replace(/\./g, "");
        const smrMediaLogVersion = (ad.smr_media_log_version || '2.0').replace(/\./g, "");

        const ad_smr = this.live || smrAdVersion === '30' ? ad_30 : ad_25;
        const log_smr = smrMediaLogVersion === '20' ? log_20 : log_15;

        let platform;
        switch (this._platform) {
          case 'SBS':
            platform = new SbsAdServer();
            platform.data = ad_sbs;
            platform.uuid = getUUID(ad_smr);
            platform.tid = getTID(ad_smr);
            break;
          case 'SMR':
            platform = new SmrAdServer();
            platform.data = ad_smr;
            platform.uuid = getUUID(ad_smr);
            platform.tid = getTID(ad_smr);
            platform.live = this.live;

            platform.medialog = log_smr;
            break;
          case 'XC':
            platform = new NetInsightXcAdServer();
            platform.data = ad_xc;
            platform.uuid = getUUID(ad_smr); // player uuid 그대로 사용
            platform.ip = ad_smr.cmdata.ip;
            break;
          case 'ADX':
            platform = new GoogleAdxAdServer();
            platform.data = ad_adx;
            break;
          default:
            break;
        }

        platform.event.initialized = () => {
          platform.requestAd();
        }
        platform.event.succeeded = (campaign) => {
          console.log(`succeeded, ${this._platform}`);
          return resolve(campaign);
        }
        platform.event.waiting = () => {
          console.log(`waiting`);
        }
        platform.event.failed = (error) => {
          console.log(`failed ${error}`);
          return reject(error);
        }
        platform.event.passed = () => {
          console.log(`passed.`);
          return reject();
        }
        platform.event.errored = (error) => {
          console.log(`errored ${error}`);
          return reject(error);
        }
        platform.initialize(position, this._id3, this._slots);
      } catch (error) {
        console.log(error);
        return reject(error);
      }
    })
  }
}
export default Vendor;