/**
 * 플레이어 화면 구현
 */
import muteIcon from '../../../../../../assets/controller_mute.svg';
import volumeIcon from '../../../../../../assets/controller_volume.svg';

import { SbsPlayerGlobal } from '../../../../../common';
import { clamp } from '../../../../../utils';
import { saveLocalStorage } from '../../../../../utils/storage';

import SbsPlayerLogger from '../../../../../utils/logger';

customElements.define('sbs-player-ad-volume', class extends HTMLElement {
  constructor() {
    super();

    this.muted = SbsPlayerGlobal.options.muted ? true : false;
    this.vol = SbsPlayerGlobal.options.muted ? 0 : 100;
    this.pressed = false;
    this.rendered = false;
    this.opened = false;
    this.state = {
      dragging: false
    }
  }

  set vol(value) {
    this._vol = value;
    this.display(value);
  }

  get vol() {
    return this._vol;
  }

  set opened(value) {
    this._opened = value;
    this.render();
  }
  get opened() {
    return this._opened;
  }

  static get observedAttributes() { return ['mute', 'volume']; }

  async attributeChangedCallback(name, oldValue, newValue) {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  async connectedCallback() {
    try {
      this.render();
      this.event();
      this.icons();
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    try {
      const self = this;
      this.innerHTML = `<div class="controlWrap-volume">
                    <div class="controlToggle-volume">
                        <button type="button" class="${this.muted ? 'BtnToggle-volume Btn-mute' : 'BtnToggle-volume'}" aria-label="음소거${this.muted ? ' 해제' : ''}">
                            ${this.muted ? `<span class="iconSvg_mute">
                              <i class="hide">음소거 해제</i>
                            </span>` : `<span class="iconSvg_volume">
                              <i class="hide">음소거</i>
                            </span>`}
                        </button>
                    </div>

                    <div class="controlBarWrap-volume ${this.opened ? 'volumeSeek-open' : ''}" role="slider" aria-valuemin="0" aria-valuemax="100" aria-valuenow="${this.vol}" aria-valuetext="${this.vol}% 볼륨">
                      <div class="volumeSeekBarWrap">
                        <div class="volumeSeekBar">
                          <button type="button" class="volumeSeekBar-boll" style="left:${this.vol}%;"></button>
                          <div class="volumeSeekBar-now" style="width:${this.vol}%;"></div>
                        </div>
                      </div>
                    </div>
                </div>`;

      this.rendered = true;
    } catch (error) {
      console.log(error);
    }
  }

  event() {
    let self = this;

    // * volumebar_wrapper 영역크기가 너무 작음
    const targetElement = this.querySelector('.controlBarWrap-volume');
    targetElement.setAttribute('aria-label', '볼륨 슬라이더');
    targetElement.setAttribute('tabindex', 0);

    let seekValue = null;
    const handleSeekClick = (event) => {
      this.state.dragging = true;
      handleSeekMove(event);
      handleSeekEnd();
    };
    const handleSeekStart = (event) => {
      this.state.dragging = true;
    };
    const handleSeekMove = (event) => {
      if (!this.state.dragging) {
        seekValue = null;
        return;
      }
      let rect = this.querySelector('.controlBarWrap-volume').getBoundingClientRect();
      // ! changedTouches, TouchEvent 일 경우 예외처리
      let value = ((event.clientX || event.detail.clientX || event.changedTouches[0].clientX || event.detail.changedTouches[0].clientX) - rect.left) / rect.width;
      value = clamp(value, 0, 1);
      seekValue = value;
      this.dispatchEvent(new CustomEvent('input-change-volume', { detail: seekValue }));
    };
    const handleSeekEnd = () => {
      this.state.dragging = false;
      if (seekValue) {
        this.dispatchEvent(new CustomEvent('input-change-volume', { detail: seekValue }));
      }
    };
    targetElement.addEventListener("click", handleSeekClick);
    targetElement.addEventListener("mousedown", handleSeekStart);
    targetElement.addEventListener("touchstart", handleSeekStart, false);
    // * 마우스가 밖으로 벗어나도 seeking 이 가능했으면 함(like YT)
    targetElement.addEventListener("progress-move", handleSeekMove);
    document.addEventListener("mousemove", handleSeekMove);
    document.addEventListener("touchmove", handleSeekMove, false);
    document.addEventListener("mouseup", handleSeekEnd);
    document.addEventListener("touchend", handleSeekEnd, false);

    targetElement.addEventListener("click", (event) => {
      const rect = this.getBoundingClientRect();
      const value = (event.clientX - rect.left) / rect.width;
      this.dispatchEvent(new CustomEvent('progress-click', { detail: value }));
    });
    targetElement.addEventListener("mouseleave", (event) => {
      this.dispatchEvent(new CustomEvent('progress-leave', { detail: event }));
    });
    targetElement.addEventListener("mousemove", (event) => {
      this.dispatchEvent(new CustomEvent('progress-move', { detail: event }));
    });
    targetElement.addEventListener("touchmove", (event) => {
      this.dispatchEvent(new CustomEvent('progress-move', { detail: event }));
    });
    targetElement.addEventListener("touchend", (event) => {
      this.dispatchEvent(new CustomEvent('progress-leave', { detail: event }));
    });

    this.addEventListener("mousemove", (event) => {
      targetElement.setAttribute('aria-expanded', true);
      targetElement.classList.add('volumeSeek-open');
    });
    this.addEventListener("touchmove", (event) => {
      targetElement.setAttribute('aria-expanded', true);
      targetElement.classList.add('volumeSeek-open');
    });
    this.addEventListener("mouseleave", (event) => {
      if (!this.state.dragging) {
        targetElement.setAttribute('aria-expanded', false);
        targetElement.classList.remove('volumeSeek-open');
      }
    });
    this.addEventListener("touchend", (event) => {
      targetElement.setAttribute('aria-expanded', false);
      targetElement.classList.remove('volumeSeek-open');
    });
    this.querySelector('button').addEventListener('click', (event) => {
      self.dispatchEvent(new CustomEvent('button-toggle-volume', { detail: this.muted }));
    });

    SbsPlayerGlobal.events.addEventListener('motion-key-event', (status) => {
      if (status.indexOf('vol') > -1) {
        targetElement.setAttribute('aria-expanded', true);
        targetElement.classList.add('volumeSeek-open');
      }
    });
  }

  display(value) {
    console.log(`display ${value}`);
    if (this.rendered) {
      if (value < 10) {
        value = 0;
        this.muted = true;
      } else {
        this.muted = false;
      }

      const slider = this.querySelector('.controlBarWrap-volume');
      const handle = this.querySelector('.volumeSeekBar-boll');
      const now = this.querySelector('.volumeSeekBar-now');
      slider.setAttribute('aria-valuenow', value);
      slider.setAttribute('aria-valuetext', `${value}% 볼륨`);
      handle.style.left = value + '%';
      now.style.width = value + '%';

      const button = this.querySelector('.controlToggle-volume button');
      // ? toggle 이였다면..
      if (this.muted) {
        button.classList.add('Btn-mute');
      } else {
        button.classList.remove('Btn-mute');
      }
      button.setAttribute('aria-label', `음소거${this.muted ? ' 해제' : ''}`);
      button.innerHTML = `${this.muted ? `<span class="iconSvg_mute">
                            <i class="hide">음소거 해제</i>
                          </span>` : `<span class="iconSvg_volume">
                            <i class="hide">음소거</i>
                          </span>`}`;
      this.icons();
    }
  }

  icons() {
    try {
      const muteIconElement = this.querySelector('.iconSvg_mute');
      muteIconElement?.insertAdjacentHTML('afterbegin', muteIcon);

      const volumeIconElement = this.querySelector('.iconSvg_volume');
      volumeIconElement?.insertAdjacentHTML('afterbegin', volumeIcon);
    } catch (error) {
      console.log(error);
    }
  }
});