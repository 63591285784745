import { SbsPlayerGlobal } from '../../common';
import SbsPlayerLogger from '../../utils/logger';

class MyVodTime {
  constructor() {
    this.playduration = 0;
    this.mediaId = undefined;
    this.lastTime = 0;
    this.currentTime = 0;
    this.duration = undefined;
    this._event = {
      initialized: () => {
        console.log(`initialized.`);
      },
    };
  }

  get event() {
    return this._event;
  }
  set event(event) {
    this._event = event;
  }

  init(video, continueTime) {
    this.playduration = continueTime;
    this.videoElement = video;
    this.mediaId = SbsPlayerGlobal.state.data.info.mediaid
    this.videoElement.addEventListener('timeupdate', (event) => {
      this.currentTime = Math.floor(event.target.currentTime) || 0;
    });
    this.videoElement.addEventListener('durationchange', (event) => {
      this.duration = Math.floor(event.target.duration);
    });
    this._event.initialized();
  }

  setTracker() {
    try {
      console.log('트래킹로그 전송 인터벌 10(s)', this.playduration);
      // TODO: 여기서 트래킹로그 전송, 현재 timeupdate 60번에 한번 호출 중, 대략 30초(->10초)에 한번 호출하면되나?
      this.sendTrackingInterval = setInterval(() => {
        if (SbsPlayerGlobal.token && this.mediaId && this.duration && this.currentTime !== this.lastTime) {
          this.lastTime = this.currentTime;
          fetch(`https://${SbsPlayerGlobal.development ? 'dev.' : ''}apis.sbs.co.kr/play-api/1.0/sbs_myvod/time`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'jwt-token': SbsPlayerGlobal.token
            },
            body: JSON.stringify({
              mediaid: this.mediaId,
              duration: this.currentTime,
              totalduration: this.duration.toString()
            })
          })
        }
      }, 10_000);

      if (this.playduration) {
        setTimeout(() => { this.videoElement.currentTime = this.playduration; }, 500);
      }
    } catch (error) {
      console.log(error);
    }
  }

  removeTracker() {
    try {
      clearInterval(this.sendTrackingInterval);
      this.sendTrackingInterval = null;
    } catch (error) {
      console.log(error);
    }
  }
}

export default MyVodTime;