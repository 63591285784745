/**
 * 화면 구현
 */
import unmuteIcon from '../../../assets/controller_volume.svg';

import { SbsPlayerGlobal } from '../../common';

customElements.define('sbs-player-toparea-unmute', class extends HTMLElement {
  constructor() {
    super();
  }

  async connectedCallback () {
    try {
      this.render();
    } catch (error) {
      console.log(error);
    }
  }

  render () {
    try {
      if (SbsPlayerGlobal.options.muted) {
        this.innerHTML = `<button type="button" class="playerMobileBtn-mute">
                          <span class="iconSvg_mute"></span>
                          <strong class="playerMobileBtn-text">탭하여 음소거 해제</strong>
                        </button>`;
        this.events();
      }
    } catch (error) {
      console.log(error);
    }
  }

  events () {
    try {
      this.querySelector('.playerMobileBtn-mute').addEventListener('click', () => {
        SbsPlayerGlobal.options.muted = false;

        const adVideoElement = SbsPlayerGlobal.view.querySelector('sbs-player-ad-wrapper video');
        const playerVideoElement = SbsPlayerGlobal.view.querySelector('sbs-player-wrapper video');

        if (adVideoElement) adVideoElement.volume = 0.5;
        if (playerVideoElement) playerVideoElement.volume = 0.5;

        SbsPlayerGlobal.events.emitEvent('input-change-volume', 0.5);

        SbsPlayerGlobal.events.addEventListener('input-change-volume', (volume) => {
          if (volume > 0) this.remove();
        });
      });
    } catch (error) {
      console.log(error);
    }
  }
});