/**
 * 백워드 중앙버튼
 */
import SbsPlayerLogger from '../../../../utils/logger';

import backwardIcon from '../../../../../assets/backward.svg';
customElements.define('sbs-player-ad-center-backward', class extends HTMLElement {
  constructor() {
    super();
  }

  static get observedAttributes() { return []; }

  async attributeChangedCallback(name, oldValue, newValue) {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  async connectedCallback() {
    try {
      this.render();
    } catch (error) {
      console.log(error);
    }
  }

  disconnectedCallback() {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  adoptedCallback() {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    try {
      this.innerHTML = `<button type="button" class="videoBtnPrev-10s" aria-label="10초 이전" title="10초 이전">
          <span class="iconSvg_prev10s"><i class="hide">10초 이전</i></span>
        </button>`;
      this.event();
      this.styles();
      this.icons();
    } catch (error) {
      console.log(error);
    }
  }

  event() {
    // * 특정시간 동안 연타할 경우 횟수 계산하기
    this.querySelector('button').addEventListener('click', (event) => {
      this.dispatchEvent(new CustomEvent('button-click-backward', {
        detail: this.paused
      }));
    });
  }
  styles() {
    try {
      this.style.visibility = 'hidden';
    } catch (error) {
      console.log(error);
    }
  }
  icons() {
    try {
      const backwardIconElement = this.querySelector('.iconSvg_prev10s');
      backwardIconElement?.insertAdjacentHTML('afterbegin', backwardIcon);
    } catch (error) {
      console.log(error);
    }
  }
});