/**
 * 화면 구현
 */
import SbsPlayerLogger from '../../utils/logger';
import { SbsPlayerGlobal } from '../../common/global';

customElements.define('sbs-player-warning', class extends HTMLElement {
  constructor() {
    super();
    this.visible = false;
  }

  set visible(value) {
    this._visible = value;
    this.render();
  }

  get visible() {
    return this._visible;
  }

  static get observedAttributes() { return ['title', 'html']; }

  async attributeChangedCallback(name, oldValue, newValue) {
    try {
      if (name === 'title' && (oldValue !== newValue)) {
        this.title = newValue;
      }
      if (name === 'html' && (oldValue !== newValue)) {
        this.html = newValue;
      }
    } catch (error) {
      console.log(error);
    }
  }

  async connectedCallback() {
    try {
      this.render();
    } catch (error) {
      console.log(error);
    }
  }

  disconnectedCallback() {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  adoptedCallback() {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    try {
      this.innerHTML = `<div class="playerDialogADBlock_w current" role="dialog">
        <div class="playerDialogContents">
            <div class="ADBlock_cont">
                <span class="icon_caution">
                  <span class="iconSvg_caution"></span>
                </span>
                <em class="ADBlock_title">${this.title}</em>
                <p class="ADBlock_text">
                </p>
            </div>
        </div>
    </div>`;
      this.querySelector('.ADBlock_text').innerHTML = this.html;

      this.styles();
      this.event();
    } catch (error) {
      console.log(error);
    }
  }

  styles() {
    try {
      if (SbsPlayerGlobal.isMobile) this.style.paddingTop = `56.2%`;
    } catch (error) {
      console.log(error);
    }
  }

  event() {
    try {
    } catch (error) {
      console.log(error);
    }
  }
});