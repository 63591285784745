import { SbsPlayerGlobal } from '../../../../../common';
import { loadLocalStorage } from '../../../../../utils/storage';
import SbsPlayerLogger from '../../../../../utils/logger';


/**
 * 플레이어 화면 구현
 */
customElements.define('sbs-player-speed-menu', class extends HTMLElement {
  constructor() {
    super();

    this.state = {
      data: ['2.0', '1.75', '1.5', '1.25', '1.0', '0.75'],
      select: `${SbsPlayerGlobal.infoType !== 'onair' ? parseFloat((loadLocalStorage(SbsPlayerGlobal.storage.sbsplayer_playback_rate) || 1)).toFixed(2) : 1}`
    }
    this.opened = false;
    this.panel_id = `sbs-player-speed-panel-${SbsPlayerGlobal.id.uuid}`;
    this.menuitem_id = `sbs-player-speed-menuitem-${SbsPlayerGlobal.id.uuid}`;
  }

  set opened(value) {
    this._opened = value;
    this.render();
  }
  get opened() {
    return this._opened;
  }

  connectedCallback() {
    try {
      this.render();
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    try {
      let items = '';
      if (this.state.data.length > 0) {
        this.state.data.forEach(item => {
          let isSelected = parseFloat(this.state.select) === parseFloat(item);
          items += `<div role="menuitemradio" tabindex="0" aria-label="${item} 배속" ${isSelected ? `aria-checked="true"` : ``}>
            <button type="button" class="btnLayerSelect-speed ${isSelected ? 'current' : ''}" value="${item}">${'x ' + item}</button>
          </div>`;
        });
      }
      this.innerHTML = `<div id="${this.panel_id}" 
            class="controlSelect-speed ${this.opened ? 'open-layer' : ''}">
            <button id="${this.menuitem_id}" type="button" class="btnText-speed" aria-label="배속 변경" aria-haspopup="true" aria-expanded="${this.opened ? 'true' : 'false'}">
              <span class="text-name">배속</span>
            </button>
            <div class="selectLayerWrap-speed" role="menu" style="display: ${this.opened ? 'block' : 'none'}">
              ${items}
            </div>
        </div>`;
      this.event();
    } catch (error) {
      console.log(error);
    }
  }


  event() {
    let menuitem = this.querySelector(`#${this.menuitem_id}`);
    menuitem.addEventListener('click', (event) => {
      event.preventDefault();
      this.opened = !this.opened;
      this.dispatchEvent(new CustomEvent('control-menu-toggle', { detail: this.opened }));
    });
    menuitem.addEventListener('keydown', (event) => {
      if (event.code === 'Space' || event.code === 'Enter') {
        menuitem.click();
      }
    });

    const speedButtons = SbsPlayerGlobal.view.querySelectorAll('.selectLayerWrap-speed div');
    speedButtons.forEach(item => {
      item.addEventListener('click', (event) => {
        let changeValue = event.target.tagName === 'BUTTON' ? event.target.value : event.target.querySelector('button').value;

        // 토글 닫기
        this.opened = false;
        this.dispatchEvent(new CustomEvent('control-menu-toggle', { detail: this.opened }));

        // 동일속도일 경우 그냥 닫기
        if (event.target.classList.contains('current')) {
          return false;
        }

        this.state.select = parseFloat(changeValue).toFixed(2);
        this.querySelector('.controlSelect-speed').classList.toggle('open-layer');
        SbsPlayerGlobal.events.emitEvent('select-change-speed', Number(changeValue));
        this.render();
      });
      item.addEventListener('keydown', (event) => {
        if (event.code === 'Space' || event.code === 'Enter') {
          item.click();
        }
      });
    });
  }
});