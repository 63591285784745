/**
 * 플레이어 화면 구현
 */
import { SbsPlayerGlobal } from '../../../../../common';
import { clamp } from '../../../../../utils';
import SbsPlayerLogger from '../../../../../utils/logger';


customElements.define('sbs-player-timeshift-hover', class extends HTMLElement {
  constructor() {
    super();
    this.percentage = 0;
    this.text = undefined;
  }
  set percentage(value) {
    this._percentage = value;
    this.render();
  }
  get percentage() {
    return this._percentage;
  }
  set text(value) {
    this._text = value;
    this.render();
  }
  get text() {
    return this._text;
  }
  static get observedAttributes() { return []; }

  async attributeChangedCallback(name, oldValue, newValue) {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  async connectedCallback() {
    try {
      this.render();
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    try {
      this.innerHTML = `<div class="timeMachineHover_w" style="left:${this.percentage}%;"><span class="timeMachineHover_text">${this.text ? '-' + this.text : ''}</span></div>`;

    } catch (error) {
      console.log(error);
    }
  }
});