/**
 * 스토리지 지원 유틸리티 클래스
 * @static
 */
export function saveLocalStorage(key, value) {
  if (typeof key === 'string' || key instanceof String) {
    localStorage.setItem(key, value);
  }
  else {
    throw new TypeError('매개변수 key가 String 타입이 아닙니다.');
  }
}

export function loadLocalStorage(key) {
  if (typeof key === 'string' || key instanceof String) {
    let value = localStorage.getItem(key);

    if (value === null) {
      return '';
    }
    else {
      return value;
    }
  }
  else {
    throw new TypeError('매개변수가 String 타입이 아닙니다.');
  }
}

export function removeLocalStorage(key) {
  if (typeof key === 'string' || key instanceof String) {
    return localStorage.removeItem(key);
  }
  else {
    throw new TypeError('매개변수가 String 타입이 아닙니다.');
  }
}
