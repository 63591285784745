import SbsPlayerLogger from '../../utils/logger';

class OnairFlag {
  constructor() {
    this.url = null;
    this.interval = null;

    this.sendInterval = null;
    this._event = {
      initialized: () => {
        console.log(`initialized.`);
      },
      passed: (result) => {
        console.log(`passed. ${JSON.stringify(result)}`);
      },
      failed: (message) => {
        console.log(`failed. ${message}`);
      },
      errored: () => {
        console.log(`errored.`);
      },
    };
  }

  get event() {
    return this._event;
  }
  set event(event) {
    this._event = event;
  }

  init(flagUrl, flagRepeatTime) {
    this.url = flagUrl;
    this.interval = flagRepeatTime;
    if (this.url && this.interval) {
      this._event.initialized();
    }
  }

  send() {
    try {
      console.log('온에어플래그 전송 인터벌', this.url);

      this.sendInterval = setInterval(() => {
        fetch(this.url).then((response) => {
          if (response.status === 200) {
            response.json().then((result) => {
              if (result.onair_yn === 'N') {
                this._event.failed(result.onair_text);
              } else if (result.overseas_yn === 'N') {
                this._event.failed(result.overseas_text);
              } else {
                this.interval = result.flag_repeat_time;
                this._event.passed(result);
              }
            });
          } else {
            this._event.errored();
          }
        }).catch(function (err) {
          console.error(err);
          this._event.errored();
        });
      }, this.interval * 1_000);
    } catch (error) {
      console.log(error);
    }
  }

  clear() {
    try {
      clearInterval(this.sendInterval);
      this.sendInterval = null;
    } catch (error) {
      console.log(error);
    }
  }
}

export default OnairFlag;