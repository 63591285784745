import { FullScreen } from '../../common';

customElements.define('sbs-player-common-fullscreen-toggle', class extends HTMLElement {
  constructor() {
    super();
  }

  static get observedAttributes() { return []; }

  async attributeChangedCallback(name, oldValue, newValue) {
    try {

    } catch (error) {
      console.log(error);
    }
  }

  async connectedCallback() {
    try {
      this.render();
    } catch (error) {
      console.log(error);
    }
  }

  disconnectedCallback() {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  adoptedCallback() {
    try {
    } catch (error) {
      console.log(error);
    }
  }


  render() {
    try {
      this.innerHTML = `<div class="controlToggle-fullScreen">
            <button type="button" class="BtnToggle-fullScreen" aria-label="전체화면">
                <span class="iconSvg_fullScreen">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="20" viewBox="0 0 20 20">
                      <defs>
                        <filter id="btn_expansion" x="0" y="0" width="20" height="20" filterUnits="userSpaceOnUse">
                          <feOffset input="SourceAlpha"/>
                          <feGaussianBlur stdDeviation="0.5" result="blur"/>
                          <feFlood flood-opacity="0.302"/>
                          <feComposite operator="in" in2="blur"/>
                          <feComposite in="SourceGraphic"/>
                        </filter>
                      </defs>
                      <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#btn_expansion)">
                        <path id="btn_expansion-2" data-name="btn_expansion" d="M103.2,18.755a.882.882,0,0,1-.91-.854.5.5,0,0,1,0-.056V13.808a.91.91,0,0,1,1.821,0h0v3.127h3.127a.91.91,0,0,1,0,1.821Zm0-11.14a.882.882,0,0,1-.91-.854.5.5,0,0,1,0-.056V2.669a.883.883,0,0,1,.854-.912h4.093a.91.91,0,0,1,0,1.821H104.11V6.7a.882.882,0,0,1-.854.91.5.5,0,0,1-.056,0m11.14,11.14a.91.91,0,0,1,0-1.821h3.127V13.808a.91.91,0,0,1,1.821,0v4.037a.882.882,0,0,1-.854.91.5.5,0,0,1-.056,0Zm4.037-11.14a.882.882,0,0,1-.91-.854.5.5,0,0,1,0-.056V3.578H114.34a.91.91,0,0,1,0-1.821h4.038a.883.883,0,0,1,.91.854.518.518,0,0,1,0,.057V6.7a.882.882,0,0,1-.854.91.5.5,0,0,1-.056,0" transform="translate(-100.79 -0.26)" fill="#fff"/>
                      </g>
                    </svg>
                    <i class="hide">전체화면</i>
                </span>
                <span class="iconSvg_ExitFullScreen">

                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="20" viewBox="0 0 20 20">
                      <defs>
                        <filter id="btn_contract" x="0" y="0" width="20" height="20" filterUnits="userSpaceOnUse">
                          <feOffset input="SourceAlpha"/>
                          <feGaussianBlur stdDeviation="0.5" result="blur"/>
                          <feFlood flood-opacity="0.302"/>
                          <feComposite operator="in" in2="blur"/>
                          <feComposite in="SourceGraphic"/>
                        </filter>
                      </defs>
                      <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#btn_contract)">
                        <path id="btn_contract-2" data-name="btn_contract" d="M138.258,17.875a.887.887,0,0,1-.256-.652V13.194a.876.876,0,0,1,.845-.908.594.594,0,0,1,.061,0h4.026a.92.92,0,0,1,.652,1.56.892.892,0,0,1-.652.257h-3.117v3.119a.919.919,0,0,1-1.558.652Zm-7.081,0a.888.888,0,0,1-.26-.652V14.1H127.8a.9.9,0,0,1-.65-.257.908.908,0,0,1,.65-1.56h4.026a.877.877,0,0,1,.907.847v4.089a.88.88,0,0,1-.85.911.514.514,0,0,1-.057,0,.885.885,0,0,1-.649-.26m7.733-10.859a.883.883,0,0,1-.909-.91V2.078a.908.908,0,1,1,1.815-.066c0,.022,0,.043,0,.066V5.2h3.117A.91.91,0,0,1,143,7.015q-.033,0-.067,0Zm-11.109,0a.884.884,0,0,1-.65-.259.955.955,0,0,1,0-1.3.884.884,0,0,1,.65-.259h3.117V2.079a.909.909,0,1,1,1.817-.067q0,.033,0,.067V6.107a.882.882,0,0,1-.851.91.5.5,0,0,1-.056,0Z" transform="translate(-125.38 0.36)" fill="#fff"/>
                      </g>
                    </svg>

                    <i class="hide">전체화면 해제</i>
                </span>
            </button>
        </div>`;
      this.styles();
      this.event();
    } catch (error) {
      console.log(error);
    }
  }

  styles() {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  event() {
    try {
      this.fullscreen = new FullScreen(this);

      // Btn_ExitFullScreen
      this.querySelector('a').addEventListener('click', () => {
        this.fullscreen.toggle();
      });
    } catch (error) {
      console.log(error);
    }
  }
});