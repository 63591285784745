/**
 * 다시보기 중앙버튼
 */
import SbsPlayerLogger from '../../../../utils/logger';

customElements.define('sbs-player-center-timeshift-text', class extends HTMLElement {
  constructor() {
    super();
    this.text = '';
  }

  static get observedAttributes() { return ['text']; }

  async attributeChangedCallback(name, oldValue, newValue) {
    try {
      if (name === 'text' && (oldValue !== newValue)) {
        this.text = newValue;
      }
      this.render();
    } catch (error) {
      console.log(error);
    }
  }

  async connectedCallback() {
    try {
      this.render();
    } catch (error) {
      console.log(error);
    }
  }

  disconnectedCallback() {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  adoptedCallback() {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    try {
      this.innerHTML = `<div class="playerTimeMachine-text">${this.text}</div>`;
      this.event();
      this.styles();
    } catch (error) {
      console.log(error);
    }
  }

  event() {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  styles() {
    try {
    } catch (error) {
      console.log(error);
    }
  }
});