/**
 * 플레이어 화면 구현
 */
import { SbsPlayerGlobal } from '../../../../../common';
import { clamp } from '../../../../../utils';
import SbsPlayerLogger from '../../../../../utils/logger';


customElements.define('sbs-player-timeshift-now', class extends HTMLElement {
  constructor() {
    super();
    this.text = undefined;
  }
  set percentage(value) {
    this._percentage = value;
    this.render();
  }
  get percentage() {
    return this._percentage;
  }
  set text(value) {
    this._text = value;
    this.render();
  }
  get text() {
    return this._text;
  }
  static get observedAttributes() { return []; }

  async attributeChangedCallback(name, oldValue, newValue) {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  async connectedCallback() {
    try {
      this.render();
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    try {
      this.innerHTML = `<div class="timeMachine-nowTime">
                        <div class="nowTime_inner">
                          <span class="nowTime_text">${this.text ? '-' + this.text : ''}</span>
                        </div>
                    </div>`;
      this.percentage === 100 ? this.querySelector('.timeMachine-nowTime').classList.add('last') : this.querySelector('.timeMachine-nowTime').classList.remove('last');
      this.percentage === 0 ? this.querySelector('.timeMachine-nowTime').classList.add('first') : this.querySelector('.timeMachine-nowTime').classList.remove('first');
    } catch (error) {
      console.log(error);
    }
  }
});