/**
 * 광고 화면 구현
 */
import { SbsPlayerGlobal } from '../../../../common/global';
import SbsPlayerLogger from '../../../../utils/logger';

import './live';
import './more';
import './point';
import './skip';
import './text'
customElements.define('sbs-player-ad-info-area', class extends HTMLElement {
  constructor() {
    super();
    this.campaign = null;
    this.duration = undefined;
    this.text = '';
  }

  set campaign(value) {
    this._campaign = value;
    this.render();
  }

  get campaign() {
    return this._campaign;
  }

  static get observedAttributes() { return ['duration', 'text']; }

  async attributeChangedCallback(name, oldValue, newValue) {
    try {
      if (name === 'duration' && (oldValue !== newValue)) {
        this.duration = parseInt(newValue);
      }
      if (name === 'text' && (oldValue !== newValue)) {
        this.text = newValue;
      }
      this.render();
    } catch (error) {
      console.log(error);
    }
  }

  async connectedCallback() {
    try {
      this.render();
    } catch (error) {
      console.log(error);
    }
  }

  disconnectedCallback() {
    try {
      if (!document.body.contains(this)) {

      }
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    try {
      if (!this.campaign) return false;
      const advertisement = this.campaign.ads[0] || this.campaign.ads || null;
      if (advertisement) {
        const isLiveMidRoll = (this.duration && this.text)

        this.innerHTML = `
        <sbs-player-ad-top-text text="${this.text}"></sbs-player-ad-top-text>
        <div class="playerADFunction_w">
          <sbs-player-ad-info-more></sbs-player-ad-info-more>
          ${isLiveMidRoll
            ? `<sbs-player-ad-info-live duration=${this.duration} skip=${0}></sbs-player-ad-info-live>`
            : `<sbs-player-ad-info-skip duration=${advertisement.duration} skip=${advertisement.skip.offset}></sbs-player-ad-info-skip>`}
        </div>`;
        this.event();
      }
    } catch (error) {
      console.log(error);
    }
  }
  event() {
    try {
      const skip = this.querySelector('sbs-player-ad-info-skip');
      skip?.addEventListener('ad-skip-click', () => {
        this.dispatchEvent(new CustomEvent('ad-skip-click'));
      });
      const more = this.querySelector('sbs-player-ad-info-more');
      more?.addEventListener('ad-more-click', () => {
        this.dispatchEvent(new CustomEvent('ad-more-click'));
      });

      SbsPlayerGlobal.events.addEventListener('video-change-ad', async (event) => {
        try {
          const video = event.target;
          if ('timeupdate' === event.type && video.currentTime && video.duration) {
            if (skip) skip.timeupdate(video.currentTime);
            const pointUrl = SbsPlayerGlobal.state.data.info.point_billing_api_url?.replace('http://', 'https://');
            // 포인트 적립
            // TODO: 포인트, 클립로그 등 서버측에 Access-Control-Allow-Origin: * 또는 *.sbs.co.kr, CORS 허용 필요
            if (video.currentTime > 13 && this.querySelector('sbs-player-ad-info-point') === null && pointUrl) {
              try {
                console.log(`포인트 적립 API ${pointUrl}`);
                SbsPlayerGlobal.state.data.info.point_billing_api_url = undefined;
                let response = await fetch(pointUrl);
                if (response.status === 200 && response.errmsg === undefined) {
                  const wrapperElement = this.querySelector('.playerADFunction_w');
                  wrapperElement?.insertAdjacentHTML('afterbegin', `<sbs-player-ad-info-point></sbs-player-ad-info-point>`);
                } else {
                  console.warn(`${response.status}, ${response.errmsg}, 포인트 적립 대상이지만, 적립하지 못함`);
                }
              } catch (error) {
                console.log(error, '포인트 적립 대상이지만, 적립하지 못함');
              }
            }
          }
        } catch (error) {
          console.log(error);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }
});