/**
 * 플레이어 화면 구현
 */
import replayIcon from '../../../../../../assets/controller_replay.svg';
import SbsPlayerLogger from '../../../../../utils/logger';

customElements.define('sbs-player-replay', class extends HTMLElement {
  constructor() {
    super();
    this.visible = true;
  }

  set visible(value) {
    this._visible = value;
    this.render();
  }

  get visible() {
    return this._visible;
  }

  static get observedAttributes() { return ['']; }

  async attributeChangedCallback(name, oldValue, newValue) {
    try {

    } catch (error) {
      console.log(error);
    }
  }

  async connectedCallback() {
    try {
      this.render();
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    try {
      this.innerHTML = `${this.visible ? `<div class="current-replay">
        <button type="button" class="Btn-replay" aria-label="다시보기">
          <span class="iconSvg_replay"><i class="hide">다시보기</i></span>
        </button>
      </div>` : ``}`;

      this.event();
      this.styles();
      this.icons();
    } catch (error) {
      console.log(error);
    }
  }

  event() {
    try {
      this.querySelector('button')?.addEventListener('click', (event) => {
        this.dispatchEvent(new CustomEvent('button-click-replay'));
      });
    } catch (error) {
      console.log(error);
    }
  }
  styles() {
    try {

    } catch (error) {
      console.log(error);
    }
  }
  icons() {
    try {
      const replayIconElement = this.querySelector('.iconSvg_replay');
      replayIconElement?.insertAdjacentHTML('afterbegin', replayIcon);
    } catch (error) {
      console.log(error);
    }
  }
});