/**
 * 플레이어 화면 구현
 */
import { SbsPlayerGlobal } from '../../../../../common';
import { clamp } from '../../../../../utils';
import SbsPlayerLogger from '../../../../../utils/logger';
import '../LiveTimeShift';

customElements.define('sbs-player-progressbar', class extends HTMLElement {
  constructor() {
    super();
    this.current = 0;
    this.progress = 0;
    this.rendered = false;
    this.state = {
      dragging: false,
    }
  }

  set current(value) {
    this._current = value;
    this.updateCurrent(value);
  }
  get current() {
    return this._current;
  }
  set progress(value) {
    this._progress = value;
    this.updateProgress(value);
  }
  get progress() {
    return this._progress;
  }

  static get observedAttributes() { return []; }

  async attributeChangedCallback(name, oldValue, newValue) {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  async connectedCallback() {
    try {
      this.render();
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    try {
      this.style.width = "100%";
      this.innerHTML = `<div class="playerSeekBar">
                <button type="button" class="playerSeekBar-boll" tabindex="-1" style="left:${this.value}%; border: none;">
                  ${SbsPlayerGlobal.infoType === 'onair' ? `<sbs-player-timeshift-now></sbs-player-timeshift-now>` : ``}
                </button>
                <div class="playerSeekBar-now" style="width:${this.value}%;"></div>
                <div class="playerSeekBar-Loding" style="width:${this.load}%"></div>
                ${SbsPlayerGlobal.infoType === 'onair' ? `<sbs-player-timeshift></sbs-player-timeshift>` : ``}
            </div>`;
      this.event();
      this.rendered = true;
    } catch (error) {
      console.log(error);
    }
  }

  event() {
    // * this(sbs-player-progressbar) 영역이 너무 작아서 계속 leave 발생함. target을 parentElement(.playerSeekBar_w)으로 변경
    // * 모바일에선 슬라이더 전체영역을 잡기보단 슬라이더 헤더에만 있는게 나아보임, like yt.
    const targetElement = this.parentElement;
    targetElement.setAttribute('aria-label', '탐색 슬라이더');
    targetElement.setAttribute('tabindex', 0);

    let seekValue = null;

    const handleSeekStart = (event) => {
      this.state.dragging = true;
      this.dispatchEvent(new CustomEvent('progress-down', { detail: event }));
      this.dispatchEvent(new CustomEvent('progress-is-dragging', { detail: this.state.dragging }));
    };
    const handleSeekMove = (event) => {
      if (!this.state.dragging) {
        seekValue = null;
        return;
      }
      let rect = targetElement.getBoundingClientRect();
      // ! changedTouches, TouchEvent 일 경우 예외처리
      let value = ((event.clientX || event.detail.clientX || event.changedTouches[0].clientX || event.detail.changedTouches[0].clientX) - rect.left) / rect.width;
      value = clamp(value, 0, 1);
      seekValue = value;
      this.current = value;
    };
    const handleSeekEnd = () => {
      this.state.dragging = false;
      if (seekValue) {
        this.dispatchEvent(new CustomEvent('progress-click', { detail: seekValue }));
      }
      this.dispatchEvent(new CustomEvent('progress-is-dragging', { detail: this.state.dragging }));
    };
    targetElement.addEventListener("mousedown", handleSeekStart);
    targetElement.addEventListener("touchstart", handleSeekStart, false);
    // * 마우스가 밖으로 벗어나도 seeking 이 가능했으면 함(like YT)
    targetElement.addEventListener("progress-move", handleSeekMove);
    document.addEventListener("mousemove", handleSeekMove);
    document.addEventListener("touchmove", handleSeekMove, false);
    document.addEventListener("mouseup", handleSeekEnd);
    document.addEventListener("touchend", handleSeekEnd, false);

    targetElement.addEventListener("click", (event) => {
      const rect = this.getBoundingClientRect();
      const value = (event.clientX - rect.left) / rect.width;

      this.dispatchEvent(new CustomEvent('progress-click', { detail: value }));
    });
    targetElement.addEventListener("mouseleave", (event) => {
      this.dispatchEvent(new CustomEvent('progress-leave', { detail: event }));
    });
    targetElement.addEventListener("mousemove", (event) => {
      this.dispatchEvent(new CustomEvent('progress-move', { detail: event }));
    });
    targetElement.addEventListener("touchmove", (event) => {
      this.dispatchEvent(new CustomEvent('progress-move', { detail: event }));
    });
    targetElement.addEventListener("touchend", (event) => {
      this.dispatchEvent(new CustomEvent('progress-leave', { detail: event }));
    });
  }

  updateCurrent(value) {
    if (this.rendered) {
      const handle = this.querySelector('.playerSeekBar-boll');
      const now = this.querySelector('.playerSeekBar-now');
      handle.style.left = value * 100 + '%';
      now.style.width = value * 100 + '%';
    }
  }

  updateProgress(value) {
    if (this.rendered) {
      const load = this.querySelector('.playerSeekBar-Loding');
      load.style.width = value * 100 + '%';
    }
  }

  live() {
    try {
      this.dispatchEvent(new CustomEvent('progress-click', { detail: 1 }));
    } catch (error) {

    }
  }
});