import { convertTimeStampToSeconds } from '../../../utils';
import SbsPlayerLogger from '../../../utils/logger';

class Campaign {
  constructor() {
    this._data = {};
    this._event = {
      initialized: () => {
        console.log(`initialized.`);
      }
    };
  }

  get event() {
    return this._event;
  }

  set event(event) {
    return this._event = event;
  }

  getCampaign(response) {
    try {
      if (typeof response === 'object') {
        return response;
      } else {
        return response.json();
      }
    } catch (error) {
      console.log(error);
      return null;
    }

  }
  /**
   * Vast(Xml)에서 json 규격으로 변경된 광고응답을 smr 규격에 맞추어 변경   
   *
   * @param {*} vast2json
   * @return {*} 
   * @memberof Campaign
   */
  convertSbsToSmr(vast2json) {
    try {
      let vastLinear = vast2json.ad[0].inLine.creatives[0].linear;
      return {
        'response': 'video',
        'ads': [{
          'content_url': vastLinear.mediaFiles.mediaFile[0].uri,
          'duration': convertTimeStampToSeconds(vastLinear.duration),
          'house': 0,
          'tracking_url': {
            'start': vastLinear.trackingEvents.filter(function (tracking) {
              return tracking.event == "start"
            })[0].uri,
            'firstQuartile': vastLinear.trackingEvents.filter(function (tracking) {
              return tracking.event == "firstQuartile"
            })[0].uri,
            'mid_point': vastLinear.trackingEvents.filter(function (tracking) {
              return tracking.event == "midpoint"
            })[0].uri,
            'thirdQuartile': vastLinear.trackingEvents.filter(function (tracking) {
              return tracking.event == "thirdQuartile"
            })[0].uri,
            'complete': vastLinear.trackingEvents.filter(function (tracking) {
              return tracking.event == "complete"
            })[0].uri,
            'skip': vastLinear.trackingEvents.filter(function (tracking) {
              return tracking.event == "skip"
            })[0].uri,
            'click': vastLinear.videoClicks.clickThrough[0].uri,
            'clickThrough': vastLinear.videoClicks.clickThrough[0].uri,
            'clickTracking': vastLinear.videoClicks.clickTracking[0].uri,
            'progress': vastLinear.trackingEvents.filter(function (tracking) {
              return tracking.event == "progress"
            })[0].uri,
            'progressOffset': convertTimeStampToSeconds(vastLinear.trackingEvents.filter(function (tracking) {
              return tracking.event == "progress"
            })[0].offset),
            'progresses': vastLinear.trackingEvents.filter((tracking) => tracking.event == "progress").map((tracking) => {
              let obj = {};
              obj['url'] = tracking.uri;
              obj['offset'] = convertTimeStampToSeconds(tracking.offset);
              return obj;
            }),
            'impression': vast2json.ad[0].inLine.impression[0].uri,
            'error': vast2json.ad[0].inLine.error[0].uri
          },
          'skip': {
            'offset': convertTimeStampToSeconds(vastLinear.skipoffset),
          }
        }]
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  }

}

export default Campaign;