/**
 * 플레이어 화면 구현
 */
import * as utils from '../../../../../utils';
import SbsPlayerLogger from '../../../../../utils/logger';

customElements.define('sbs-player-ad-time', class extends HTMLElement {
  constructor() {
    super();

    this.current = '';
    this.duration = '';
  }

  set current(value) {
    this._current = value;
    this.render();
  }

  get current() {
    return this._current;
  }

  set duration(value) {
    this._duration = value;
    this.render();
  }

  get duration() {
    return this._duration;
  }

  static get observedAttributes() { return []; }

  async attributeChangedCallback(name, oldValue, newValue) {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  async connectedCallback() {
    try {
      this.render();
    } catch (error) {
      console.log(error);
    }
  }

  disconnectedCallback() {
    try {
      if (!document.body.contains(this)) {
      }
    } catch (error) {
      console.log(error);
    }
  }

  adoptedCallback() {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    try {
      this.innerHTML = `<div class="infoText-playtime">
        <strong class="infoText-timeNow">${utils.secondsToHms(this.current)}</strong><span class="infoText-timeAll"> / ${utils.secondsToHms(this.duration)}</span>
        </div>`;
      this.styles();
    } catch (error) {
      console.log(error);
    }
  }

  styles() {
    try {
    } catch (error) {
      console.log(error);
    }
  }
});