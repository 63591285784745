/**
 * 화면 구현
 */
import { SbsPlayerGlobal } from '../../common';

import './app';
import './back';
import './buy';
import './pass';
import './text';
import './unmute';

customElements.define('sbs-player-toparea', class extends HTMLElement {
  constructor() {
    super();

    this.text = null;
  }

  static get observedAttributes() { return []; }

  async attributeChangedCallback(name, oldValue, newValue) {
    try {

    } catch (error) {
      console.log(error);
    }
  }

  async connectedCallback() {
    try {
      this.render();
    } catch (error) {
      console.log(error);
    }
  }

  disconnectedCallback() {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  adoptedCallback() {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    try {
      const { info } = SbsPlayerGlobal.state.data;

      if (info === undefined) return false;
      // - VOD 3분 미리보기	초고화질 3분 미리보기 중입니다.
      // - LIVE(m) 라이브 모바일 3분 미리보기	모바일 웹에서는 3분 미리보기만 제공됩니다.
      // - LIVE(pc) 라이브 3분 미리보기	라이브 3분 미리보기 중입니다.로그인 후 일반화질로 무료 시청하세요.

      if (info.type === 'VOD3MIN') {
        if (SbsPlayerGlobal.isMobile) {
          this.text = '모바일 웹에서는 3분 미리보기만 제공됩니다.';
        } else {
          this.text = '초고화질 3분 미리보기 중입니다.';
        }
      } else if (info.onair_preview_yn === 'Y') {
        if (SbsPlayerGlobal.isMobile) {
          this.text = '모바일 웹에서는 3분 미리보기만 제공됩니다.';
        } else {
          this.text = '라이브 3분 미리보기 중입니다. 로그인 후 일반화질로 무료 시청하세요.';
        }
      }

      this.innerHTML = `<div class="playerTopArea_w">
                      <div class="playerTopArea-left">
                        ${SbsPlayerGlobal.vodtype.indexOf('ALLVOD') > -1 ? `<sbs-player-toparea-back></sbs-player-toparea-back>` : ``}
                        ${this.text ? `<sbs-player-toparea-text text="${this.text}"></sbs-player-toparea-text>` : ``}
                        <sbs-player-toparea-unmute></sbs-player-toparea-unmute>
                      </div>
                      <div class="playerTopArea-right">
                        ${SbsPlayerGlobal.isMobile ? `<sbs-player-toparea-app></sbs-player-toparea-app>` : ``}
                        ${info.type === 'VOD3MIN' ? `<sbs-player-toparea-buy></sbs-player-toparea-buy>` : ``}
                        ${info.type === 'VOD3MIN' ? `<sbs-player-toparea-pass></sbs-player-toparea-pass>` : ``}
                      </div>
                    </div>`;
      this.event();
      this.styles();
    } catch (error) {
      console.log(error);
    }
  }

  event() {
    try {
      if (!this.text) {
        // * hide_controls
        SbsPlayerGlobal.events.addEventListener('controller-hide-event', (hidden) => {
          const hideControls = this.querySelector('.playerTopArea_w');
          hidden ? hideControls.classList.add('hide_controls') : hideControls.classList.remove('hide_controls');
        });
      }

      this.querySelector('sbs-player-toparea-buy')?.addEventListener('billing-popup', (event) => {
        this.dispatchEvent(new CustomEvent('billing-popup', {
          detail: event.detail
        }));
      });
    } catch (error) {
      console.log(error);
    }
  }

  styles() {
    try {
      this.innerHTML += `<style>
        .playerADType .playerTopArea-right {
          display: none !important;
        }
        .playerTopArea_w {
          z-index: 10 !important;
        }
      </style>`;
    } catch (error) {
      console.error(error);
    }
  }
});