/**
 * 플레이어 화면 구현
 */
import episodeIcon from '../../../../../../assets/controller_episode.svg';
import { SbsPlayerGlobal } from '../../../../../common';
import SbsPlayerLogger from '../../../../../utils/logger';

// import PlayerWrapper
customElements.define('sbs-player-episode', class extends HTMLElement {
  constructor() {
    super();
    this.activated = false;
  }

  set activated(value) {
    this._activated = value;
    this.render();
  }

  get activated() {
    return this._activated;
  }

  static get observedAttributes() { return []; }

  async attributeChangedCallback(name, oldValue, newValue) {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  async connectedCallback() {
    try {
      this.render();
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    try {
      this.innerHTML = `<button type="button" class="btnIcon-viewList" aria-label="회차리스트">
            <span class="iconSvg_viewList">
              <i class="hide">회차리스트</i>
            </span>
          </button>`;
      this.event();
      this.styles();
      this.icons();
    } catch (error) {
      console.log(error);
    }
  }

  event() {
    try {
      this.querySelector('button').addEventListener('click', async (event) => {
        const episodeList = SbsPlayerGlobal.view.querySelector('sbs-player-episode-list');
        if (episodeList !== null) {
          episodeList.visible = !episodeList.visible;
        } else {
          alert('관련회차를 찾을 수 없습니다.');
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  styles() {
    try {

    } catch (error) {
      console.log(error);
    }
  }

  icons() {
    try {
      const episodeIconElement = this.querySelector('.iconSvg_viewList');
      episodeIconElement?.insertAdjacentHTML('afterbegin', episodeIcon);
    } catch (error) {
      console.log(error);
    }
  }
});