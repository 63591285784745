export * from './compatibility';
import { toFormattedDate } from '.';
import { loadCookie, saveCookie } from './cookie';
import SbsPlayerLogger from './logger';
import { getMD5 } from './md5';


/**
 * smr 요청사항, 플레이어(브라우저)의 고유 식별사용자 아이디를 조회/생성(쿠키저장)
 * - 조합식 'SMR_MEMBERS' + yyyyMMddHHmmss + 'user ip' => md5 hash 적용
 *
 * @export
 * @param {*} media
 * @param {*} ip
 * @return {*} 
 */
export function getUUID(media, ip) {
  let sbsUUIDKey = 'SBS_PLAYER_UUID';
  let savedUUID = loadCookie(sbsUUIDKey);

  if (savedUUID.length == 32) {
    return savedUUID;
  }
  else {
    let uuid = getMD5(media + toFormattedDate(new Date(), 'yyyyMMddHHmmss') + ip);
    saveCookie(sbsUUIDKey, uuid);
    return uuid;
  }
}

export function getTID(media, ip) {
  return getMD5(getUUID(media, ip) + toFormattedDate(new Date(), 'yyyyMMddHHmmss') + getRandomInteger());
}

export function getRandomInteger() {
  let randomInteger = Math.floor(Math.random() * 100000) + 10000;

  if (randomInteger > 100000) {
    randomInteger = randomInteger - 10000;
  }

  return Math.floor(randomInteger);
}