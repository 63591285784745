/**
 * 광고 화면 구현
 */
import { SbsPlayerGlobal } from '../../../../common';

customElements.define('sbs-player-ad-info-skip', class extends HTMLElement {
  constructor() {
    super();
    this.skip_able = false;
    this.count = 0;
  }

  static get observedAttributes() { return ['tracking_url', 'duration', 'skip']; }

  async attributeChangedCallback(name, oldValue, newValue) {
    try {
      if (name === 'duration' && (oldValue !== newValue)) {
        this.duration = parseInt(newValue);
      }
      if (name === 'skip' && (oldValue !== newValue)) {
        this.skip = parseInt(newValue);
        if (this.skip_able) return false;
      }
      this.render();
    } catch (error) {
      console.log(error);
    }
  }

  async connectedCallback() {
    try {
      this.render();
    } catch (error) {
      console.log(error);
    }
  }

  disconnectedCallback() {
    try {
      if (!document.body.contains(this)) {

      }
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    try {
      this.none_skip = this.skip === this.duration || this.skip === undefined || this.skip === '0' || this.skip.length === 0 || isNaN(this.skip);
      this.skip_able = this.skip <= this.current && ('0' < this.skip);

      this.innerHTML = this.none_skip ?
        `<button type="button" class="videoBtn-adNow" style="cursor: default;">광고중</button>`
        : this.skip_able ? `<button tabindex="0" type="button" class="videoBtn-adNow" style="cursor: pointer;">SKIP</button>`
          : this.skip && this.current ? `<button type="button" class="videoBtn-adNow" style="cursor: default;" aria-label="${this.count} 초 후 스킵가능"><em class="adPoint-color">${this.count}</em>초 후 SKIP</button>`
            : ``;
      this.event();
      this.styles();
    } catch (error) {
      console.log(error);
    }
  }
  event() {
    this.querySelector('.videoBtn-adNow')?.addEventListener('click', (event) => {
      event.preventDefault();
      if (event.target.innerText === 'SKIP') {
        this.dispatchEvent(new CustomEvent('ad-skip-click'));
      }
    });

    // SbsPlayerGlobal.events.addEventListener('video-change-ad', async (event) => {
    //   try {
    //     const video = event.target;
    //     if ('timeupdate' === event.type && video.currentTime && video.duration) {
    //       this.timeupdate(video.currentTime);
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   }
    // });
  }
  styles() {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  timeupdate(value) {
    this.current = parseInt(value);
    if (this.current <= this.skip) {
      this.none_skip = this.skip === this.duration || this.skip === undefined || this.skip === '0' || this.skip.length === 0;
      this.skip_able = this.skip <= this.current && ('0' < this.skip);
      this.count = parseInt(this.skip - this.current);
      if (this.skip_able || this.querySelector('.videoBtn-adNow .adPoint-color') === null) {
        this.render();
      } else if (this.none_skip) {
        this.querySelector('.videoBtn-adNow').textContent = '광고중';
      } else {
        this.querySelector('.videoBtn-adNow .adPoint-color').textContent = this.count;
      }
    }
  }
});