/**
 * 광고 화면 구현
 */
import SbsPlayerLogger from '../../../../utils/logger';

customElements.define('sbs-player-ad-info-point', class extends HTMLElement {
  constructor() {
    super();
  }

  set current(value) {
    this._current = value;
    this.render();
  }

  get current() {
    return this._current;
  }

  static get observedAttributes() { return []; }

  async attributeChangedCallback(name, oldValue, newValue) {
    try {
      this.render();
    } catch (error) {
      console.log(error);
    }
  }

  async connectedCallback() {
    try {
      this.render();
    } catch (error) {
      console.log(error);
    }
  }

  disconnectedCallback() {
    try {
      if (!document.body.contains(this)) {

      }
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    try {
      this.innerHTML = `<div class="playerADPoint-animation">
            <span class="iconSvg_point">
                <img src="https://static.cloud.sbs.co.kr/player/img/svg_point5.svg" alt="포인트 5P 적립">
            </span>
        </div>`;
      this.event();
      this.styles();
    } catch (error) {
      console.log(error);
    }
  }
  event() {
    try {
    } catch (error) {
      console.log(error);
    }
  }
  styles() {
    try {
    } catch (error) {
      console.log(error);
    }
  }
});