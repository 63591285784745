/**
 * 포워드 중앙버튼
 */
import SbsPlayerLogger from '../../../../utils/logger';

import forwardIcon from '../../../../../assets/forward.svg';

customElements.define('sbs-player-ad-center-forward', class extends HTMLElement {
  constructor() {
    super();
  }

  static get observedAttributes() { return []; }

  async attributeChangedCallback(name, oldValue, newValue) {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  async connectedCallback() {
    try {
      this.render();
    } catch (error) {
      console.log(error);
    }
  }

  disconnectedCallback() {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  adoptedCallback() {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    try {
      this.innerHTML = `<button type="button" class="videoBtnNext-10s" aria-label="10초 이후" title="10초 이후">
          <span class="iconSvg_next10s"><i class="hide">10초 이후</i></span>
        </button>`;
      this.event();
      this.styles();
      this.icons();
    } catch (error) {
      console.log(error);
    }
  }

  event() {
    // * 특정시간 동안 연타할 경우 횟수 계산하기
    this.querySelector('button').addEventListener('click', (event) => {
      this.dispatchEvent(new CustomEvent('button-click-forward', {
        detail: this.paused
      }));
    });
  }

  styles() {
    try {
      this.style.visibility = 'hidden';
    } catch (error) {
      console.log(error);
    }
  }
  icons() {
    try {
      const forwardIconElement = this.querySelector('.iconSvg_next10s');
      forwardIconElement?.insertAdjacentHTML('afterbegin', forwardIcon);
    } catch (error) {
      console.log(error);
    }
  }
});