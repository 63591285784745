/**
 * 플레이어 화면 구현
 */
import fullScreenIcon from '../../../../../../assets/controller_fullscreen.svg';
import exitFullScreenIcon from '../../../../../../assets/controller_fullscreen_exit.svg';
import SbsPlayerLogger from '../../../../../utils/logger';

customElements.define('sbs-player-fullscreen', class extends HTMLElement {
  constructor() {
    super();

    this.activated = false;
  }

  set activated(value) {
    this._activated = value;
    this.render();
  }
  get activated() {
    return this._activated;
  }

  static get observedAttributes() { return []; }

  async attributeChangedCallback(name, oldValue, newValue) {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  async connectedCallback() {
    try {
      this.render();
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    try {
      this.innerHTML = `<div class="controlToggle-fullScreen">
                    <button type="button" class="BtnToggle-fullScreen${this.activated ? ' Btn_ExitFullScreen' : ''}" aria-label="전체화면${this.activated ? ' 해제' : ''}">
                        ${this.activated ? `<span class="iconSvg_ExitFullScreen">
                          <i class="hide">전체화면 해제</i>
                        </span>` : `<span class="iconSvg_fullScreen">
                        <i class="hide">전체화면</i></span>`}
                    </button>
                </div>`;
      this.event();
      this.styles();
      this.icons();
    } catch (error) {
      console.log(error);
    }
  }

  event() {
    try {
      this.querySelector('button').addEventListener('click', (event) => {
        this.dispatchEvent(new CustomEvent('button-toggle-fullscreen', { detail: this.activated }));
      });
    } catch (error) {
      console.log(error);
    }
  }

  styles() {
    try {

    } catch (error) {
      console.log(error);
    }
  }
  icons() {
    try {
      const exitFullScreenIconElement = this.querySelector('.iconSvg_ExitFullScreen');
      exitFullScreenIconElement?.insertAdjacentHTML('afterbegin', exitFullScreenIcon);

      const fullScreenIconElement = this.querySelector('.iconSvg_fullScreen');
      fullScreenIconElement?.insertAdjacentHTML('afterbegin', fullScreenIcon);
    } catch (error) {
      console.log(error);
    }
  }
});