import { SbsPlayerGlobal } from '../common';
import { isIOS } from '../utils/navigator';
import SbsPlayerLogger from '../utils/logger';

/**
 * 
 * - 
 */
export class FullScreen {
  constructor(props) {
    this.props = props;
    this.activated = false;
    this.init();
  }

  init() {
    try {
      this.event();
    } catch (error) {
      console.log(error);
    }
  }

  event() {
    try {
      let self = this;
      document.addEventListener("fullscreenchange", function (event) {
        self.activated = document.fullscreenElement;
        let adfullscreen = self.props.querySelector('sbs-player-ad-fullscreen');
        if (adfullscreen) adfullscreen.activated = self.activated;
        let fullscreen = self.props.querySelector('sbs-player-fullscreen');
        if (fullscreen) fullscreen.activated = self.activated;
      });
    } catch (error) {
      console.log(error);
    }
  }

  toggle() {
    try {
      const ua = navigator.userAgent;
      // ! shadow dom 하위 퍼블영역을 타켓으로 잡아야함 
      //const target = this.props;
      this.$ad = SbsPlayerGlobal.view.querySelector('.playerWrap sbs-player-ad-wrapper');
      this.$player = SbsPlayerGlobal.view.querySelector('.playerWrap sbs-player-wrapper');
      const adVideo = this.$ad.querySelector('video');
      const playerVideo = this.$player.querySelector('video');
      const isAdsExposed = SbsPlayerGlobal.view.querySelector('.playerWrap').classList.contains('playerADType');
      // iOS 광고플레이어에선 풀스크린을 막던지 해야함
      const target = isIOS() ? isAdsExposed ? adVideo : playerVideo : SbsPlayerGlobal.view.querySelector('.playerWrap');
      if (ua.indexOf('Trident/') !== -1 && parseFloat(ua.slice(ua.indexOf('Trident/') + 8)) <= 6.0) {
        return alert('해당 브라우져에서는 지원되지 않습니다.');
        //this.parentElement.innerHTML = `<sbs-player-warning title="영상시청안내" html="해당 브라우져에서는 지원되지 않습니다."></sbs-player-warning>`;
      }
      // * fullscreen toggle
      this.activated ?
        document.exitFullscreen ? document.exitFullscreen() :
          document.mozExitFullscreen ? document.mozExitFullscreen() :
            document.webkitExitFullscreen ? document.webkitExitFullscreen() :
              document.msFullscreenElement ? document.msExitFullscreen() :
                document.fullscreenElement ? document.cancelFullScreen() :
                  document.mozFullScreenElement ? document.mozCancelFullScreen() :
                    document.webkitFullscreenElement && document.webkitCancelFullScreen() :
        target.requestFullscreen ? target.requestFullscreen() :
          target.mozRequestFullScreen ? target.mozRequestFullScreen() :
            target.webkitRequestFullscreen ? target.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT) :
              target.webkitEnterFullscreen ? target.webkitEnterFullscreen() :
                target.msRequestFullscreen ? target.msRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT) :
                  target.msEnterFullscreen && target.msEnterFullscreen();

      this.activated = !this.activated;
    } catch (error) {
      console.log(error);
    }
  }
}
