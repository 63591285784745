/**
 * 플레이어 화면 구현
 */
import { SbsPlayerGlobal } from '../../../../common';
import SbsPlayerLogger from '../../../../utils/logger';
import './desktop';
import './mobile';

customElements.define('sbs-player-video-motion', class extends HTMLElement {
  constructor() {
    super();
    this.motion = null;
    this.count = null;
  }

  static get observedAttributes() { return ['motion', 'count']; }

  async attributeChangedCallback(name, oldValue, newValue) {
    try {
      if (name === 'motion') {
        this.motion = newValue;
      }
      if (name === 'count' && (oldValue !== newValue)) {
        this.count = newValue;
      }
      this.attributes();
    } catch (error) {
      console.log(error);
    }
  }

  async connectedCallback() {
    try {
      this.render();
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    try {
      this.innerHTML = `<div class="playerVideoMotion" style="display: block;">
          ${SbsPlayerGlobal.isMobile && SbsPlayerGlobal.infoType !== 'onair' ? `<sbs-player-video-mobile-motion motion=${this.motion} count=${this.count}></sbs-player-video-mobile-motion>` : `<sbs-player-video-desktop-motion motion=${this.motion} count=${this.count}></sbs-player-video-desktop-motion>`}
        </div>`;
      this.events();
    } catch (error) {
      console.log(error);
    }
  }
  attributes() {
    const motionElement = this.querySelector(SbsPlayerGlobal.isMobile ? 'sbs-player-video-mobile-motion' : 'sbs-player-video-desktop-motion');
    motionElement?.setAttribute('motion', this.motion);
    motionElement?.setAttribute('count', this.count);

  }
  events() {
    try {
      const motionElement = this.querySelector(SbsPlayerGlobal.isMobile ? 'sbs-player-video-mobile-motion' : 'sbs-player-video-desktop-motion');
      motionElement.addEventListener('motion-current', (event) => {
        this.dispatchEvent(new CustomEvent('motion-current', { detail: event.detail }));
      });


    } catch (error) {
      console.log(error);
    }
  }
});