/**
 * 화면 구현
 */
import { SbsPlayerGlobal } from '../../common';

customElements.define('sbs-player-toparea-text', class extends HTMLElement {
  constructor() {
    super();
    this.text = '';
  }

  static get observedAttributes() { return ['text', 'ad']; }

  async attributeChangedCallback(name, oldValue, newValue) {
    try {
      if (name === 'text' && (oldValue !== newValue)) {
        this.text = newValue;
      }
      if (name === 'ad' && (oldValue !== newValue)) {
        this.ad = newValue;
      }
      this.render();
    } catch (error) {
      console.log(error);
    }
  }

  async connectedCallback() {
    try {
      this.render();
    } catch (error) {
      console.log(error);
    }
  }

  disconnectedCallback() {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  adoptedCallback() {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    try {
      this.innerHTML = `${this.text && this.text !== 'undefined' ? `<p class="playerTopArea-Text ${this.ad ? 'ad' : ''}">${this.text}</p>` : ``}`;

      this.styles();
    } catch (error) {
      console.log(error);
    }
  }

  styles() {
    try {
      // playerADType 여부에 따라 
      this.innerHTML += `<style>
        .playerADType .playerTopArea-Text {
          display: none;
        }
        .playerADType .playerTopArea-Text.ad {
          display: block;
        }
      </style>`;
    } catch (error) {
      console.error(error);
    }
  }
});