import SbsPlayerLogger from './logger';

export function loadCookie(key) {
  try {
    let rawName = key + '=';
    let rawCookies = document.cookie.split(';');

    for (let index = 0; index < rawCookies.length; index++) {
      let rawCookie = rawCookies[index];

      while (rawCookie.charAt(0) === ' ') {
        rawCookie = rawCookie.substring(1);
      }

      if (rawCookie.indexOf(rawName) === 0) {
        return decodeURIComponent(rawCookie.substring(rawName.length, rawCookie.length));
      }
    }

    return '';
  }
  catch (error) {
    console.log(error);
  }
}

export function saveCookie(key, data, milliseconds) {
  try {
    let date = new Date();
    date.setTime(date.getTime() + (milliseconds !== undefined ? milliseconds : (10 * 365 * 24 * 60 * 60 * 1000)));

    let expires = 'expires=' + date.toUTCString();

    document.cookie = key + '=' + encodeURIComponent(data) + '; ' + expires + `;domain=${window.location.hostname.indexOf('.sbs.co.kr') > -1 ? '.sbs.co.kr' : window.location.hostname};path=/`;

    console.log(key + '=' + encodeURIComponent(data) + '; ' + (milliseconds > 0 ? expires : '') + `;domain=${window.location.hostname.indexOf('.sbs.co.kr') > -1 ? '.sbs.co.kr' : window.location.hostname};path=/`);
  }
  catch (error) {
    console.log(error);
  }
}


export function removeCookie(key) {
  try {
    document.cookie = key + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=.sbs.co.kr; path=/';
  }
  catch (error) {
    console.log(error);
  }
}
