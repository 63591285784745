import { SbsPlayerGlobal } from '../../../common';
import SbsPlayerLogger from '../../../utils/logger';

class VodMidroll {
  constructor() {
    this.midroll_adbreak_points = []; // midroll ad sequence number(ad-markers)
    this._event = {
      initialized: () => {
        console.log(`initialized.`);
      },
      adStarted: () => {
        console.log(`adStarted.`);
      },
      adEnded: () => {
        console.log(`adEnded.`);
      }
    };
  }

  set event(event) {
    this._event = event;
  }

  get event() {
    return this._event;
  }

  init(videoDuration) {
    try {
      const { ad } = SbsPlayerGlobal.state.data;
      // 15분, 30분, 45분 에서만 호출하도록 조건(length < 3) 추가

      console.log('midroll_ad_repeat_time', ad.midroll_ad_repeat_time, this.midroll_adbreak_points.length);

      for (let i = 1; i * ad.midroll_ad_repeat_time < videoDuration && this.midroll_adbreak_points.length < 3; i++) {
        this.midroll_adbreak_points.push(i * ad.midroll_ad_repeat_time);
      }

      SbsPlayerGlobal.events.addEventListener('video-change-player', (event) => {
        try {
          const video = event.target;
          if ('timeupdate' === event.type) {
            //console.log(`${video.currentTime}, ${this.midroll_adbreak_points}`);
            // TODO: 중간광고 adbreakpoint, marker 처리, 900초 간격으로 (15/30/45/60/75/...분)에서 중간광고 실행
            if (ad.midroll_ad_sequence.length > 0 && this.midroll_adbreak_points.includes(parseInt(video.currentTime))) {
              video.pause();
              // * breakpoint 도달시 [900, 1800, 2700] 배열에서 900 제거
              this.midroll_adbreak_points = this.midroll_adbreak_points.filter(item => item !== parseInt(video.currentTime));
              console.log('midroll_adbreak_points []', this.midroll_adbreak_points.length, this.midroll_adbreak_points);

              this._event.adStarted();
            }
          }
        } catch (error) {
          console.log(error);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }
}

export default VodMidroll;