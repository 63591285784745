/**
 * 플레이어 화면 구현
 */
import liveCaptionIcon from '../../../../../../assets/controller_live_caption.svg';
import { SbsPlayerGlobal } from '../../../../../common';
import SbsPlayerLogger from '../../../../../utils/logger';

customElements.define('sbs-player-live-caption', class extends HTMLElement {
  constructor() {
    super();

    this.activated = false;
  }

  set activated(value) {
    this._activated = value;
    this.render();
  }
  get activated() {
    return this._activated;
  }

  static get observedAttributes() { return []; }

  async attributeChangedCallback(name, oldValue, newValue) {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  async connectedCallback() {
    try {
      this.render();
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    try {
      if (SbsPlayerGlobal.infoType.indexOf('onair') === -1) {
        return;
      }
      this.innerHTML = `<div class="controlToggle-cc">
                    <button type="button" class="BtnToggle-cc${this.activated ? ' cc-on' : ''}" aria-label="자막 ${this.activated ? '끄기' : '켜기'}">
                      <span class="iconSvg_cc">
                        <i class="hide">close caption</i>
                      </span>
                      <span class="iconSvg_on">
                        <i class="hide">on</i>
                      </span>
                    </button>
                </div>`;
      this.event();
      this.styles();
      this.icons();
    } catch (error) {
      console.log(error);
    }
  }

  event() {
    try {
      this.querySelector('button').addEventListener('click', (event) => {
        this.activated = !this.activated;

        this.dispatchEvent(new CustomEvent('button-toggle-live-caption', { detail: this.activated }));
      });
    } catch (error) {
      console.log(error);
    }
  }

  styles() {
    try {

    } catch (error) {
      console.log(error);
    }
  }
  icons() {
    try {
      const iconElement = this.querySelector('.iconSvg_cc');
      iconElement?.insertAdjacentHTML('afterbegin', liveCaptionIcon);
    } catch (error) {
      console.log(error);
    }
  }
});