/**
 * 플레이어 화면 구현
 */
import SbsPlayerLogger from '../../../../../utils/logger';

customElements.define('sbs-player-live-divider', class extends HTMLElement {
  constructor() {
    super();
  }

  static get observedAttributes() { return []; }

  async attributeChangedCallback(name, oldValue, newValue) {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  async connectedCallback() {
    try {
      this.render();
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    try {
      this.innerHTML = `<div class="playerLiveSeekLine"></div>`;
    } catch (error) {
      console.log(error);
    }
  }
});