/**
 * 플레이어 화면 구현
 */
import pauseIcon from '../../../../../../assets/controller_pause.svg';
import playIcon from '../../../../../../assets/controller_play.svg';
import SbsPlayerLogger from '../../../../../utils/logger';

customElements.define('sbs-player-playpause', class extends HTMLElement {
  constructor() {
    super();
    this.paused = true;
    this.visible = true;
  }

  set visible(value) {
    this._visible = value;
    this.render();
  }

  get visible() {
    return this._visible;
  }

  set paused(value) {
    this._paused = value;
    this.render();
  }

  get paused() {
    return this._paused;
  }

  static get observedAttributes() { return ['']; }

  async attributeChangedCallback(name, oldValue, newValue) {
    try {

    } catch (error) {
      console.log(error);
    }
  }

  async connectedCallback() {
    try {
      this.render();
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    try {
      // ? 헷갈린다
      this.innerHTML = `<div class="controlToggle-play" style="display: ${this.visible ? 'block' : 'none'}">
        <button type="button" class="BtnToggle-play ${this.paused ? '' : 'BtnToggle-pause'}" aria-label="${this.paused ? '재생' : '일시정지'}" data-state="${this.paused}">
          ${this.paused ? `<span class="iconSvg_play"><i class="hide">재생</i></span>` : `<span class="iconSvg_pause"><i class="hide">일시정지</i></span>`}
        </button>
      </div>`;
      this.event();
      this.styles();
      this.icons();
    } catch (error) {
      console.log(error);
    }
  }

  event() {
    try {
      this.querySelector('button')?.addEventListener('click', (event) => {
        this.dispatchEvent(new CustomEvent('button-toggle-playpause', { detail: this.paused }));
      });
    } catch (error) {
      console.log(error);
    }
  }
  styles() {
    try {

    } catch (error) {
      console.log(error);
    }
  }
  icons() {
    try {
      const pauseIconElement = this.querySelector('.iconSvg_pause');
      pauseIconElement?.insertAdjacentHTML('afterbegin', pauseIcon);

      const playIconElement = this.querySelector('.iconSvg_play');
      playIconElement?.insertAdjacentHTML('afterbegin', playIcon);
    } catch (error) {
      console.log(error);
    }
  }
});