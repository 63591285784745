/**
 * 화면 구현
 */
import { SbsPlayerGlobal } from '../../common';
import SbsPlayerLogger from '../../utils/logger';

customElements.define('sbs-player-dialog-age-19-type', class extends HTMLElement {
  constructor() {
    super();
    this.visible = false;
    this.icon = ``;
    this.html = ``;
  }

  set visible(value) {
    this._visible = value;
    this.render();
  }

  get visible() {
    return this._visible;
  }

  static get observedAttributes() { return ['icon', 'html']; }

  async attributeChangedCallback(name, oldValue, newValue) {
    try {
      if (name === 'icon' && (oldValue !== newValue)) {
        this.icon = newValue;
      }
      if (name === 'html' && (oldValue !== newValue)) {
        this.html = newValue;
      }
    } catch (error) {
      console.log(error);
    }
  }

  async connectedCallback() {
    try {
      this.render();
    } catch (error) {
      console.log(error);
    }
  }

  disconnectedCallback() {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  adoptedCallback() {
    try {
    } catch (error) {
      console.log(error);
    }
  }


  render() {
    try {
      this.innerHTML = `<div class="playerDialogAge19_w current" role="dialog">
        <div class="playerDialogContents">
            <div class="age19_cont">
                ${this.icon}
                ${this.html}
            </div>
        </div>
    </div>`;
      this.styles();
      this.event();
    } catch (error) {
      console.log(error);
    }
  }

  styles() {
    try {
      if (SbsPlayerGlobal.isMobile) this.style.paddingTop = `56.2%`;
    } catch (error) {
      console.log(error);
    }
  }

  event() {
    try {
      // ! 공통 모듈형태로 분리하기 어려운 구조임 일단 보류
      const loginLinkElement = this.querySelectorAll('a[class^="btn_login"]');
      for (let i = 0; i < loginLinkElement.length; i++) {
        loginLinkElement[i].addEventListener("click", (e) => {
          window.open(`https://join${SbsPlayerGlobal.development ? '.developers' : ''}.sbs.co.kr/login/login.do?div=pc_login&returnUrl=${encodeURIComponent(location.href)}`);
        });
      }

      const registLinkElement = this.querySelectorAll('a[class^="btn_registInfo"]');
      for (let i = 0; i < registLinkElement.length; i++) {
        registLinkElement[i].addEventListener("click", (e) => {
          window.open(`https://join${SbsPlayerGlobal.development ? '.developers' : ''}.sbs.co.kr/join/join_info_form.do?div=pc_login&returnUrl=${encodeURIComponent(location.href)}`, '개인정보 등록', 'width=426px, height=662px, scrollbars=no, location=no, resizable=no, toolbars=no, menubar=no, status=no');
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
});