/**
 * 콘텍스트메뉴 대체
 */
import SbsPlayerLogger from '../../../../utils/logger';

import { SbsPlayerGlobal } from '../../../../common';
import './backward';
import './forward';
import './playpause';

customElements.define('sbs-player-ad-center-buttons', class extends HTMLElement {
  constructor() {
    super();
    this.visible = true;
    this.ended = false;
  }

  set visible(value) {
    this._visible = value;
    this.render();
  }

  get visible() {
    return this._visible;
  }

  static get observedAttributes() { return []; }

  async attributeChangedCallback(name, oldValue, newValue) {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  async connectedCallback() {
    try {
      this.render();
    } catch (error) {
      console.log(error);
    }
  }

  disconnectedCallback() {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  adoptedCallback() {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    try {
      if (this.position === 'live_midroll') return false;
      this.innerHTML = `<div class="playerVideoBtns" style="display: ${this.visible ? 'flex' : 'none'} align-items: center;justify-content: center;">
          <sbs-player-ad-center-backward></sbs-player-ad-center-backward>
          <sbs-player-ad-center-playpause paused="true"></sbs-player-ad-center-playpause>
          <sbs-player-ad-center-forward></sbs-player-ad-center-forward>     
      </div>`;
      this.event();
    } catch (error) {
      console.log(error);
    }
  }

  event() {
    const loading = SbsPlayerGlobal.view.querySelector('sbs-player-loading');
    const playpause = this.querySelector('sbs-player-ad-center-playpause');

    playpause.setAttribute('visible', 'false');

    SbsPlayerGlobal.events.addEventListener('ima-ad-event', (type) => {
      try {
        switch (type) {
          case 'LOADED':
            loading.visible = true;
            break;
          case 'STARTED':
          case 'RESUMED':
            playpause.setAttribute('visible', 'true');
            playpause.setAttribute('paused', 'false');
            SbsPlayerGlobal.view.querySelector('.playerWrap').classList.add('bottomDim-hide');
            break;
          case 'PAUSED':
            playpause.setAttribute('visible', 'true');
            playpause.setAttribute('paused', 'true');
            break;
          case 'ALL_ADS_COMPLETED':
            SbsPlayerGlobal.view.querySelector('.playerWrap').classList.remove('bottomDim-hide');
            break;
          default:
            loading.visible = false;
            playpause.setAttribute('visible', 'false');
            break;
        }

      } catch (error) {
        console.log(error);
      }
    });

    SbsPlayerGlobal.events.addEventListener('video-change-ad', (event) => {
      try {
        switch (event.type) {
          case 'play':
            playpause.setAttribute('visible', 'true');
            playpause.setAttribute('paused', 'false');
            setTimeout(() => {
              SbsPlayerGlobal.events.emitEvent('controller-hide-event', true);
            }, SbsPlayerGlobal.options.hide_contorls_seconds);
            break;
          case 'pause':
            playpause.setAttribute('paused', 'true');
            SbsPlayerGlobal.events.emitEvent('controller-hide-event', false);
            break;
          case 'ended':
            loading.visible = false;
            playpause.setAttribute('visible', 'false');
            break;
          case 'canplay':
            loading.visible = false;
            playpause.setAttribute('visible', 'true');
            break;
          case 'waiting':
            loading.visible = true;
            playpause.setAttribute('visible', 'false');
            break;
          default:
            break;
        }
      } catch (error) {
        console.log(error);
      }
    });

    const elements = [
      { element: playpause, event: 'button-toggle-playpause' },
    ];

    for (const { element, event } of elements) {
      if (!element) continue;
      element.addEventListener(event, (e) => {
        SbsPlayerGlobal.events.emitEvent('button-toggle-playpause', e.detail);
        this.dispatchEvent(new CustomEvent(event, { detail: e.detail }));
      });
    }

    SbsPlayerGlobal.events.addEventListener('controller-hide-event', (hidden) => {
      const hideControls = this.querySelector('.playerVideoBtns');
      hidden ? hideControls.classList.add('hide_controls') : hideControls.classList.remove('hide_controls');
    });
    SbsPlayerGlobal.events.addEventListener('center-hide-event', (hidden) => {
      const hideControls = this.querySelector('.playerVideoBtns');
      hidden ? hideControls.style.display = 'none' : hideControls.style.display = 'flex';
    });
  }
});