/**
 * 플레이어 화면 구현
 */
import * as utils from '../../../../../utils';
import SbsPlayerLogger from '../../../../../utils/logger';
import { isMobile } from '../../../../../utils/navigator';
import { SbsPlayerGlobal } from '../../../../../common';

customElements.define('sbs-player-ad-sequence', class extends HTMLElement {
  constructor() {
    super();
    this.text = '';
  }

  static get observedAttributes() { return ['text']; }

  async attributeChangedCallback(name, oldValue, newValue) {
    try {
      if (name === 'text' && (oldValue !== newValue)) {
        this.text = newValue;
        this.render();
      }
    } catch (error) {
      console.log(error);
    }
  }

  async connectedCallback() {
    try {
      this.render();
    } catch (error) {
      console.log(error);
    }
  }

  disconnectedCallback() {
    try {
      if (!document.body.contains(this)) {
      }
    } catch (error) {
      console.log(error);
    }
  }

  adoptedCallback() {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    try {
      if (SbsPlayerGlobal.isMobile) return false;
      this.innerHTML = `<div class="infoText-ad">AD ${this.text}</div>`;
      this.styles();
    } catch (error) {
      console.log(error);
    }
  }

  styles() {
    if (this.text.length > 0 && this.text !== '1/1') {
      this.querySelector('.infoText-ad').style.display = 'flex';
      this.querySelector('.infoText-ad').style.color = 'white';
      this.querySelector('.infoText-ad').style.zIndex = '999';

    }
  }
});