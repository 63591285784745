import { SbsPlayerGlobal } from '../../../common';
import { importScript } from '../../../utils';
import { loadCookie } from '../../../utils/cookie';
import SbsPlayerLogger from '../../../utils/logger';

class SbsAdServer {
  constructor() {
    this.position = '';
    this.uuid = '';
    this.tid = '';
    this.url = '';
    this._data = {};
    this._event = {
      initialized: () => {
        console.log(`initialized.`);
      },
      succeeded: () => {
        console.log(`succeeded.`);
      },
      waiting: () => {
        console.log(`waiting.`);
      },
      failed: () => {
        console.log(`failed.`);
      },
      passed: () => {
        console.log(`passed.`);
      },
      errored: () => {
        console.log(`errored.`);
      }
    };
  }

  get data() {
    return this._data;
  }

  set data(data) {
    return this._data = data;
  }

  get event() {
    return this._event;
  }

  set event(event) {
    return this._event = event;
  }

  initialize(position) {
    try {
      this.position = position;
      let url = this._data[this.position].link;
      if (url) {
        this.url = new URL(url);
        this.url.protocol = SbsPlayerGlobal.development ? 'http:' : 'https:';

        let uuid = this.uuid;
        let tid = this.tid;
        let cmdata = this._data.cmparam || this._data.cmdata;
        let params = new URLSearchParams(cmdata);
        params.set('x.tid', tid);
        params.set('x.playertype', 'HTML5');
        params.set('d.ifa', uuid);
        params.set('d.model', 'ETC');
        params.set('u.gender', loadCookie('SBS_SeX') === '' ? 'O' : loadCookie('SBS_SeX') === '0' ? 'F' : 'M');
        params.set('u.g.region', '98');
        params.set('u.x.job', 'ETC');
        params.set('u.x.age', loadCookie('SBS_aGe') === '' ? '-1' : loadCookie('SBS_SeX'));
        params.set('d.lmt', '0');
        params.set('i.v.startdelay', position === 'midroll' ? '-1' : '0');
        this.url = this.url + '?' + params.toString();
        this._event.initialized();
      } else {
        this._event.passed();
      }


    } catch (error) {
      console.log(error);
      this._event.errored();
    }
  }

  requestAd() {
    try {
      let self = this;
      console.log(this.position, this.url);
      fetch(this.url)
        .then(response => {
          if (response.status === 200) {
            return response.text();
          } else {
            self._event.failed(response.status);
          }
        })
        .then(responseText => {
          let parser = new DOMParser();
          let xmlDoc = parser.parseFromString(responseText.replace(/\r/g, "").replace(/\n/g, ""), "text/xml");

          if (typeof VASTParser === 'undefined') {
            importScript(SbsPlayerGlobal.url.lib.vast_parser, () => {
              let campaign = VASTParser.parseFromXML(xmlDoc);
              self._event.succeeded(campaign);
            });
          } else {
            let campaign = VASTParser.parseFromXML(xmlDoc);
            self._event.succeeded(campaign);
          }
        })
        .catch(() => {
          this._event.errored();
        });
    } catch (error) {
      console.log(error);
      this._event.errored(error);
    }
  }
}

export default SbsAdServer;