import playIcon from '../../../assets/play.svg';
import { SbsPlayerGlobal } from '../../common';
import SbsPlayerLogger from '../../utils/logger';

/**
 * 최초 인트로 화면 구현
 */
customElements.define('sbs-player-intro', class extends HTMLElement {
  constructor() {
    super();

    this.state = {
      image: '',
      title: '',
      clicked: false
    };
  }

  static get observedAttributes () { return ['type', 'image', 'title']; }

  async attributeChangedCallback (name, oldValue, newValue) {
    try {
      if (name === 'type') {
      }
      if (name === 'image' && (oldValue !== newValue)) {
        newValue = newValue.replace('http://', 'https://');
        this.state.image = newValue;
      }

      if (name === 'title' && (oldValue !== newValue)) {
        this.state.title = newValue;
      }

    } catch (error) {
      console.log(error);
    }
  }

  async connectedCallback () {
    try {
      this.render();
    } catch (error) {
      console.log(error);
    }
  }

  disconnectedCallback () {
    try {
      this.clickEventListener = null;
      this.keyEventListener = null;
      console.log('remove listener');
    } catch (error) {
      console.log(error);
    }
  }

  adoptedCallback () {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  render () {
    try {
      // * 
      let text = null;
      if (SbsPlayerGlobal.isMobile) {
        if (SbsPlayerGlobal.infoType === 'onair' || SbsPlayerGlobal.infoType === 'vod') {
          text = '모바일 웹에서는 3분 미리보기만 제공됩니다.';
        } else {
          text = 'SBS앱에서 초고화질로 시청하세요.';
        }
      }
      if (SbsPlayerGlobal.src) {
        text = null;
      }

      this.style.width = "100%";
      this.innerHTML = `<div style="width: 100%; height: 100%; cursor: pointer; aria-label="${this.state.title || '플레이어'}">
        ${text ? `<div class="playerTopArea_w">
          <div class="playerTopArea-left">
            <p class="playerTopArea-Text">${text}</p>
          </div>
          <div class="playerTopArea-right">
            <sbs-player-toparea-app></sbs-player-toparea-app>
          </div>
        </div>` : ``} 
        <div class="playerVideo_w" style="cursor: pointer;">
          <img src="${this.state.image}" loading="lazy" alt="${this.state.title || '플레이어'}" style="position: absolute; display: block; width: 100%;top: 50%;left: 50%; transform: translate(-50%, -50%);"/>
          <div class="playerVideoBtns" style="align-items: center;justify-content: center;">
            <button type="button" class="videoBtnToggle-play" aria-label="재생">
              <span class="iconSvg_play">
                <i class="hide">재생</i>
              </span>
            </button>
          </div>
        </div>`;
      this.event();
      this.icons();
    } catch (error) {
      console.log(error);
    }
  }
  icons () {
    try {
      const playIconElement = this.querySelector('.iconSvg_play');
      playIconElement?.insertAdjacentHTML('afterbegin', playIcon);
    } catch (error) {
      console.log(error);
    }
  }
  event () {
    // FIXME: 현재 css 유지하면서 예외처리 중
    if (this.offsetHeight === 0) {
      this.style.height = "100%"; // pc.
    }
    this.addEventListener('click', (event) => {
      if (event.target.tagName !== 'A' && !this.state.clicked) {
        this.state.clicked = true;
        this.dispatchEvent(new CustomEvent('button-click-intro', {
          detail: event.target.value
        }));
      }
    });

    const tag = document.activeElement.tagName.toUpperCase();
    const editable = document.activeElement.getAttribute('contenteditable');
    document.addEventListener('keydown', (event) => {
      try {
        if (
          tag !== 'INPUT' &&
          tag !== 'TEXTAREA' &&
          editable !== '' &&
          editable !== 'true' &&
          (event.key === ' ' || event.keyCode === 32) &&
          event.target.tagName !== 'VIDEO' &&
          !this.state.clicked
        ) {
          this.state.clicked = true;
          this.dispatchEvent(new CustomEvent('button-click-intro'));
        }
      } catch (error) {
        console.log(error);
      }
    });
  }
});