/**
 * 화면 구현
 */

customElements.define('sbs-player-ad-top-text', class extends HTMLElement {
  constructor() {
    super();
    this.text = '';
  }

  static get observedAttributes() { return ['text']; }

  async attributeChangedCallback(name, oldValue, newValue) {
    try {
      if (name === 'text' && (oldValue !== newValue)) {
        this.text = newValue === 'undefined' ? undefined : newValue;
      }
      this.render();
    } catch (error) {
      console.log(error);
    }
  }

  async connectedCallback() {
    try {
      this.render();
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    try {
      this.innerHTML = `${this.text ? `<div class="playerTopArea_w">
        <div class="playerTopArea-left">
          <p class="playerTopArea-Text ad">다음프로그램: ${this.text}</p>
        </div>
      </div>` : ``}`;

      this.styles();
    } catch (error) {
      console.log(error);
    }
  }

  styles() {
    try {
      // playerADType 여부에 따라 
      this.innerHTML += `<style>
        .playerADType .playerTopArea-Text {
          display: none;
        }
        .playerADType .playerTopArea-Text.ad {
          display: block;
        }
      </style>`;
    } catch (error) {
      console.error(error);
    }
  }
});