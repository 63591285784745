/**
 * 플레이어 화면 구현
 */
import { SbsPlayerGlobal } from '../../../../../common';
import SbsPlayerLogger from '../../../../../utils/logger';

import liveDVRIcon from '../../../../../../assets/controller_live_dvr.svg';
customElements.define('sbs-player-live-dvr', class extends HTMLElement {
  constructor() {
    super();

    this.activated = false;
  }

  set activated(value) {
    this._activated = value;
    this.render();
  }
  get activated() {
    return this._activated;
  }

  static get observedAttributes() { return []; }

  async attributeChangedCallback(name, oldValue, newValue) {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  async connectedCallback() {
    try {
      this.render();
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    try {
      if (SbsPlayerGlobal.infoType.indexOf('onair') === -1) {
        return;
      }
      this.innerHTML = `<div class="controlToggle-timeMachine">
                    <button type="button" class="BtnToggle-timeMachine${this.activated ? ' timeMachine-on' : ''}" aria-label="타임머신 ${this.activated ? '끄기' : '켜기'}">
                      <span class="iconSvg_timeMachine">
                        <i class="hide">타임머신</i>
                      </span>
                      <span class="iconSvg_on">
                        <i class="hide">on</i>
                      </span>
                    </button>
                </div>`;
      this.event();
      this.styles();
      this.icons();
    } catch (error) {
      console.log(error);
    }
  }

  event() {
    try {
      this.querySelector('button').addEventListener('click', (event) => {
        this.activated = !this.activated;

        this.dispatchEvent(new CustomEvent('button-toggle-live-dvr', { detail: this.activated }));
      });
    } catch (error) {
      console.log(error);
    }
  }

  styles() {
    try {

    } catch (error) {
      console.log(error);
    }
  }
  icons() {
    try {
      const iconElement = this.querySelector('.iconSvg_timeMachine');
      iconElement?.insertAdjacentHTML('afterbegin', liveDVRIcon);
    } catch (error) {
      console.log(error);
    }
  }
});