import { SbsPlayerGlobal } from '../../common';
import { getTID, getUUID } from '../../utils/advertisements';
import SbsPlayerLogger from '../../utils/logger';

class RadioLog {
  constructor() {
    this.url = null;
    this.uuid = null;
    this.tid = null;
    this.channel = null;
    this.interval = null;
    this.timeout = null;
    this.count = 1;

    this._event = {
      initialized: () => {
        console.log(`initialized.`);
      },
      timeout: (result) => {
        console.log(`passed. ${result}`);
      },
      interval: (message) => {
        console.log(`failed. ${message}`);
      },
      errored: () => {
        console.log(`errored.`);
      },
    };
  }

  get event() {
    return this._event;
  }
  set event(event) {
    this._event = event;
  }

  init(url) {
    try {

      // * ad_25, ad_30
      const { info, ad, ad_25, ad_30 } = SbsPlayerGlobal.state.data;
      const smrAdVersion = (ad.smr_ad_version || '3.0').replace(/\./g, "");
      const ad_smr = smrAdVersion === '25' ? ad_25 : ad_30;

      this.url = url;
      this.uuid = getUUID(ad_smr);
      this.tid = getTID(ad_smr);

      switch (info.channelid) {
        case 'S07':
          this.channel = 'RA01';
          break;
        case 'S17':
          this.channel = 'RA11';
          break;
        case 'S08':
          this.channel = 'RA01';
          break;
        case 'S18':
          this.channel = 'RA01';
          break;
        case 'S19':
          this.channel = 'RA03';
          break;
        default:
          break;
      }

      if (this.url && this.uuid && this.tid && this.channel) {
        console.log(this.url, this.uuid, this.tid, this.channel);

        this._event.initialized();
      }
    } catch (error) {
      this._event.errored();
    }

  }

  setTimeout(seconds) {
    try {
      this.timout = setTimeout(() => {
        this._event.timeout();
      }, seconds * 1_000);
    } catch (error) {
      console.log(error);
      this._event.errored();
    }
  }

  setInterval(seconds) {
    try {
      this.interval = setInterval(() => {
        this._event.interval();
      }, seconds * 1_000);
    } catch (error) {
      console.log(error);
      this._event.errored();
    }
  }

  sendActionLog() {
    try {
      const { info } = SbsPlayerGlobal.state.data;

      fetch(this.url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          version: '1.3',
          uuid: this.uuid,
          tid: this.tid,
          agree: 'N',
          telco: '',
          model: '',
          device: 'PW',
          os: (navigator.userAgent.indexOf('Mac') > -1) ? 'Macintosh' : 'Windows',
          type: 'AT',
          category: 'RA',
          channel: this.channel,
          program: info.smr_programid,
          behavior: 'ON'
        })
      }).catch(function (err) {
        console.error(err);
        this._event.errored();
      });
    } catch (error) {
      console.log(error);
    }
  }
  /**
  최초 0, 1(timeout), 이후 5분단위로 interval 하게 청취로그 전송
  */
  sendListenLog(minute) {
    try {
      const { info } = SbsPlayerGlobal.state.data;

      fetch(this.url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          version: '1.3',
          uuid: this.uuid,
          tid: this.tid,
          agree: 'N',
          telco: '',
          model: '',
          device: 'PW',
          os: (navigator.userAgent.indexOf('Mac') > -1) ? 'Macintosh' : 'Windows',
          type: 'LS',
          category: 'RA',
          channel: this.channel,
          program: info.smr_programid,
          duration: (minute === 0 || minute === 1 ? minute : ((this.count - 2) * 5)).toString()
        })
      }).then(() => {
        this.count++;
      }).catch(function (err) {
        console.error(err);
        this._event.errored();
      });
    } catch (error) {
      console.log(error);
    }
  }

  clearInterval() {
    try {
      clearInterval(this.interval);
      this.interval = null;
    } catch (error) {
      console.log(error);
    }
  }

  clearTimeout() {
    try {
      clearTimeout(this.timeout);
      this.timeout = null;
    } catch (error) {
      console.log(error);
    }
  }
}

export default RadioLog;