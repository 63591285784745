/**
 * 콘텍스트메뉴 대체
 */
import { SbsPlayerGlobal } from '../../common';
import { clamp } from '../../utils';
import SbsPlayerLogger from '../../utils/logger';

customElements.define('sbs-player-contextmenu', class extends HTMLElement {
  constructor() {
    super();
    this.pointerEvent = null;
  }

  set pointerEvent(value) {
    this._pointerEvent = value;
    this.render();
  }

  get pointerEvent() {
    return this._pointerEvent;
  }

  static get observedAttributes() { return []; }

  async attributeChangedCallback(name, oldValue, newValue) {
    try {

    } catch (error) {
      console.log(error);
    }
  }

  async connectedCallback() {
    try {
      //this.render();
    } catch (error) {
      console.log(error);
    }
  }

  disconnectedCallback() {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  adoptedCallback() {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    try {
      this.innerHTML = `<div class="playerContextMenu_w">
        <div class="selectLayerWrap-contextmenu" role="menu">
          <div role="menuitem">
            <button type="button" class="btnLayerText-urlCopy">
              <strong class="layerText-subtit">동영상 URL 복사</strong>
            </button>
          </div>
          <div role="menuitem">
            <button type="button" class="btnLayerText-codeCopy">
              <strong class="layerText-subtit">소스코드 복사</strong>
            </button>
          </div>
          <div class="layerText-info" role="menuitem">
            <strong class="layerText-subtit">SBS Player v${SbsPlayerGlobal.config.version}</strong>
          </div>
          ${window.Hls ? `<div class="layerText-info" role="menuitem">
            <strong class="layerText-subtit">Hls.js v${window.Hls.version}</strong>
          </div>` : ``}
        </div>
      </div>`;
      this.event();
    } catch (error) {
      console.log(error);
    }
  }

  event() {
    const contextmenu = this.querySelector('.playerContextMenu_w');
    const urlCopy = this.querySelector('.btnLayerText-urlCopy');
    const codeCopy = this.querySelector('.btnLayerText-codeCopy');
    urlCopy.addEventListener('click', () => {
      this.dispatchEvent(new CustomEvent('button-click-url'));
    });
    codeCopy.addEventListener('click', () => {
      this.dispatchEvent(new CustomEvent('button-click-code'));
    });

    if (this.pointerEvent) {
      const menuWidth = 172, menuHeight = 140;
      const padding = 8;
      const positionX = clamp(this.pointerEvent.layerX, padding, this.pointerEvent.currentTarget.offsetWidth - menuWidth);
      const positionY = clamp(this.pointerEvent.layerY, padding, this.pointerEvent.currentTarget.offsetHeight - menuHeight);

      contextmenu.style.width = '0';
      contextmenu.style.height = '0';
      contextmenu.style.top = positionY + 'px';
      contextmenu.style.left = positionX + 'px';
      contextmenu.classList.add('open-layer');
    } else {
      contextmenu.classList.remove('open-layer');
    }
  }
});