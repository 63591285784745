/**
 * 다시보기 중앙버튼
 */
import replayIcon from '../../../../../assets/replay.svg';
import SbsPlayerLogger from '../../../../utils/logger';

customElements.define('sbs-player-center-replay', class extends HTMLElement {
  constructor() {
    super();
    this.visible = false;
  }

  set visible(value) {
    this._visible = value;
    this.render();
  }

  get visible() {
    return this._visible;
  }

  static get observedAttributes() { return ['']; }

  async attributeChangedCallback(name, oldValue, newValue) {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  async connectedCallback() {
    try {
      this.render();
    } catch (error) {
      console.log(error);
    }
  }

  disconnectedCallback() {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  adoptedCallback() {
    try {
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    try {
      this.innerHTML = `<button type="button" class="videoBtn-replay" aria-label="다시보기" title="다시보기">
              <span class="iconSvg_replay"><i class="hide">다시보기</i></span>
            </button>`;
      this.event();
      this.styles();
      this.icons();
    } catch (error) {
      console.log(error);
    }
  }

  event() {
    this.querySelector('button').addEventListener('click', (event) => {
      this.dispatchEvent(new CustomEvent('button-click-replay'));
    });
  }

  styles() {
    try {
      if (this.visible) {
        this.style.display = 'block';
      } else {
        this.style.display = 'none';
      }
    } catch (error) {
      console.log(error);
    }
  }
  icons() {
    try {
      const replayIconElement = this.querySelector('.iconSvg_replay');
      replayIconElement?.insertAdjacentHTML('afterbegin', replayIcon);
    } catch (error) {
      console.log(error);
    }
  }
});